import { Injectable } from "@angular/core";

import { DocumentDownloadStatusLocalStorageKey, LocalStorageKey } from "app/models";

@Injectable({
  providedIn: "root",
})
export class PersistenceService {
  setItem(key: LocalStorageKey | DocumentDownloadStatusLocalStorageKey, value: any): void {
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  getItem(key: LocalStorageKey | DocumentDownloadStatusLocalStorageKey): any {
    return JSON.parse(window.localStorage.getItem(key));
  }

  removeItem(key: LocalStorageKey): void {
    window.localStorage.removeItem(key);
  }
}
