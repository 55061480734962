import { MasterBondProductStatus } from "../enums";

export const MASTER_BOND_REVIEW_PRODUCT_STATUS_LIST = [
  MasterBondProductStatus.UCC,
  MasterBondProductStatus.ACCEPTED,
];
export const MASTER_BOND_DATA_REVIEW_PRODUCT_STATUS_LIST = [MasterBondProductStatus.DATA_REVIEW];

export const MASTER_BOND_READY_PRODUCT_STATUS_LIST = [
  MasterBondProductStatus.ACCEPTED,
  MasterBondProductStatus.DOWNLOADED,
  MasterBondProductStatus.DELIVERED,
];

export const SHOW_MASTER_BOND_BTN = [
  MasterBondProductStatus.DOC_1_READY,
  MasterBondProductStatus.DOWNLOADED,
  MasterBondProductStatus.DELIVERED,
  MasterBondProductStatus.SIGNATURE_REJECTED,
];
