import flattenDeep from "lodash/flattenDeep";

const TITLES = {
  dashboard: "Universal Law Private Affairs System | Dashboard",
  profile: "Universal Law Private Affairs System | Set Up Your Profile",
  "spc-amend": "Universal Law Private Affairs System | Amend Your Secured Party Creditor",
  trust: "Universal Law Private Affairs System | Trust Dashboard",
  "trust.create": "Universal Law Private Affairs System | Create Your Trust",
  "trust.edit": "Universal Law Private Affairs System | Update Your Trust",
  "services.master-bond": "Universal Law Private Affairs System | Set Up Your Master Bond Coupon",
  "services.tax-settlement":
    "Universal Law Private Affairs System | Set Up Your Tax Settlement Coupon",
};

export const getTitle = (url: string): string => {
  const list = url.split("/").map((el) => (el.includes("?") ? el.split("?") : el));
  const key = flattenDeep(list)
    .slice(1, 3)
    .map((el) => el.trim())
    .join(".");

  return TITLES[key] || "Universal Law Private Affairs System";
};
