/**
 * Enum of Master Bond Service Dynamic DOM Entities
 *
 * @enum {string} DOM Entity
 */
export enum MasterBondEntity {
  SET_MASTER_BOND_BTN = "setMasterBondBtn",
  UPDATE_MASTER_BOND_BUTTON = "updateMasterBondBtn",
  MASTER_BOND_PRODUCT = "masterBondProduct",
  MASTER_BOND_PRODUCT_MSG = "masterBondProductMsg",
  MASTER_BOND_DOWNLOAD_BTN = "masterBondDownloadBtn",
  MASTER_BOND_DATA_REVIEW_MSG = "masterBondDataReviewMsg",
}

/**
 * Enum of Master Bond Product Status
 *
 * @enum {string} Master Bond Product Status
 *
 */
export enum MasterBondProductStatus {
  UCC = "UCC",
  DATA_REVIEW = "Data review",
  DOC_1_READY = "Doc 1 ready",
  DATA_REJECT = "Data reject",
  ACCEPTED = "Accepted",
  DOWNLOADED = "Downloaded",
  DELIVERED = "Delivered",
  SIGNATURE_REJECTED = "Signature Rejected",
}

export enum MasterBondDocNames {
  MASTER_BOND = "masterBond",
  MASTER_BOND_COVER_LETTER = "masterBondCoverLetter",
}
