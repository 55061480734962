import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "countryMask",
})
export class CountryMaskPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value || !args) {
      return "";
    }

    const [countries] = args;
    const countryList = countries.filter((country) => {
      return country.name.toLowerCase().includes(value.toLowerCase());
    });

    if (countryList.length === 0) {
      return "";
    }

    if (countryList.length === 1) {
      return countryList[0].name;
    }

    if (countryList.length > 1) {
      const tempCountry = countryList.find((country) => {
        return country.name.toLowerCase() === value.toLowerCase();
      });
      return tempCountry ? tempCountry.name : "";
    }
  }
}
