<div class="wrapper">
  <div class="not-found">
    <!-- <img alt="Logo" src="assets/img/logo.png"> -->
    <div class="not-found-404">
      <h1 style="background-image: url(assets/img/inner-banner.jpg)">Oops!</h1>
    </div>
    <h2>404 - Page Not Found</h2>
    <p>
      The page you are looking for might have been removed, had its name changed or is temporarily
      unavailable.
    </p>
    <button
      mat-raised-button
      class="btn btn-warning btn-large text-capitalize"
      (click)="onGotoHome()"
    >
      Go To Dashboard
    </button>
  </div>
</div>
