export const FORM_CONTROL_ERROR = {
  email: {
    required: "Email is required.",
    email: "Email is invalid.",
  },
  password: {
    required: "Password is required.",
    minLength: "Password should be at least 8 characters.",
  },
  phone: {
    required: "Phone number is required.",
    pattern: "Phone number is invalid.",
    maxLength: "Phone number cannot be more than 15 characters long.",
  },
  firstName: {
    required: "First name is required.",
    character: "Only English characters are supported.",
  },
  middleName: { character: "Only English characters are supported." },
  lastName: {
    required: "Last name is required.",
    character: "Only English characters are supported.",
  },
  country: {
    required: "Country is required.",
    character: "Only English characters are supported.",
  },
  state: {
    required: "State is required.",
    character: "Only English characters are supported.",
  },
  address: {
    required: "Address is required.",
    character: "Only English characters are supported.",
  },
  city: {
    required: "City is required.",
    character: "Only English characters are supported.",
  },
  postalCode: { required: "Postal code is required." },

  bcFirstName: { required: "Birth First Name is required." },
  bcLastName: { required: "Birth Last Name is required." },
  bcTitle: { required: "Birth Certificate Title is required." },
  bcCounty: { required: "County of Birth is required." },
  bcState: { required: "Birth State is required." },
  bcFileNumber: { required: "Birth Certificate Number is required." },
  userCounty: { required: "County of Residence is required." },
  bcDOB: { required: "Date of Birth is required." },
  bcGender: { required: "Gender is required." },
  sscSSN: {
    required: "Birth first name is required.",
    pattern: "Social Security Number is invalid.",
  },
  spcNotaryState: { required: "Notary Venue State is required." },
  spcNotaryCounty: { required: "Notary Venue County is required." },
  userEthnicity: { required: "Race is required." },

  trustName: { required: "Trust Name is required." },
  trustNotaryCounty: { required: "Trust County is required." },
  trustNotaryState: { required: "Trust State is required." },

  authNumber: { required: "Birth Certificate Authentication Number is required." },
  mailNumber: { required: "Post Office Registered Mail Number is required." },

  masterBondNum: {
    required: "Master Bond Registered Mail Number is required.",
  },
};
