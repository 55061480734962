import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "stateMask",
})
export class StateMaskPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (!value || !args) {
      return "";
    }

    const [states] = args;
    const stateList = states.filter((state) => {
      return state.toLowerCase().includes(value.toLowerCase());
    });

    if (stateList.length === 0) {
      return "";
    }

    if (stateList.length === 1) {
      return stateList[0];
    }

    if (stateList.length > 1) {
      const temp = stateList.find((state) => {
        return state.toLowerCase() === value.toLowerCase();
      });
      return temp || "";
    }
  }
}
