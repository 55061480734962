export const TOOL_TIP = {
  dashboard: {
    setupProfileBtn: "Setup your SPC packet",
  },
  profile: {
    usc: {
      bcName: "Enter your full birth name in the following format I.E John Henry Doe.",
      bcFirstName: "Enter your birth first name.",
      bcMiddleName: "Enter your birth middle name.",
      bcLastName: "Enter your birth last name.",
      bcTitle:
        "Please enter the title that is on your birth certificate - each state has a different title for the birth certificate. The most common titles are Certificate of Birth, Certificate of Live Birth and Standard Certificate of Birth. Please note this is not the name of the state.",
      bcCounty: "Please enter the county you were born in.",
      userCounty: "Please enter the county you currently reside in.",
      bcState: "Please enter the state you were born in.",
      bcFileNumber:
        "This is the state file number which is located on your birth certificate. It is a combination of letters and/or numbers that can be found on top or at the bottom of your Birth Certificate. It’s very important that you only input the state file number and not another number on the birth certificate.",
      sscSSN: "Enter your full Social Security Number without dashes.",
      sscBondNumber:
        "This is the red number located on the back of your social security card." +
        "*Note: Some cards that were issued before 1970 do not have a red number on the back. If this is the case, skip this entry.",
      driversLicenseNumber: "Enter your full driver’s license number with dashes if any.",
      driversLicenseState: "Enter your full driver’s license state if any.",
      passportNumber: "Please only provide this if you have a U.S passport.",
      marriageLicNum:
        "This is a combination of letters and/or number that appears on your marriage license." +
        "It may be located on the top or the bottom of the document.",
      marriageFirstName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageMiddleName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageLastName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageSpouseStrawman: "Enter your Spouse’s full name.",
      spcNotaryState:
        "This is the state that you will be notarizing the trust and secured party instruments in.",
      spcNotaryCounty:
        "This is the county you will be notarizing the trust and secured party instruments in.",
    },
    uspr: {
      bcName: "Enter your full birth name in the following format I.E John Henry Doe.",
      bcFirstName: "Enter your birth first name.",
      bcMiddleName: "Enter your birth middle name.",
      bcLastName: "Enter your birth last name.",
      bcTitle:
        "Each state has a different title for the birth certificate, please enter the title that is on your birth certificate.",
      bcCounty: "Please enter the county you were born in.",
      userCounty: "Please enter the county you currently reside in.",
      bcState: "Please enter the country you were born in.",
      bcFileNumber: "This is the nine digit number located on your green card.",
      dateEnteredUS: "This is the date listed on your Green Card",
      sscSSN: "Enter your full Social Security Number without dashes.",
      sscBondNumber:
        "This is the red number located on the back of your social security card." +
        "*Note: Some cards that were issued before 1970 do not have a red number on the back. If this is the case, skip this entry.",
      driversLicenseNumber: "Enter your full driver’s license number with dashes if any",
      driversLicenseState: "Enter your full driver’s license state if any.",
      passportNumber: "Please only provide this if you have a U.S passport",
      marriageLicNum:
        "This is a combination of letters and/or number that appears on your marriage license." +
        "It may be located on the top or the bottom of the document.",
      marriageFirstName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageMiddleName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageLastName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageSpouseStrawman: "Enter your Spouse’s full name.",
      spcNotaryState:
        "This is the state that you will be notarizing the trust and secured party instruments in.",
      spcNotaryCounty:
        "This is the county you will be notarizing the trust and secured party instruments in.",
    },
    nusc: {
      bcName: "Enter your full birth name in the following format I.E John Henry Doe.",
      bcFirstName: "Enter your birth first name.",
      bcMiddleName: "Enter your birth middle name.",
      bcLastName: "Enter your birth last name.",
      bcTitle:
        "Each state has a different title for the birth certificate, please enter the title that is on your birth certificate.",
      bcCounty: "Please enter the county you were born in.",
      userCounty: "Please enter the county you currently reside in.",
      bcState: "Please enter the country you were born in.",
      bcFileNumber:
        "This is a combination of letters and/or numbers that appears on your birth certificate," +
        "it may be on the top or bottom of the document.",
      dateEnteredUS: "Date on your naturalization certificate",
      sscSSN: "Enter your full Social Security Number without dashes.",
      sscBondNumber:
        "This is the red number located on the back of your social security card." +
        "*Note: Some cards that were issued before 1970 do not have a red number on the back. If this is the case, skip this entry.",
      driversLicenseNumber: "Enter your full driver’s license number with dashes if any",
      driversLicenseState: "Enter your full driver’s license state if any.",
      passportNumber: "Please only provide this if you have a U.S passport",
      marriageLicNum:
        "This is a combination of letters and/or number that appears on your marriage license." +
        "It may be located on the top or the bottom of the document.",
      marriageFirstName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageMiddleName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageLastName: `Did you legally change your name when you married? If yes, enter your married name.`,
      marriageSpouseStrawman: "Enter your Spouse’s full name.",
      spcNotaryState:
        "This is the state that you will be notarizing the trust and secured party instruments in.",
      spcNotaryCounty:
        "This is the county you will be notarizing the trust and secured party instruments in.",
      naturalizationNum:
        "This is the number that starts with an A located on your naturalization certificate.",
    },
    saveAddrBtn: "Save your address",
    confirmAddrBtn: "Confirm your address",
    saveExtProfileBtn: "Save your extended profile",
    tutorial: {
      addr: "This tutorial video shows how to set address.",
      extProfile: "This tutorial video shows how to complete extended profile.",
      spcAmend: "This tutorial video shows how to complete SPC addendum.",
    },
  },
  trust: {
    trustName:
      "You can name your trust whatever you like," +
      " but it’s like a child you can only give it a name once, so choose carefully!" +
      "Some people honor an ancestor by naming it after them and some people choose to name it after someone or something they hold dear." +
      "It’s up to you! Just remember that Trust must be the last word in the title.",
    trustNotaryState: "This is the state that you will be notarizing the trust instrument in.",
    trustNotaryCounty: "This is the county you will be notarizing the trust instrument in.",
    grantor:
      "The Grantor cannot hold any other position in the trust and must be a human." +
      " If you do not have someone you trust that can act as the Grantor please choose Meta 1 as your Grantor.",
    protector:
      "The Protector will be Meta 1. The Protector’s role is to observe the trust administration," +
      " discharge trustees for mismanagement and install successor trustees.",
    firstTrustee: "The first Trustee is you and your Trust requires at least a second Trustee.",
    secondTrustee:
      "The second trustee should be someone you trust and who can and will be involved in the daily operation of the trust.",

    createTrustBtn: "Create a new Trust",
    refreshTrustBtn: "Refresh Trust List",
    editTrustBtn: "Edit Trust",
    downloadTrustBtn: "Download Trust PDF",
    downloadReportBtn: "Download Trust Report",
    uploadTrustBtn: "Upload Trust PDF",
    addBenefBtn: "Add Beneficiary",
    removeBenefBtn: "Remove Beneficiary",
    addPropertyBtn: "Add Property",
    editPropertyBtn: "Edit Property",
    removePropertyBtn: "Remove Property",
    createBTrustBtn: "Create a new Beneficiary Trust",
    searchBTrustBtn: "Search a Beneficiary Trust",

    detail: {
      human: {
        invite: "Click to resend Invite email.",
        protector: {
          replace: "Replace Protector.",
          download: "Download PC Amendment document.",
          upload: "Upload PC Amendment document.",
          complete: "Complete Protector replacement.",
        },
        secondaryTrustee: {
          replace: "Replace Secondary Trustee.",
          download: "Download PC Amendment document.",
          upload: "Upload PC Amendment document.",
          complete: "Complete Secondary Trustee Replacement.",
        },
      },
      beneficiary: {
        add: "Add beneficiary human.",
        remove: "Remove beneficiary human.",
        replace: "Replace beneficiary human.",
        download: "Download beneficiary human amendment document.",
        upload: "Upload beneficiary human amendment document.",
        complete: "Complete beneficiary human amendment.",
        downloadRemovalDoc: "Download removal of beneficiary human document.",
        completeRemoval: "Complete removal of beneficiary human.",

        addtrust: "Add beneficiary trust.",
        removetrust: "Remove beneficiary trust.",
        replacetrust: "Replace beneficiary trust.",
        completetrust: "Complete beneficiary trust amendment.",
        downloadtrustremoval: "Download removal of beneficiary trust.",
        completetrustremoval: "Complete removal of beneficiary trust.",
      },
    },
  },
  spcamend: {
    authnumber:
      "You will locate this number on the authentication page that the US Secretary of State attached on top of your Birth Certificate.",
    registeredmailnumber:
      "This is the number that is located on the registered mail label you got from the U.S Post Office.",
    uccfilenumber:
      "This is the state file number located on the top right corner of your UCC 1 Financial Statement filing.",
    uccstate: "Enter the state you filed the UCC 1 in.",
    firstname: "Enter your child’s birth first name.",
    midname: "Enter your child’s birth middle name.",
    lastname: "Enter your child’s birth last name.",
    birthstate: "This is the state your child was born in.",
    birthdate: "Date of Birth- Enter your child’s date of birth",
    birthcertnumber: "This is the state file number located on your child’s birth certificate.",
    birthcerttitle: "This is the title on the top of your child’s birth certificate.",
    birthcounty: "This is the county that your child was born in",
    birthcertauthnumber:
      " You will locate this number on the authentication page that the US Secretary of State attached on top of your child’s birth certificate.",
  },
  services: {
    tax: {
      refreshBtn: "Refresh Tax Settlement",
      createBtn: "Create Tax Settlement",
      payOffAmount: "Enter the amount stated as owed on your IRS Statement.",
      irsAccountNum:
        "Enter the amount number on the bottom of the coupon that is located on your IRS statement.",
      irsTechDeptCertifiedMailNum:
        // "Enter the full number located on one of the 4 certified mail labels you acquired from the Post Office.",
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      treasuryDeptCertifiedMailNum:
        // "Enter the full number located on one of the 4 certified mail labels you acquired from the Post Office.",
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      treasurySecretaryCertifiedMailNum:
        // "Enter the full number located on one of the 4 certified mail labels you acquired from the Post Office.",
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      ttbExciseTax:
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      es1040CertMailNum:
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      v1040CertMailNum:
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      pibRegMailNum:
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      boeRegMailNum:
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      irsPubCertifiedMailNum:
        // "Enter the full number located on one of the 4 certified mail labels you acquired from the Post Office.",
        "Enter the full number located on the  certified mail labels you acquired from the Post Office.",
      irsPriRegisteredMailNum:
        "Enter the full number located on one of the 2 registered mail labels you acquired from the Post Office.",
      treasuryRegisteredMailNum:
        "Enter the full number located on one of the 2 registered mail labels you acquired from the Post Office.",
      irsPubAddr:
        "Enter the street address of the IRS Public address listed on the IRS payment coupon in all uppercase letters.",
      irsPubCity:
        "Enter the city of the IRS Public address listed on the IRS payment coupon in all uppercase letters.",
      irsPubState:
        "Enter the state of the IRS Public address listed on the IRS payment coupon in all uppercase letters.",
      irsPubZipCode:
        "Enter the Zip code of the IRS Public address listed on the IRS payment coupon in all uppercase letters.",
      irsPubDepartName:
        "Enter the name of the IRS Department listed above the Public address on the payment coupon.",
      irsPriAddr:
        "Enter the street address of the IRS Private address which is listed on the upper left IRS statement.",
      irsPriCity:
        "Enter the city of the IRS Private address which is listed on the upper left of the IRS statement.",
      irsPriState:
        "Enter the state of the IRS Private address listed on the upper left of the IRS statement.",
      irsPriZipCode:
        "Enter the zip code of the IRS Private address listed on the upper left of the RS statement.",
      irsPriDepartName:
        "Enter the name of the IRS Department listed above the private address on the payment coupon.",
      notaryName: "Enter the fully name of the notary who will notarize the Letter of Acceptance.",
      notaryAddr: `Enter notary's street address`,
      notaryState: `Enter the notary's state.`,
      notaryZip: `Enter the notary's zip code.`,
    },
    gsa: {
      createBtn: "Create GSA Bond",
      plaintiffName: "This is the name of the Plaintiff located on the complaint.",
      defendantName: "This is the name of the Defendant located on the complaint.",
      caseNumber: "This is the case number located on the summons.",
      judgeFirstName: "Judge’s first name listed on Summons",
      judgeMiddleName: "Judge’s middle name listed on Summons",
      judgeLastName: "Judge’s last name listed on Summons",
      judgeCertMailNum: "Judge Registered Mail Number",
      irsCertMail: "Internal Revenue Registered Mail Number",
      dtcCertMailNum: "Depository Trust Company Registered Mail Number",
      treasuryCertMailNum: "Treasury Dept Registered Mail Number",
      judgeCertMailNum2: "Judge Certified Mail Number",
      courtDistrictName: "The name of the Court listed on Summons.",
      courtAddress: "The address of the Court listed on Summons.",
      courtCity: "The city of the Court listed on Summons.",
      courtState: "The state of the Court listed on Summons.",
      gsaNState: "The state of the Court listed on Summons.",
      caseType: "The case type of the Court listed on Summons.",
      courtCounty: "The county the court is located in.",
      courtZip: "The zip of the Court listed on Summons.",
      caseFileDate: "Date court case was filed.",
      gsaBondDate: "Date Bond was filed",
      amount: "Charged amount",
      feeAmount: "Gsa Bond Amount",
      gsaNCounty: "The county the court is located in.",
    },
    easylien: {
      exemption: {
        businessName: "Enter the name of the business that has violated your rights.",
        businessAddress: "Enter the address of the business that has violated your rights.",
        bizCity: "Enter the address of the business that has violated your rights.",
        bizState: "Enter the state of the business that has violated your rights.",
        bizZip:
          "Enter the zip code of the business that has violated your rights Registered Mail Number – Enter the certified mail number you will use to mail the NOTICE OF EXEMPTION.",
        bizRegMailNum1:
          "Enter the Registered Mail Number you will use to mail the Notice of Exemption.",
        incidentStatement:
          "In your own words describe the incident in which you were denied service for wearing a mask. If you have names of employees or even employee ID numbers this is helpful to add as well.",
        fineAmount: "Enter the value of Damages",
        ownersName: "Enter the Owner's/CEO's name",
      },
      default: {
        bizRegMailNum2:
          "Enter the Registered Mail Number you will use to mail the Notice of Default.",
        bizRegMailNum3:
          "Enter the Registered Mail Number you will use to mail the Notice of Lien, last step in the process.",
        employeeName:
          "Enter the name of the employee who denied you service and violated your rights on behalf of the corporation.",
        empID: "(Optional) Enter if the violator’s employee ID if you have it.",
        incidentDate:
          "Enter the date of the incident or if you returned to the business the date of the second incident.",
      },
    },
  },
};
