import {
  Directive,
  OnDestroy,
  Self,
  AfterViewInit,
  HostListener,
  ChangeDetectorRef,
} from "@angular/core";
import { NgControl } from "@angular/forms";

import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: "[appCurrencyFormatter]",
})
export class CurrencyFormatterDirective implements AfterViewInit, OnDestroy {
  private _formatter: Intl.NumberFormat;
  private _stop$: Subject<any>;

  constructor(@Self() private _ngCtrl: NgControl, private _cdRef: ChangeDetectorRef) {
    this.setVariables();
  }

  ngAfterViewInit(): void {
    this._setValue(this._formatPrice(this._ngCtrl.value));
    this._cdRef.detectChanges();

    this._ngCtrl.control.valueChanges
      .pipe(takeUntil(this._stop$))
      .subscribe(this._updateValue.bind(this));
  }

  ngOnDestroy(): void {
    this._setValue(this._unFormatValue(this._ngCtrl.value));

    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    this._stop$ = new Subject();
  }

  private _formatPrice(value): any {
    return this._formatter.format(value);
  }

  private _unFormatValue(value): any {
    return value.replace(/,/g, "");
  }

  private _updateValue(value): void {
    const inputVal = value || "";
    this._setValue(
      inputVal ? this._validateDecimalValue(String(inputVal).replace(/[^0-9.]/g, "")) : "",
    );
  }

  private _setValue(value): void {
    this._ngCtrl.control.setValue(value, { emitEvent: false });
  }

  private _validateDecimalValue(value): void {
    if (Number.isNaN(Number(value))) {
      const strippedValue = value.slice(0, value.length - 1);
      return Number.isNaN(Number(strippedValue)) ? "" : strippedValue;
    }

    return value;
  }

  @HostListener("focus") onFocus(): void {
    this._setValue(this._unFormatValue(this._ngCtrl.value));
  }

  @HostListener("blur") onBlur(): void {
    const value = this._ngCtrl.value || "";
    value && this._setValue(this._formatPrice(value));
  }
}
