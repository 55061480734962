import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { tap, filter } from "rxjs/operators";

import { LocalStorageKey } from "app/models";
import { PersistenceService } from "./persistence.service";

@Injectable({
  providedIn: "root",
})
export class RouteStateService {
  private _history: string[];

  constructor(
    private readonly _router: Router,
    private readonly _persistenceService: PersistenceService,
  ) {
    this._history = [];
  }

  loadRouteState(): void {
    this._router.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        tap(({ urlAfterRedirects }: NavigationEnd) => {
          this._persistenceService.setItem(LocalStorageKey.REDIRECT_URL, urlAfterRedirects);
        }),
      )
      .subscribe(() => {});
  }

  getRouteHistory(): string[] {
    return this._history;
  }

  getPreviousUrl(): string {
    return this._history[this._history.length - 2] || "/dashboard";
  }
}
