export const VIDEO = {
  intro: "https://player.vimeo.com/video/495283969",
  profile: {
    basic: "https://player.vimeo.com/video/495892156",
    extended: {
      USC: "https://player.vimeo.com/video/495892184",
      NUSC: "https://player.vimeo.com/video/495892258",
      USPR: "https://player.vimeo.com/video/495892214",
    },
    spc: {
      signature: "https://player.vimeo.com/video/495892305",
    },
  },
  trust: {
    setup: "https://player.vimeo.com/video/495892725",
    naming: "https://player.vimeo.com/video/495892746",
    humans: "https://player.vimeo.com/video/495892752",
    beneficiary: "https://player.vimeo.com/video/495284106",
    property: "https://player.vimeo.com/video/495284204",
    execution: {
      signature: "https://player.vimeo.com/video/424845888",
      tracking: "https://player.vimeo.com/video/424849884",
    },
    replacing: {
      protector: "https://player.vimeo.com/video/495893977",
      trustee: "https://player.vimeo.com/video/495893998",
      beneficiary: "https://player.vimeo.com/video/495290437",
    },
  },
  spcamend: "https://player.vimeo.com/video/495892035",
  masterbond: {
    intro: "https://player.vimeo.com/video/495894131",
    instruction: "https://player.vimeo.com/video/495290636",
  },
  taxsettlement: {
    intro: "https://player.vimeo.com/video/495290819",
    instruction: "https://player.vimeo.com/video/495894150",
  },
};
