import { NgModule, APP_INITIALIZER } from "@angular/core";
import { RouterModule, PreloadAllModules } from "@angular/router";
import { CommonModule } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { NgxSpinnerModule } from "ngx-spinner";

import { SessionModule } from "@libs/session/session.module";
import { SidebarModule, NavbarModule, FooterModule, MaterialModule, PipesModule } from "app/shared";

import {
  AdminLayoutComponent,
  AuthLayoutComponent,
  ScrollTopComponent,
  PageNotFoundComponent,
  NotifyMaintainPageComponent,
  SideDrawerComponent,
} from "app/layouts";
import { CoreModule, AppInitService } from "app/core";

import { AppRoutes } from "./app.routing";
import { ComponentsModule } from "./components";

import { AppComponent } from "./app.component";
import { NdaComponent } from "./pages/nda/nda.component";

export function initializeApp(appInitService: AppInitService) {
  return () => {
    return appInitService.init();
  };
}

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),

    NgxSpinnerModule,

    CoreModule,

    PipesModule,
    FooterModule,
    NavbarModule,
    SidebarModule,
    MaterialModule,
    ComponentsModule,

    SessionModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    ScrollTopComponent,
    PageNotFoundComponent,
    NotifyMaintainPageComponent,
    SideDrawerComponent,
    NdaComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
