import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatTooltipModule } from "@angular/material/tooltip";

import { HorizontalStepperComponent } from "./horizontal-stepper.component";

@NgModule({
  declarations: [HorizontalStepperComponent],
  imports: [CommonModule, MatTooltipModule],
  exports: [HorizontalStepperComponent],
})
export class HorizontalStepperModule {}
