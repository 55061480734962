import { Instruction, UserCitizenStatus, UserStatus } from "@models/enums";
import { IGender, IRace, IUserCitizenStatus, ISuffix } from "@models/models";
import { SHEPHERD_BUILT_IN_BTN, TUTORIALS } from "./common.constant";

export const CITIZEN_STATUS_ARR: IUserCitizenStatus[] = [
  {
    userCitizenStatus: UserCitizenStatus.US_CITIZEN,
    ProductType: "SPC",
    ProductName: "USC",
    SPCRequired: true,
  },
  // {
  //   userCitizenStatus: UserCitizenStatus.US_PERMANENT_RESIDENT,
  //   ProductType: "SPC",
  //   ProductName: "USPR",
  //   SPCRequired: true,
  // },
  {
    userCitizenStatus: UserCitizenStatus.NATURALIZED_US_CITIZEN,
    ProductType: "SPC",
    ProductName: "NUSC",
    SPCRequired: true,
  },
  {
    userCitizenStatus: UserCitizenStatus.NA,
    ProductType: "SPC",
    ProductName: "None of the Above",
    SPCRequired: false,
  },
];

export const USER_SUFFIX_ARR: ISuffix[] = [
  {
    title: "None",
    value: "None",
  },
  {
    title: "Sr",
    value: "Sr",
  },
  {
    title: "Jr",
    value: "Jr",
  },
  {
    title: "I",
    value: "I",
  },
  {
    title: "II",
    value: "II",
  },
  {
    title: "III",
    value: "III",
  },
  {
    title: "IV",
    value: "IV",
  },
  {
    title: "V",
    value: "V",
  },
  {
    title: "VI",
    value: "VI",
  },
  {
    title: "VII",
    value: "VII",
  },
  {
    title: "VIII",
    value: "VIII",
  },
  {
    title: "IX",
    value: "IX",
  },
  {
    title: "X",
    value: "X",
  },
];

export const BirthCertificateTitle = {
  [UserCitizenStatus.US_PERMANENT_RESIDENT]: "Green Card",
  [UserCitizenStatus.NATURALIZED_US_CITIZEN]: "Naturalization Certificate",
};

export const PARTIAL_ADDR_KEYS = [
  "userCountry",
  "userState",
  "userCity",
  "userAddressLine1",
  "userPostalCode",
  "userCitizenStatus",
  "userBirthCountry",
];

export const GENDER: IGender[] = [
  {
    title: "Man",
    value: "man",
  },
  {
    title: "Woman",
    value: "woman",
  },
];

export const RACES: IRace[] = [
  {
    key: "White",
    value: "White",
  },
  {
    key: "Black",
    value: "Black",
  },
  {
    key: "Asian",
    value: "Asian",
  },
  {
    key: "Hispanic",
    value: "Hispanic",
  },
  {
    key: "Native American",
    value: "Native American",
  },
  {
    key: "Pacific Islander",
    value: "Pacific Islander",
  },
  {
    key: "Native Hawaiian",
    value: "Native Hawaiian",
  },
];

export const SECURED_USER_ADDRESS_UPDATE_FIELDS = [
  "nameSuffix",
  "userFirstName",
  "userLastName",
  "userMiddleName",
  "userPhone",
  "userCountry",
  "userAddressLine1",
  "userCity",
  "userState",
  "userBirthCountry",
  "userPostalCode",
  "bcGender",
  "userCounty",
  "userEthnicity",
];

export const NEW_USER_STATUS_ARR: UserStatus[] = [UserStatus.NEW_USER, UserStatus.SPC_REQUIRED];

export const SPC_PROGRESS_USER_STATUS_ARR: UserStatus[] = [UserStatus.INVITED, UserStatus.NEW_USER];

export const ADDRESS_UNLOCK_USER_STATUS_ARR: UserStatus[] = [
  UserStatus.INVITED,
  UserStatus.NEW_USER,
  UserStatus.SPC_REQUIRED,
  UserStatus.SPC_REVIEW,
  UserStatus.SECURED,
];

export const EXT_PROFILE_UNLOCK_USER_STATUS_ARR: UserStatus[] = [
  UserStatus.INVITED,
  UserStatus.NEW_USER,
  UserStatus.SPC_REQUIRED,
  UserStatus.SPC_REVIEW,
];

export const SPC_AMEND_LOCKED_USER_STATUS_ARR: UserStatus[] = [UserStatus.VERIFIED];

export const VERIFIED_USER_STATUS_ARR: UserStatus[] = [
  UserStatus.VERIFIED,
  UserStatus.AUTHENTICATION_REVIEW,
  UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
  UserStatus.AUTHENTICATION_SIGNATURE_REVIEW,
  UserStatus.UCC3_SUBMITTED_TO_UCC,
  UserStatus.AUTHENTICATION_COMPLETED,
  UserStatus.TREASURY_PACKET_SENT,
  UserStatus.SECURED,
];

export const SPC_AMEND_PDF_MANAGE_USER_STATUS_ARR: UserStatus[] = [
  UserStatus.AUTHENTICATION_REVIEW,
  UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
  UserStatus.UCC3_SUBMITTED_TO_UCC,
  UserStatus.AUTHENTICATION_COMPLETED,
  UserStatus.TREASURY_PACKET_SENT,
  UserStatus.SECURED,
];

export const SPC_AMEND_DOWNLOAD_STATUS_ARR: UserStatus[] = [
  UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
  UserStatus.UCC3_SUBMITTED_TO_UCC,
  UserStatus.AUTHENTICATION_COMPLETED,
  UserStatus.TREASURY_PACKET_SENT,
  UserStatus.SECURED,
];

export const AUTHENTICATION_COMPLETED_STATUS_ARR: UserStatus[] = [
  UserStatus.AUTHENTICATION_COMPLETED,
  UserStatus.TREASURY_PACKET_SENT,
  UserStatus.SECURED,
];

export const SPC_PROCESS_STEPS = [
  {
    statusList: [UserStatus.INVITED, UserStatus.NEW_USER, UserStatus.SPC_REQUIRED],
    step: {
      index: 1,
      percent: 10,
      text: "Profile",
      tutorial: "profile.basic",
      tooltip:
        "Fill out and complete your basic and extend profiles, you can do this from the Profile tab on the left-hand side of your screen.",
    },
  },
  {
    statusList: [UserStatus.SPC_REVIEW],
    step: {
      index: 2,
      percent: 20,
      text: "Review",
      tooltip:
        "Our team will review your profile for errors and you’ll get notifications of next steps via email.",
    },
  },
  {
    statusList: [UserStatus.SPC_SIGNATURE_REQUIRED],
    step: {
      index: 3,
      percent: 30,
      text: "Sign",
      tutorial: "profile.spc.signature",
    },
  },
  {
    statusList: [UserStatus.SPC_SIGNATURE_REVIEW, UserStatus.SPC_SUBMITTED_TO_UCC],
    step: {
      index: 4,
      percent: 40,
      text: "Signature Review",
      tooltip:
        "Our team will review your executed instruments and on approval will submit them for filing.",
    },
  },
  {
    statusList: [UserStatus.VERIFIED],
    step: {
      index: 5,
      percent: 50,
      text: "Filing Done",
      tooltip: "Your initial SPC filing is complete.",
    },
  },
  {
    statusList: [UserStatus.AUTHENTICATION_REVIEW],
    step: {
      index: 6,
      percent: 60,
      text: "Birth Auth",
      tooltip: "Authenticating your Birth Certificate.",
      instruction: Instruction.AUTHENTICATION,
    },
  },
  {
    statusList: [
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.AUTHENTICATION_SIGNATURE_REVIEW,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
    ],
    step: {
      index: 7,
      percent: 70,
      text: "Auth Sign",
      tooltip:
        "Time to sign your Schedule A Addendum and upload it to your account, you can do this from the SPC Addendum tab.",
    },
  },
  {
    statusList: [UserStatus.AUTHENTICATION_COMPLETED],
    step: {
      index: 8,
      percent: 80,
      text: "Auth Done",
      tooltip: "Your SPC Addendum is filed and approved.",
    },
  },
  {
    statusList: [UserStatus.TREASURY_PACKET_SENT],
    step: {
      index: 9,
      percent: 90,
      text: "Treasury",
      tooltip: "Your Treasury package is ready for download and preparation.",
      instruction: Instruction.TREASURY_PACKET_MAILING,
    },
  },
  {
    statusList: [UserStatus.SECURED],
    step: {
      index: 10,
      percent: 100,
      text: "Secured",
    },
  },
];

export const SPC_SHEPHERD_STEPS = [
  {
    attachTo: {
      element: ".address-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel, SHEPHERD_BUILT_IN_BTN.next],
    classes: "address-shepherd",
    id: "address-shepherd",
    title: "How to Set Address",
    text: "This tutorial video shows how to set Address.",
  },
  {
    attachTo: {
      element: ".ext-profile-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel, SHEPHERD_BUILT_IN_BTN.back, SHEPHERD_BUILT_IN_BTN.next],
    classes: "ext-profile-shepherd",
    id: "ext-profile-shepherd",
    title: "How to Complete Extended Profile",
    text: "This tutorial video shows how to complete Extended Profile.",
  },
  {
    attachTo: {
      element: ".spc-packet-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel, SHEPHERD_BUILT_IN_BTN.back],
    classes: "spc-packet-shepherd",
    id: "spc-packet-shepherd",
    title: "How to Sign SPC Packet",
    text: "This tutorial video shows how to sign SPC packet.",
  },
];

export const SPC_AMEND_SHEPHERD_STEPS = [
  {
    attachTo: {
      element: ".spc-amend-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel],
    classes: "spc-amend-shepherd",
    id: "spc-amend-shepherd",
    title: "How to Complete SPC Addendum",
    text: "This tutorial video shows how to complete SPC addendum.",
  },
];
