import { animate, animation, keyframes, style } from "@angular/animations";

export const zoomInAnimation = animation([
  style({ transform: "scale(0.4)" }),
  animate("{{ timings }}", style({ transform: "scale(1)" })),
]);

export const zoomOutAnimation = animation([
  animate("{{ timings }}", style({ transform: "scale(0)" })),
]);

export const pulseAnimation = animation([
  style({ transform: "scale(1)" }),
  animate(
    "{{ timings }}",
    keyframes([
      style({ transform: "scale(1)", offset: 0 }),
      style({ transform: "scale({{ scale }})", offset: 0.5 }),
      style({ transform: "scale(1)", offset: 1 }),
    ]),
  ),
]);

export const slideXInAnimation = animation([
  style({ transform: "translateX({{ from }})", opacity: 0 }),
  animate("{{ timings }}", style({ transform: "translateX(0)", opacity: 1 })),
]);

export const slideXOutAnimation = animation([
  animate("{{ timings }}", style({ transform: "translateX({{ to }})", opacity: 0 })),
]);

export const slideYInAnimation = animation([
  style({ transform: "translateY({{ from }})", opacity: 0 }),
  animate("{{ timings }}", style({ transform: "translateY(0)", opacity: 1 })),
]);

export const slideYOutAnimation = animation([
  animate("{{ timings }}", style({ transform: "translateY({{ to }})", opacity: 0 })),
]);

export const fadeInAnimation = animation([
  style({ opacity: 0 }),
  animate("{{ timings }}", style({ opacity: 1 })),
]);

export const fadeOutAnimation = animation([animate("{{ timings }}", style({ opacity: 0 }))]);
