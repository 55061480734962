import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { PersistenceService, StateService } from "@core/services";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";
import { LocalStorageKey } from "app/models";

@Injectable({
  providedIn: "root",
})
export class NdaGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _state: StateService,
    private readonly _persistenceService: PersistenceService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this._state.user.pipe(
      map((user) => {
        // const masterBondEnabled = Boolean(user?.status?.showMasterBond);
        const NDAEnabled =
          Boolean(user?.status?.showNda) ||
          this._persistenceService.getItem(LocalStorageKey.USER_STATUS)?.showNda;

        if (NDAEnabled) {
          return true;
        }

        this._router.navigate(["/dashboard"]);
        return false;
      }),
    );
  }
}
