import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpParams,
  HttpEvent,
  HttpEventType,
  HttpResponse,
} from "@angular/common/http";

import { Observable } from "rxjs";
import { map, finalize, tap } from "rxjs/operators";

import { environment } from "environments/environment";

import { JwtService } from "./jwt.service";
import { OverlayService } from "./overlay.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  baseUrl: string;

  constructor(
    private _http: HttpClient,
    private _jwt: JwtService,
    private _overlayService: OverlayService,
  ) {
    this.baseUrl = environment.apiUrl;
  }

  getEventMessage(event: HttpEvent<any>) {
    switch (event.type) {
      case HttpEventType.UploadProgress:
        return this.fileUploadProgress(event);
      case HttpEventType.Response:
        return this.apiResponse(event);
      default:
        return `File surprising upload event: ${event.type}.`;
    }
  }

  fileUploadProgress(event) {
    const percentDone = Math.round((100 * event.loaded) / event.total);
    this._overlayService.setUploadPercent(percentDone);
    return { status: "progress", value: percentDone };
  }

  apiResponse(event) {
    return event.body;
  }

  get(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const setUpdatedJwt = (res: HttpResponse<any>) => {
      const newJwt = res.headers.get("authorization");
      newJwt && this._jwt.saveToken(newJwt);
    };

    return this._http
      .get(`${this.baseUrl}${path}`, {
        params,
        observe: "response",
      })
      .pipe(tap(setUpdatedJwt));
  }

  getFile(path: string): Observable<any> {
    return this._http.get(`${this.baseUrl}${path}`, {
      responseType: "arraybuffer",
    });
  }

  put(path: string, body): Observable<any> {
    if (path === "/product" || path === "/doc" || path === "/product/exhibit") {
      const filename = body.get("file").name;
      // set uploaded file name for displaying in upload progress bar
      // tslint:disable-next-line: no-unused-expression
      filename && this._overlayService.setUploadingFilename(filename);

      return this._http
        .put(`${this.baseUrl}${path}`, body, {
          reportProgress: true,
          observe: "events",
        })
        .pipe(
          map((event) => this.getEventMessage(event)),
          finalize(() => this._overlayService.hideUploadBar()),
        );
    }

    return this._http.put(`${this.baseUrl}${path}`, JSON.stringify(body));
  }

  post(path: string, body = {}): Observable<any> {
    return this._http.post(`${this.baseUrl}${path}`, JSON.stringify(body), {
      observe: "response",
    });
  }

  delete(path): Observable<any> {
    return this._http.delete(`${this.baseUrl}${path}`);
  }
}
