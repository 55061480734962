import { Directive, Input, OnInit } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
  selector: "[appBindQueryParam]",
})
export class BindQueryParamDirective implements OnInit {
  @Input("appBindQueryParam") paramKey: string;

  constructor(private _formControl: NgControl) {}

  ngOnInit(): void {
    // eslint-disable-next-line no-restricted-globals
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has(this.paramKey)) {
      this._formControl.control.patchValue(queryParams.get(this.paramKey));
      this._formControl.control.disable();
    }
  }
}
