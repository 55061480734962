import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "spcProgressStep",
})
export class SpcProgressStepPipe implements PipeTransform {
  transform(spcStep: number, ...args: number[]): boolean {
    const [curSpcStep] = args;
    return spcStep < curSpcStep;
  }
}
