import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import {
  AuthGuard,
  NoAuthGuard,
  UserStatusGuard,
  ProfileDeactivateGuard,
  UnderMaintenanceGuard,
} from "./guards";
import {
  ApiService,
  JwtService,
  UserService,
  AuthService,
  StateService,
  EventsService,
  AppInitService,
  OverlayService,
  RouteStateService,
  PersistenceService,
  DrawerService,
  SessionService,
} from "./services";
import {
  HttpTokenInterceptor,
  HttpRequestInterceptor,
  HttpResponseInterceptor,
} from "./interceptors";

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    ApiService,
    JwtService,
    UserService,
    AuthService,
    StateService,
    EventsService,
    AppInitService,
    OverlayService,
    RouteStateService,
    PersistenceService,
    DrawerService,
    SessionService,

    // { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpResponseInterceptor, multi: true },

    AuthGuard,
    NoAuthGuard,
    UserStatusGuard,
    ProfileDeactivateGuard,
    UnderMaintenanceGuard,
  ],
})
export class CoreModule {}
