import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { OverlayService, UserService, StateService } from "@core/services";
import { Subject } from "rxjs";
import { filter, mergeMap, takeUntil, tap } from "rxjs/operators";
import { filterByNull } from "@util/operators";
import { util } from "app/util";
import { IPdf, NO_CB, PdfType, Sku, UPLOAD_FILE_SIZE_MAXIMUM } from "app/models";

@Component({
  selector: "ulaw-nda",
  templateUrl: "./nda.component.html",
  styleUrls: ["./nda.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NdaComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  user: any;
  productId: any;
  nda: any;
  productStatus: any;
  isUploaded: any;

  constructor(
    private _userService: UserService,
    private _overlayService: OverlayService,
    private _store: StateService,
    private _cdRef: ChangeDetectorRef,
  ) {
    this.setVariables();
  }

  setVariables(): void {
    this._stop$ = new Subject();
  }

  ngOnInit(): void {
    this.fetchUser();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  fetchUser(): void {
    const setUser = (user: any) => {
      this.user = user;
      const { products } = user;
      this.nda = products.find(({ sku }) => sku === Sku.NDA);
      this.isUploaded = !!products.find(({ sku }) => sku === Sku.NDA)?.filled;
      this.productStatus = products.find(({ sku }) => sku === Sku.NDA)?.producStatus;
      this.productId = products.find(({ sku }) => sku === Sku.NDA)?.id;
      this._cdRef.detectChanges();
    };

    this._store.user.pipe(filterByNull(), tap(setUser), takeUntil(this._stop$)).subscribe(NO_CB);
  }

  getPdfFilename(): string {
    return [this.user?.userLastName, new Date().getFullYear(), "NDA"].filter(Boolean).join("_");
  }

  onDownloadDoc(): void {
    const filename = this.getPdfFilename();
    const pdf: IPdf = {
      type: PdfType.NDA,
      sku: Sku.NDA,
      filename,
    };
    this._overlayService.setPdf(pdf);
  }

  onRefreshNda(): void {
    this._userService.getUser().subscribe(NO_CB);
  }

  onUploadSignedDoc({ target }): void {
    const file = target.files[0];
    const filename = "";
    this.onUploadDoc(file, filename, false, Sku.NDA);
  }

  onUploadDoc(file, docName, isExhibit = false, sku: any): void {
    if (file.size >= UPLOAD_FILE_SIZE_MAXIMUM) {
      util.warnMsg({ msgKey: "uploadsmallerfile" });
      return;
    }

    this._overlayService.showUploadBar();

    const formData = new FormData();
    formData.append("file", file);
    formData.append("sku", sku);
    this.uploadPdf(formData, isExhibit);
  }

  uploadPdf(formData: FormData, isExhibit = false) {
    this._userService
      .uploadProductPdf(formData, isExhibit)
      .pipe(
        filter((res) => !!res.result),
        mergeMap(() => this._userService.getUser()),
        takeUntil(this._stop$),
      )
      .subscribe(
        () => {
          util.successMsg({ msgKey: "services.nda.upload.success" });
        },
        () => {
          util.dangerMsg({ msgKey: "services.nda.upload.failure" });
        },
      );
  }
}
