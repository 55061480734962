import { Component, OnInit, Inject } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { TRUST_ENTITY_REPLACE_REASON } from "app/models/constants";
import { IReplaceTrustEntityReasonDialogData } from "app/models";

@Component({
  selector: "app-replace-trust-entity-reason-dialog",
  templateUrl: "./replace-trust-entity-reason-dialog.component.html",
  styleUrls: ["./replace-trust-entity-reason-dialog.component.scss"],
})
export class ReplaceTrustEntityReasonDialogComponent implements OnInit {
  reasons: any[];
  reasonForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ReplaceTrustEntityReasonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IReplaceTrustEntityReasonDialogData,
    private readonly _fb: FormBuilder,
  ) {
    this._setVariables();
  }

  ngOnInit(): void {
    this.reasons = TRUST_ENTITY_REPLACE_REASON[this.data.humanType];
  }

  private _setVariables(): void {
    this.reasons = [];
    this.reasonForm = this._fb.group({
      reason: ["", Validators.required],
    });
  }

  onSelectReason(): void {
    if (this.reasonForm.invalid) {
      this.reasonForm.markAllAsTouched();
      return;
    }

    this.dialogRef.close({ reason: this.reasonForm.value.reason });
  }
}
