<div class="main-content">
  <div class="container-fluid">
    <div class="col-12 d-flex justify-content-end pr-0 mb-2">
      <button mat-raised-button class="btn btn-warning text-capitalize" (click)="onRefreshNda()">
        <i class="material-icons">refresh</i>
        Refresh NDA
      </button>
    </div>
    <div class="card nda-card mt-0" *ngIf="!!nda">
      <div class="card-header d-flex align-items-baseline">
        <span class="f-18-500 text-primary">Non-Disclosure Agreement</span>
      </div>
      <div class="card-body">
        <div class="row w-100 justify-content-center align-items-center">
          <div
            class="col-12 d-flex align-items-center flex-column"
            *ngIf="
              productStatus === 'Doc ready' ||
              productStatus === 'Downloaded' ||
              productStatus === 'Uploaded' ||
              productStatus === 'Completed'
            "
          >
            <span class="f-16-500">File Download:</span>
            <span class="f-16-400 text-gray">Non-Disclosure</span>
            <span class="f-16-400 text-gray">Agreement</span>
            <button
              mat-raised-button
              class="btn btn-warning text-capitalize download-btn-size"
              (click)="onDownloadDoc()"
            >
              <i class="material-icons">download</i>
              Download
            </button>
          </div>
          <div *ngIf="productStatus === 'Downloaded'">
            <div
              class="col-12 mt-3 d-flex align-items-center flex-column"
              *ngIf="!isUploaded; else uploadNda"
            >
              <span class="f-16-500">File Upload:</span>
              <span class="f-16-400 text-gray">Non-Disclosure</span>
              <span class="f-16-400 text-gray">Agreement</span>
              <input
                type="file"
                id="nda-doc-upload"
                style="visibility: none"
                [hidden]="true"
                name="nda-doc-upload"
                accept=".pdf"
                (change)="onUploadSignedDoc($event)"
              />
              <label class="caption" for="nda-doc-upload" class="btn btn-warning">
                Choose File
              </label>
            </div>
            <ng-template #uploadNda>
              <div class="col-12 d-flex align-items-center flex-column my-2">
                Non-Disclosure Agreement uploaded
              </div>
            </ng-template>
          </div>
          <div class="col-12 mt-3 text-center" *ngIf="productStatus === 'Uploaded'">
            <div class="mb-2">Your upload was successful!</div>
            <div>
              Note: Universal law will now review the submitted information. Please allow for 2-3
              business days to review the information.
            </div>
            <div>
              If you have any questions or concerns, or if this is an urgent matter, we invite you
              to reach out to us at Support@8UniversalLaw.com.
            </div>
          </div>
          <div class="col-12 mt-3 text-center" *ngIf="productStatus === 'Completed'">
            <span>Congratulations! The non-disclosure agreement process is complete.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
