import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";

import { Observable } from "rxjs";
import { finalize, tap } from "rxjs/operators";

import set from "lodash/set";

import { EventsService, SessionService } from "../services";

const PUT_JSON_REQUESTS = [
  "address",
  "tax",
  "gsa",
  "elienone",
  "elientwo",
  "elienincident",
  "nnc",
  "elien2v",
  "masterbond",
  "gsa2v",
  "tax2v",
  "irvic",
];

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  activeRequestsCount: number;

  constructor(private _uLawEvent: EventsService, private _uLawSession: SessionService) {
    this.setVariables();
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};

    const { method, url } = req;
    const isPutJSONRequest = PUT_JSON_REQUESTS.some((el) => url.includes(el));

    if (method !== "PUT" || isPutJSONRequest) {
      set(headers, "Content-Type", "application/json; charset=utf-8");
      set(headers, "Accept", "application/json");
    }

    const request = req.clone({ setHeaders: headers });

    this.activeRequestsCount++;

    if (this.activeRequestsCount > 0) {
      this._uLawEvent.showLoadingSpinner();
    }

    return next
      .handle(request)
      .pipe(tap(this.onStartRequest.bind(this)), finalize(this.onFinalizeRequest.bind(this)));
  }

  setVariables(): void {
    this.activeRequestsCount = 0;
  }

  onStartRequest(): void {
    this._uLawSession.setLastActiveTime();
  }

  onFinalizeRequest(): void {
    this.activeRequestsCount--;

    if (this.activeRequestsCount === 0) {
      this._uLawEvent.hideLoadingSpinner();
    }
  }
}
