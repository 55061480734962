import { Routes } from "@angular/router";

import { UserStatus } from "@models/enums";
import { AuthGuard, UserStatusGuard, UnderMaintenanceGuard } from "@core/guards";

import {
  AuthLayoutComponent,
  AdminLayoutComponent,
  PageNotFoundComponent,
  NotifyMaintainPageComponent,
} from "./layouts";
import { NdaGuard } from "./core/guards/nda.guard";
import { IrvicGuard } from "./core/guards/irvic.guard";

export const AppRoutes: Routes = [
  {
    path: "",
    redirectTo: "dashboard",
    pathMatch: "full",
  },
  {
    path: "",
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    // canActivate: [UnderMaintenanceGuard], // only for maintenance
    children: [
      {
        path: "",
        loadChildren: () =>
          import("./pages/dashboard/dashboard.module").then((m) => m.DashboardModule),
      },
      {
        path: "profile",
        loadChildren: () => import("./pages/profile/profile.module").then((m) => m.ProfileModule),
      },
      {
        path: "trust",
        loadChildren: () => import("./pages/trust/trust.module").then((m) => m.TrustModule),
      },
      {
        path: "spc-amend",
        loadChildren: () =>
          import("./pages/spc-amendment/spc-amendment.module").then((m) => m.SpcAmendmentModule),
      },
      {
        path: "services",
        canActivate: [UserStatusGuard],
        data: {
          userStatusArr: [UserStatus.SECURED],
        },
        loadChildren: () =>
          import("./pages/services/services.module").then((m) => m.ServicesModule),
      },
      {
        path: "nda",
        canActivate: [NdaGuard],
        loadChildren: () => import("./pages/nda/nda.module").then((m) => m.NdaModule),
      },
      {
        path: "irvic",
        data: {
          userStatusArr: [
            UserStatus.VERIFIED,
            UserStatus.AUTHENTICATION_REVIEW,
            UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
            UserStatus.AUTHENTICATION_SIGNATURE_REVIEW,
            UserStatus.UCC3_SUBMITTED_TO_UCC,
            UserStatus.AUTHENTICATION_COMPLETED,
            UserStatus.SECURED,
          ],
        },
        canActivate: [IrvicGuard],
        loadChildren: () => import("./pages/irvic/irvic.module").then((m) => m.IrvicModule),
      },
    ],
  },
  {
    path: "",
    component: AuthLayoutComponent,
    // canActivate: [UnderMaintenanceGuard], // only for maintenance
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/auth/auth.module").then((m) => m.AuthModule),
      },
    ],
  },
  {
    path: "instruction",
    // canActivate: [UnderMaintenanceGuard], // only for maintenance
    loadChildren: () =>
      import("./pages/instruction/instruction.module").then((m) => m.InstructionModule),
  },
  {
    path: "maintenance",
    component: NotifyMaintainPageComponent,
  },
  {
    path: "**",
    component: PageNotFoundComponent,
  },
];
