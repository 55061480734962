import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { forkJoin } from "rxjs";

import { Country } from "app/models";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class AppInitService {
  constructor(private _http: HttpClient, private _store: StateService) {}

  init() {
    return new Promise<any>((resolve, _ignore) => {
      forkJoin({
        config: this._http.get("config/config.json"),
        countries: this._http.get("assets/countries.json"),
        currencies: this._http.get("assets/currencies.json"),
        dialCodes: this._http.get("assets/dial-codes.json"),
      }).subscribe(({ config, countries, currencies, dialCodes }) => {
        this.setConfig(config);
        this.setCountries(countries);
        this.setCurrencies(currencies);
        this.setDialCodes(dialCodes);

        resolve({ config, countries, currencies, dialCodes });
      });
    });
  }

  setConfig(config): void {
    this._store.setConfig(config);
  }

  setCountries(data): void {
    const countries: any[] = data;
    const tempStates = countries.find((country) => country.name === Country.US).states;
    const fullStates = tempStates.map((state) => ({
      name: state.name,
      abb: state["alpha-2"],
    }));
    const states = tempStates.map((state) => state.name);

    this._store.setStates(states);
    this._store.setFullStates(fullStates);
    this._store.setCountries(countries);
  }

  setCurrencies(data): void {
    const currencies = Object.entries(data).map((currency: any) => ({
      ...currency[1],
    }));
    this._store.setCurrencies(currencies);
  }

  setDialCodes(data: any): void {
    this._store.setDialCodes(data);
  }
}
