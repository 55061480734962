import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from "@angular/core";
import { MatSnackBarRef } from "@angular/material/snack-bar";

import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { NO_CB, SESSION_TIME } from "@models/constants";

import { SessionService } from "@core/services";

/**
 * SessionSnackBarComponent
 *
 * If user doesn't do any action against our web app for 15 mins,
 * then user's session will be automatically destroyed.
 * This component shows snackbar that notifies users
 */
@Component({
  selector: "ulaw-session-snack-bar",
  templateUrl: "./session-snack-bar.component.html",
  styleUrls: ["./session-snack-bar.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SessionSnackBarComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  sessionTimeInSecond: number;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _snackBarRef: MatSnackBarRef<SessionSnackBarComponent>,
    private _uLawSession: SessionService,
  ) {
    this.setVariables();
  }

  ngOnInit(): void {
    this.getSessionTime();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();
    this.sessionTimeInSecond = 0;
  }

  getSessionTime(): void {
    this._uLawSession.sessionTime
      .pipe(tap(this.setSessionTime.bind(this)), takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  setSessionTime(value: number): void {
    this.sessionTimeInSecond = SESSION_TIME - value;
    this._cdRef.detectChanges();

    // if session time ends
    if (this.sessionTimeInSecond <= 0) {
      this._snackBarRef.dismiss();
    }
  }

  onContinueSession(): void {
    this._snackBarRef.dismissWithAction();
  }
}
