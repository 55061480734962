import { Component, OnInit, Inject, OnDestroy } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { Subject, combineLatest } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";

import { NO_CB } from "@models/constants";

import { OverlayService } from "@core/services";

@Component({
  selector: "app-upload-indicator-dialog",
  templateUrl: "./upload-indicator-dialog.component.html",
  styleUrls: ["./upload-indicator-dialog.component.scss"],
})
export class UploadIndicatorDialogComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  filename: string;
  percent: number;

  constructor(
    public dialogRef: MatDialogRef<UploadIndicatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _overlayService: OverlayService,
  ) {
    this.setVariables();
  }

  ngOnInit(): void {
    combineLatest([
      this._overlayService.isShowUploadBar.pipe(tap(this.setOpenStatus.bind(this))),
      this._overlayService.uploadPercent.pipe(tap(this.setUploadPercent.bind(this))),
      this._overlayService.uploadingFilename.pipe(tap(this.setFilename.bind(this))),
    ])
      .pipe(takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();

    this.filename = "";
    this.percent = 0;
  }

  setFilename(filename): void {
    this.filename = filename ?? "";
  }

  setUploadPercent(percent): void {
    this.percent = percent;
  }

  setOpenStatus(isShow): void {
    if (!isShow) {
      this.dialogRef.close();
    }
  }
}
