import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit } from "@angular/core";
import { Location, PopStateEvent } from "@angular/common";
import { Router, NavigationEnd, NavigationStart } from "@angular/router";
import { Overlay, OverlayConfig, OverlayRef } from "@angular/cdk/overlay";
import { CdkPortal } from "@angular/cdk/portal";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";

import { Subject, combineLatest } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import { NgxSpinnerService } from "ngx-spinner";
import PerfectScrollbar from "perfect-scrollbar";

import { NO_CB } from "@models/constants";
import { INavItem } from "@models/models";
import { NavItemType, PdfType } from "@models/enums";

import { util } from "app/util";
import { getDialogConfig } from "@util/dialog-util";

import { OverlayService, StateService, EventsService, DrawerService } from "@core/services";

import { NavbarComponent } from "@shared/navbar/navbar.component";
import { UploadIndicatorDialogComponent } from "app/components";

declare const $: any;

@Component({
  selector: "app-layout",
  templateUrl: "./admin-layout.component.html",
})
export class AdminLayoutComponent implements OnInit, AfterViewInit, OnDestroy {
  public navItems: INavItem[];
  private lastPoppedUrl: string;
  private yScrollStack: number[] = [];

  // user defined variables
  private _stop$: Subject<any> = new Subject();
  private _overlayRef: OverlayRef;
  private _windowWidth: any;
  private _windowHeight: any;

  url: string;
  location: Location;
  isShowScrollTop: boolean;

  // overlay variables
  videoKey: string;
  pdf: any;

  // side-drawer variables
  drawerContent: any;

  @ViewChild("sidebar", { static: false }) sidebar: any;
  @ViewChild(NavbarComponent, { static: false }) navbar: NavbarComponent;
  @ViewChild("videoPortal", { static: false }) videoPortal: CdkPortal;
  @ViewChild("pdfViewerPortal", { static: false }) pdfViewerPortal: CdkPortal;
  @ViewChild("sideDrawer", { static: false }) sideDrawer: MatDrawer;

  constructor(
    public dialog: MatDialog,
    private router: Router,
    location: Location,
    private _overlay: Overlay,
    private _spinner: NgxSpinnerService,
    private _uLawState: StateService,
    private _uLawEvent: EventsService,
    private _uLawDrawer: DrawerService,
    private _uLawOverlay: OverlayService,
  ) {
    this.location = location;
    this.isShowScrollTop = false;
    this.drawerContent = {};
  }

  ngOnInit() {
    this.initULawEventListener();
    this.initULawOverlayListener();
    this.initULawDrawerListener();

    this.getWindowSize();

    const elemMainPanel = document.querySelector(".main-panel") as HTMLElement;
    const elemSidebar = document.querySelector(".sidebar .sidebar-wrapper") as HTMLElement;
    this.location.subscribe((ev: PopStateEvent) => {
      this.lastPoppedUrl = ev.url;
    });
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationStart) {
        if (event.url !== this.lastPoppedUrl) {
          this.yScrollStack.push(window.scrollY);
        }
      } else if (event instanceof NavigationEnd) {
        if (event.url === this.lastPoppedUrl) {
          this.lastPoppedUrl = undefined;
          window.scrollTo(0, this.yScrollStack.pop());
        } else {
          window.scrollTo(0, 0);
        }
      }
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        elemMainPanel.scrollTop = 0;
        elemSidebar.scrollTop = 0;
      });
    const html = document.getElementsByTagName("html")[0];
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      html.classList.add("perfect-scrollbar-on");
    } else {
      html.classList.add("perfect-scrollbar-off");
    }
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.navbar.sidebarClose();
      });

    this.navItems = [
      {
        type: NavItemType.NavbarLeft,
        title: "Dashboard",
        iconClass: "fa fa-dashboard",
      },

      {
        type: NavItemType.NavbarRight,
        title: "",
        iconClass: "fa fa-bell-o",
        numNotifications: 5,
        dropdownItems: [
          { title: "Notification 1" },
          { title: "Notification 2" },
          { title: "Notification 3" },
          { title: "Notification 4" },
          { title: "Another Notification" },
        ],
      },
      {
        type: NavItemType.NavbarRight,
        title: "",
        iconClass: "fa fa-list",

        dropdownItems: [
          { iconClass: "pe-7s-mail", title: "Messages" },
          { iconClass: "pe-7s-help1", title: "Help Center" },
          { iconClass: "pe-7s-tools", title: "Settings" },
          "separator",
          { iconClass: "pe-7s-lock", title: "Lock Screen" },
          { iconClass: "pe-7s-close-circle", title: "Log Out" },
        ],
      },
      {
        type: NavItemType.NavbarLeft,
        title: "Search",
        iconClass: "fa fa-search",
      },

      { type: NavItemType.NavbarLeft, title: "Account" },
      {
        type: NavItemType.NavbarLeft,
        title: "Dropdown",
        dropdownItems: [
          { title: "Action" },
          { title: "Another action" },
          { title: "Something" },
          { title: "Another action" },
          { title: "Something" },
          "separator",
          { title: "Separated link" },
        ],
      },
      { type: NavItemType.NavbarLeft, title: "Log out" },
    ];
  }

  ngAfterViewInit(): void {
    this.runOnRouteChange();

    $(".main-panel").on("scroll", (ev) => {
      const scrollTopPos = ev.target.scrollTop;
      this.isShowScrollTop = scrollTopPos >= 100;
    });
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  getWindowSize(): void {
    combineLatest([this._uLawState.windowWidth, this._uLawState.windowHeight]).subscribe(
      ([width, height]) => {
        this._windowWidth = width;
        this._windowHeight = height;
      },
    );
  }

  initULawEventListener(): void {
    this._uLawEvent.isShowLoadingSpinner
      .pipe(
        tap((isShow: boolean) => {
          isShow && this._spinner.show();
          !isShow && this._spinner.hide();
        }),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }

  initULawOverlayListener(): void {
    combineLatest([
      this._uLawOverlay.videoKey.pipe(tap(this.showVideoPlayer.bind(this))),
      this._uLawOverlay.pdf.pipe(tap(this.showPdfViewer.bind(this))),
      this._uLawOverlay.isShowUploadBar.pipe(filter(Boolean), tap(this.showUploadBar.bind(this))),
    ])
      .pipe(takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  initULawDrawerListener(): void {
    combineLatest([
      this._uLawDrawer.content.pipe(tap((content) => (this.drawerContent = content))),
      this._uLawDrawer.open.pipe(tap(() => this.sideDrawer.open())),
      this._uLawDrawer.close.pipe(tap(() => this.sideDrawer.close())),
    ])
      .pipe(takeUntil(this._stop$))
      .subscribe(NO_CB);
  }

  showVideoPlayer(videoKey): void {
    this.videoKey = videoKey;
    const { width, height } = util.getVideoOverlaySize(this._windowWidth);
    this._overlayRef = this.getOverlayRef(height, width);
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.dispose();
    });
    this._overlayRef.attach(this.videoPortal);
  }

  showPdfViewer(pdf): void {
    this.pdf = pdf;
    const { width, height } = util.getPdfOverlaySize(this._windowWidth, this._windowHeight);
    this._overlayRef = this.getOverlayRef(height, width);
    this._overlayRef.backdropClick().subscribe(() => {
      this._overlayRef.dispose();
    });
    this._overlayRef.attach(this.pdfViewerPortal);
  }

  showUploadBar(isShowUploadBar): void {
    if (!isShowUploadBar) {
      return;
    }

    const uploadDialogConfig = getDialogConfig({
      width: "320px",
      position: { top: "24px", right: "16px" },
      panelClass: "upload-progress-bar",
      backdropClass: "upload-progress-bar-back-drop",
    });
    this.dialog.open(UploadIndicatorDialogComponent, uploadDialogConfig);
  }

  onDetachOverlay(): void {
    this._overlayRef.detach();
  }

  onLoadPdfError(): void {
    const { type, sku, changedDoc = false } = this.pdf;
    this._overlayRef.detach();

    let msgKey = "";
    switch (type) {
      case PdfType.SPC:
        msgKey = `pdf.${type}.${sku}`;
        break;
      case PdfType.TRUST:
        msgKey = changedDoc ? `pdf.${type}.changed` : `pdf.${type}.default`;
        break;
      default:
        break;
    }

    util.dangerMsg({ msgKey });
  }

  getOverlayRef(height: string, width: string) {
    const positionStrategy = this._overlay
      .position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      positionStrategy,
      height,
      width,
    });
    overlayConfig.hasBackdrop = true;

    const overlayRef = this._overlay.create(overlayConfig);
    return overlayRef;
  }

  onCloseSideDrawer(): void {
    this.sideDrawer.close();
  }

  public isMap() {
    if (this.location.prepareExternalUrl(this.location.path()) === "/maps/fullscreen") {
      return true;
    }
    return false;
  }

  runOnRouteChange(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = document.querySelector(".sidebar .sidebar-wrapper") as HTMLElement;
      const elemMainPanel = document.querySelector(".main-panel") as HTMLElement;
      let ps = new PerfectScrollbar(elemMainPanel);
      ps = new PerfectScrollbar(elemSidebar);
      ps.update();
    }
  }

  isMac(): boolean {
    let bool = false;
    if (
      navigator.platform.toUpperCase().indexOf("MAC") >= 0 ||
      navigator.platform.toUpperCase().indexOf("IPAD") >= 0
    ) {
      bool = true;
    }
    return bool;
  }
}
