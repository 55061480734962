import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import PerfectScrollbar from "perfect-scrollbar";

import { Subject } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import { NO_CB } from "@models/constants";
import { UserCitizenStatus, UserStatus } from "@models/enums";

import { StateService } from "@core/services";

declare const $: any;

// Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  collapse?: string;
  userStatusArr: UserStatus[];
  userCitizenStatusArr: UserCitizenStatus[];
  children?: ChildrenItems[];
  showFieldName?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab: string;
  type?: string;
  gsaSensitive?: boolean;
  easyLienSensitive?: boolean;
  nncSensitive?: boolean;
  masterBondSensitive?: boolean;
  taxSensitive?: boolean;
}

// Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    type: "link",
    icontype: "dashboard",
    userStatusArr: [],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
  },
  {
    path: "/profile",
    title: "SPC Profile",
    type: "link",
    icontype: "person_outline",
    userStatusArr: [],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
  },
  {
    path: "/spc-amend",
    title: "SPC Addendum",
    type: "link",
    icontype: "text_format",
    userStatusArr: [],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
    ],
  },
  {
    path: "/trust",
    title: "Trust",
    type: "link",
    icontype: "verified_user",
    userStatusArr: [],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
  },
  {
    path: "/nda",
    title: "Non-Disclosure Agreement",
    type: "link",
    icontype: "gavel",
    showFieldName: "showNda",
    userStatusArr: [],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
  },
  {
    path: "/irvic",
    title: "Test",
    type: "link",
    icontype: "settings_alert",
    userStatusArr: [
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.AUTHENTICATION_SIGNATURE_REVIEW,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.SECURED,
    ],
    showFieldName: "showIrvic",
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
  },
  {
    path: "/services",
    title: "Additional Services",
    type: "sub",
    icontype: "miscellaneous_services",
    collapse: "services",
    userStatusArr: [UserStatus.SECURED],
    userCitizenStatusArr: [
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.US_PERMANENT_RESIDENT,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
      UserCitizenStatus.NA,
    ],
    children: [
      {
        path: "nnc",
        title: "Notice Of Non-Consent",
        ab: "N",
        nncSensitive: true,
      },
      {
        path: "master-bond",
        title: "Master Bond",
        ab: "M",
        masterBondSensitive: true,
      },
      {
        path: "tax-settlement",
        title: "Tax Settlement",
        ab: "T",
        taxSensitive: true,
      },
      {
        path: "gsa-bond",
        title: "GSA Bond",
        ab: "G",
        gsaSensitive: true,
      },
      {
        path: "easy-lien",
        title: "Easy Lien",
        ab: "E",
        easyLienSensitive: true,
      },
    ],
  },
];
@Component({
  selector: "app-sidebar-cmp",
  templateUrl: "sidebar.component.html",
})
export class SidebarComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  user: any;
  menuItems: any[];
  ps: any;

  constructor(private _cdRef: ChangeDetectorRef, private _store: StateService) {
    this.setVariables();
  }

  ngOnInit(): void {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      const elemSidebar = document.querySelector(".sidebar .sidebar-wrapper") as HTMLElement;
      this.ps = new PerfectScrollbar(elemSidebar);
    }

    this.getUser();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();
  }

  getUser(): void {
    this._store.user
      .pipe(
        tap((user) => {
          this.user = user;
          this._cdRef.detectChanges();
        }),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }

  isMobileMenu() {
    const windowWidth = $(window).width();
    return !(windowWidth > 991);
  }

  updatePS(): void {
    if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
      this.ps.update();
    }
  }

  isMac(): boolean {
    const { platform } = navigator;
    const isAppleDevice =
      platform.toUpperCase().includes("MAC") || platform.toUpperCase().includes("IPAD");
    return isAppleDevice;
  }
}
