import { GsaProductStatus, GSABondCaseCategory } from "../enums";

export const GSA_REVIEW_STATUS_LIST = [GsaProductStatus.REJECTED];

export const GSA_READY_STATUS_LIST = [GsaProductStatus.ACCEPTED, GsaProductStatus.DOWNLOADED];

export const GSA_BOND_CASE_TYPE = [
  {
    label: "Civil",
    value: GSABondCaseCategory.CIVIL,
  },
  {
    label: "Criminal",
    value: GSABondCaseCategory.CRIMINAL,
  },
];

export const GSA_V2_FIELDS = [
  "plaintiffs",
  "defendants",
  "caseNumber",
  "judgeFirstName",
  "judgeMiddleName",
  "judgeLastName",
  "judgeCertMailNum",
  "judgeCertMailNum2",
  "irsCertMail",
  "dtcCertMailNum",
  "treasuryCertMailNum",
  "courtDistrictName",
  "courtAddress",
  "courtCity",
  "courtState",
  "gsaNState",
  "courtCounty",
  "gsaNCounty",
  "courtZip",
  "caseFileDate",
  "judgeCertMail2Ddate",
  "judgeCertMailDdate",
  "irsCertMailDdate",
  "dtcCertMailDdate",
  "treasuryCertMailDdate",
  "caseType",
  "gsaFee",
  "judgeSuffix",
];
