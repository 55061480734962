import {
  ADDR_VALIDATION_MSG,
  EXT_PROFILE_VALIDATION_MSG,
  TRUST_BASIC_VALIDATION_MSG,
  NCC_FORM_VALIDATION_MSG,
} from "app/models/constants";

import { fileUtil } from "./file-util";
import { filterUtil } from "./filter-util";
import { profileUtil } from "./profile-util";
import { notificationUtil } from "./notification-util";
import { isMobile, getWidth, getHeight } from "./common-util";

function Util() {
  this._filter = filterUtil;
  this._profile = profileUtil;
  this._fileManager = fileUtil;
  this._notification = notificationUtil;
}

Util.prototype.getNccFormValidationMsg = () => {
  return NCC_FORM_VALIDATION_MSG;
};

Util.prototype.getAddressValidationMsg = () => {
  return ADDR_VALIDATION_MSG;
};

Util.prototype.getExtProfileValidationMsg = () => {
  return EXT_PROFILE_VALIDATION_MSG;
};

Util.prototype.getTrustBasicValidationMsg = () => {
  return TRUST_BASIC_VALIDATION_MSG;
};

Util.prototype.getTrustSearchValidationMsg = () => {
  return TRUST_BASIC_VALIDATION_MSG;
};

Util.prototype.filterCountry = function (countries: any[], key: string) {
  return this._filter.filterCountry(countries, key);
};

Util.prototype.filterState = function (states: any[], key: string) {
  return this._filter.filterState(states, key);
};

Util.prototype.isInternationalUser = function (status: string): boolean {
  return this._profile.isInternationalUser(status);
};

Util.prototype.getFullState = function (abb: string, states: any[]) {
  return this._profile.getFullState(abb, states);
};

Util.prototype.infoMsg = function ({ isOrigin = false, msgKey = "", originMsg = "" }) {
  this._notification.info({ isOrigin, msgKey, originMsg });
};

Util.prototype.successMsg = function ({ isOrigin = false, msgKey = "", originMsg = "" }) {
  this._notification.success({ isOrigin, msgKey, originMsg });
};

Util.prototype.warnMsg = function ({ isOrigin = false, msgKey = "", originMsg = "" }) {
  this._notification.warning({ isOrigin, msgKey, originMsg });
};

Util.prototype.dangerMsg = function ({ isOrigin = false, msgKey = "", originMsg = "" }) {
  this._notification.danger({ isOrigin, msgKey, originMsg });
};

Util.prototype.savePdf = function (blob, filename) {
  this._fileManager.saveFile(blob, filename);
};

Util.prototype.saveZip = function (arrayBuffer: ArrayBuffer, filename) {
  const blob = new Blob([arrayBuffer], {
    type: "application/zip",
  });
  this._fileManager.saveFile(blob, filename);
};

Util.prototype.printPdf = function (blob, filename) {
  this._fileManager.printPdf(blob, filename);
};

Util.prototype.isMobile = isMobile;

Util.prototype.getWindowSize = function () {
  const width = getWidth();
  const height = getHeight();
  return { width, height };
};

Util.prototype.getVideoOverlaySize = function (windowWidth: number) {
  const isMobileView = Boolean(this.isMobile.any());
  const width = isMobileView ? `${windowWidth}px` : "720px";
  const height = isMobileView ? `${(windowWidth * 9) / 16}px` : "480px";
  return { width, height };
};

Util.prototype.getPdfOverlaySize = function (windowWidth: number, windowHeight: number) {
  const isMobileView = Boolean(this.isMobile.any());
  const width = isMobileView ? `${windowWidth - 24}px` : `${windowWidth - 480}px`;
  const height = `${windowHeight - 24}px`;
  return { width, height };
};

Util.prototype.deepCopyFn = function (inObj) {
  let outObj;

  if (typeof inObj !== "object" || inObj === null) {
    return inObj;
  }

  // eslint-disable-next-line prefer-const
  outObj = Array.isArray(inObj) ? [] : {};
  Object.keys(inObj).forEach((key) => {
    const value = inObj[key];
    outObj[key] = this.deepCopyFn(value);
  });

  return outObj;
};

Util.prototype.getPlainPhoneNumber = function (phone: string) {
  return phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");
};

export const util = new Util();
