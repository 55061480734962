<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-10 offset-1">
        <div class="row">
          <div class="col-12 col-sm-4">
            <img alt="footer-logo" src="assets/img/footer-logo.png">
          </div>
          <div class="col-12 col-sm-4">
            <p class="text-left text-uppercase">Have a Question?</p>
            <div class="d-flex justify-content-start align-items-center">
              <i class="material-icons mr-2"> alternate_email </i>
              <span>Email Us&nbsp;:&nbsp;</span>
              <span class="footer-content">&nbsp;support@8universallaw.com&nbsp;</span>
            </div>
            <div class="d-flex justify-content-start align-items-center">
              <i class="material-icons mr-2"> call </i>
              <span>Call Us&nbsp;:&nbsp;&nbsp;&nbsp;</span>
              <span class="footer-content">&nbsp;561-581-1030&nbsp;</span>
            </div>
          </div>
          <div class="col-12 col-sm-4 text-left mt-2">
            <span class="mb-0 footer-content" style="white-space: nowrap;">
              Copyright &copy; 2020-2021 Universal Law
            </span>
            <br />
            <span class="mb-0 footer-content">
              All Rights Reserved.
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>
