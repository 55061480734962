import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";

import { Observable, throwError } from "rxjs";
import { catchError, filter, tap } from "rxjs/operators";

import { AuthService, JwtService } from "../services";

@Injectable()
export class HttpResponseInterceptor implements HttpInterceptor {
  constructor(
    private _router: Router,
    private _uLawAuth: AuthService,
    private _uLawJwt: JwtService,
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter((ev) => ev instanceof HttpResponse),
      tap(this.refreshJwt.bind(this)),
      catchError(this.formatError.bind(this)),
    );
  }

  refreshJwt(ev: HttpResponse<any>): void {
    const newJwt = ev.headers.get("authorization");
    newJwt && this._uLawJwt.saveToken(newJwt);
  }

  formatError(err: HttpErrorResponse): Observable<any> {
    const { status, error } = err;

    // token expired
    console.warn("http response error: ", err);
    if (status === 401) {
      this._uLawAuth.purgeAuth();
      this._router.navigate(["/login"]);
    }

    return throwError(error);
  }
}
