import { EasyLienProductStatus } from "../enums";
import { EasyLienOffenseType } from "../models";

export const NOTICE_EXEMPTION_VISIBLE_STATUS_ARR = [
  EasyLienProductStatus.EXEMPTION_REVIEW,
  EasyLienProductStatus.EXEMPTION_REJECT,
  EasyLienProductStatus.EXEMPTION_READY,
  EasyLienProductStatus.EXEMPTION_DELIVERED,
  EasyLienProductStatus.DEFAULT_READY,
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const NOTICE_DEFAULT_VISIBLE_STATUS_ARR = [
  EasyLienProductStatus.EXEMPTION_DELIVERED,
  EasyLienProductStatus.DEFAULT_READY,
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const NOTICE_LIEN_VISIBLE_STATUS_ARR = [
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const NOTICE_EXEMPTION_REVIEW_STATUS_ARR = [
  EasyLienProductStatus.EXEMPTION_REVIEW,
  EasyLienProductStatus.EXEMPTION_REJECT,
];

export const NOTICE_EXEMPTION_READY_STATUS_ARR = [
  EasyLienProductStatus.EXEMPTION_READY,
  EasyLienProductStatus.EXEMPTION_DELIVERED,
  EasyLienProductStatus.DEFAULT_READY,
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const NOTICE_DEFAULT_REVIEW_STATUS_ARR = [EasyLienProductStatus.EXEMPTION_DELIVERED];

export const NOTICE_DEFAULT_READY_STATUS_ARR = [
  EasyLienProductStatus.DEFAULT_READY,
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const EASY_LIEN_REVIEW_STATUS_ARR = [
  EasyLienProductStatus.DEFAULT_DELIVERED,
  EasyLienProductStatus.LIEN_UCC,
  EasyLienProductStatus.LIEN_ACCEPTED,
];

export const EASY_LIEN_READY_STATUS_ARR = [
  EasyLienProductStatus.LIEN_READY,
  EasyLienProductStatus.COMPLETED,
];

export const NOTICE_EXEMPTION_FIELDS = [
  "businessName",
  "employeeName",
  "bizAccountNum",
  "businessAddress",
  "documentNumber",
  "empID",
  "bizCity",
  "bizState",
  "bizZip",
  "bizRegMailNum1",
  "bizRegMailNum2",
  "bizRegMailNum3",
  "datePacketMailed1",
  "datePacketMailed2",
  "datePacketMailed3",
  "incidentStatement",
  "fineAmount",
  "lienCountyName",
  "lienState",
];

export const NOTICE_DEFAULT_FIELDS = [
  "bizRegMailNum2",
  "bizRegMailNum3",
  "employeeName",
  "empID",
  "incidentDate",
];

export const EasyLienOffenseTypes: EasyLienOffenseType[] = [
  {
    value: "flexible",
    label: "Flexible",
  },
  {
    value: "copyright",
    label: "Meta Copyright",
  },
];
