/**
 * Enum of User status in ULaw
 *
 * @enum {string} User Status
 */
export enum UserStatus {
  INVITED = "Invite",
  NEW_USER = "New User",
  SPC_REQUIRED = "SPC Required",
  SPC_REVIEW = "SPC Review",
  SPC_SIGNATURE_REQUIRED = "SPC Signature Required",
  SPC_SIGNATURE_REVIEW = "SPC Signature Review",
  SPC_SUBMITTED_TO_UCC = "SPC Submitted to UCC",
  VERIFIED = "Verified",
  AUTHENTICATION_REVIEW = "Authentication Review",
  AUTHENTICATION_SIGNATURE_REQUIRED = "Authentication Signature Required",
  AUTHENTICATION_SIGNATURE_REVIEW = "Authentication Signature Review",
  UCC3_SUBMITTED_TO_UCC = "UCC 3 submitted to UCC",
  AUTHENTICATION_COMPLETED = "Authentication Completed",
  TREASURY_PACKET_SENT = "Treasury Packet sent",
  SECURED = "Secured",
  DELETED = "Deleted",
}

/**
 * Enum of User Citizen Status in ULaw
 *
 * @enum {string} User Citizen Status
 */
export enum UserCitizenStatus {
  US_CITIZEN = "U.S. Citizen",
  US_PERMANENT_RESIDENT = "U.S. Permanent Resident",
  NATURALIZED_US_CITIZEN = "Naturalized U.S. Citizen",
  NA = "None of the Above",
}

/**
 *
 * @enum {string} Confirmed by user or ISO
 *
 * When user entered Address does not match ISO,
 * then user have to confirm their status
 */
export enum AddressConfirmStatus {
  API = "api",
  USER = "user",
}

/**
 * Enum of Sku which means the SPC product type in ULaw
 *
 * @enum {string} Sku
 */
export enum Sku {
  SPC = "spc",
  SPC_CONSENT = "spcConsent",
  SPC_REPORT = "spcReport",
  SPC_AMEND = "spcAmendment",
  SPC_AMEND_REPORT = "spcAmendmentReport",
  TREASURY_PACKET = "treasuryPacket",
  MASTER_BOND = "masterBond",
  MASTER_BOND_COVER_LETTER = "masterBondCoverLetter",
  TAX = "tax",
  TAX_V2 = "tax2v",
  GSA = "gsa",
  GSA_V2 = "gsa2v",
  IRVIC = "irvic",
  EASY_LIEN = "elien",
  EASY_LIEN_V2 = "elien2v",
  NNC = "nnc",
  INSTRUCTION_PDF = "InstructionsPDF",
  NDA = "nda",
}

/**
 * Enum of Mail service
 *
 * @enum {string} User Citizen Status
 *
 * When user add tracking number to ULaw products, use this mail service.
 */
export enum MailService {
  USPS = "USPS",
}
