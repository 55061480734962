export enum IrvicDocNames {
  DOC_1 = "doc1",
  DOC_2 = "doc2",
  DOC_3 = "doc3",
  DOC_4 = "doc4",
  DOC_5 = "doc5",
}

export enum IrvicProductStatus {
  DATA_REVIEW = "Data review",
  DATA_APPROVED = "Data approved",
  DATA_REJECTED = "Data reject",
  DOC_READY = "Doc ready",
  DOC_SIGNATURE_REVIEW = "Doc signature review",
  TRACKING = "Tracking",
  COMPLETED = "Completed",
}

export enum IrvicPaperDocStatus {
  READY = "Ready",
  SIGNED = "Signed",
  TRACKING = "Tracking",
  COMPLETED = "Completed",
}
