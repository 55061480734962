import {
  Instruction,
  LocalStorageKey,
  Product,
  Sku,
  UserCitizenStatus,
  UserStatus,
} from "../enums";

export const NO_CB: () => void = () => {};

// maximum upload file size
export const UPLOAD_FILE_SIZE_MAXIMUM = 90 * 1024 * 1024;

// time delay
export const SHORT_DELAY_IN_MILLISECONDS = 250;
export const LONG_DELAY_IN_MILLISECONDS = 3 * 1000;

// maximum tooltip show count
export const MAXIMUM_TOOLTIP_SHOW_COUNT = 7;

// session time
export const SESSION_TIME = 40 * 60; // 15mins
export const IDLE_TIME = 35 * 60; // 5mins

export const SPC_LOCAL_STORAGE_KEYS = {
  [Sku.SPC]: LocalStorageKey.SPC_DOWNLOAD,
  [Sku.SPC_CONSENT]: LocalStorageKey.SPC_CONSENT_DOWNLOAD,
  [Sku.SPC_AMEND]: LocalStorageKey.SPC_AMEND_DOWNLOAD,
  [Sku.TREASURY_PACKET]: LocalStorageKey.SPC_TREASURY_PACKET_DOWNLOAD,
};

// FIXME: update regular expressions for each field
export const ADDR_REG_EXP = {
  userFirstName: /^[a-zA-Z]+$/,
  userMiddleName: /^[a-zA-Z]+$/,
  userLastName: /^[a-zA-Z]+$/,
  userAddressLine1: /^[a-zA-Z0-9 ]+$/,
  // userCountry: /^[a-zA-Z]+$/, // disable only english characters for userCountry in address formGroup
  userCity: /^[a-zA-Z0-9 ]+$/,
  userState: /^[a-zA-Z0-9 ]+$/,
};

export const TUTORIALS = [
  {
    value: "profile.basic",
    label: "How to Set Address",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_REQUIRED,
      UserStatus.SPC_REVIEW,
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    isInternational: true,
  },
  {
    value: "profile.extended.USC",
    label: "How to Complete U.S Citizen Extended Profile",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_REQUIRED,
      UserStatus.SPC_REVIEW,
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    citizenStatus: UserCitizenStatus.US_CITIZEN,
    isInternational: false,
  },
  {
    value: "profile.extended.NUSC",
    label: "How to Complete Naturalized U.S. Citizen Extended Profile",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_REQUIRED,
      UserStatus.SPC_REVIEW,
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    citizenStatus: UserCitizenStatus.NATURALIZED_US_CITIZEN,
    isInternational: false,
  },
  {
    value: "profile.extended.USPR",
    label: "How to Complete U.S. Permanent Resident Extended Profile",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_REQUIRED,
      UserStatus.SPC_REVIEW,
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    citizenStatus: UserCitizenStatus.US_PERMANENT_RESIDENT,
    isInternational: false,
  },
  {
    value: "profile.spc.signature",
    label: "How to Sign SPC Packet",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    isInternational: false,
  },
  {
    value: "spcamend",
    label: "How to Complete SPC Addendum",
    product: Product.SPC,
    userStatues: [
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    isInternational: false,
  },
  {
    value: "trust.setup",
    label: "How to Setup Trust",
    product: Product.TRUST,
  },
  {
    value: "trust.naming",
    label: "How to Name Trust",
    product: Product.TRUST,
  },
  {
    value: "trust.humans",
    label: "How to Add Humans to Trust",
    product: Product.TRUST,
  },
  {
    value: "trust.execution.signature",
    label: "How to Sign Trust",
    product: Product.TRUST,
  },
  {
    value: "trust.execution.tracking",
    label: "How to Track Trust Product",
    product: Product.TRUST,
  },
  {
    value: "masterbond.intro",
    label: "How to Setup Master Bond Product",
    product: Product.TAX,
  },
  {
    value: "masterbond.instruction",
    label: "Master Bond Instruction",
    product: Product.TAX,
  },
  {
    value: "taxsettlement.intro",
    label: "How to Setup Tax Settlement Product",
    product: Product.TAX,
  },
  {
    value: "taxsettlement.instruction",
    label: "Tax Settlement Instruction",
    product: Product.TAX,
  },
];

export const INSTRUCTIONS = [
  {
    instruction: Instruction.SPC_EXECUTION,
    label: "How to Execute the Secured Party Packet",
    product: Product.SPC,
    userStatues: [
      UserStatus.SPC_SIGNATURE_REQUIRED,
      UserStatus.SPC_SIGNATURE_REVIEW,
      UserStatus.SPC_SUBMITTED_TO_UCC,
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
  },
  {
    instruction: Instruction.AUTHENTICATION,
    label: "Authentication Instruction",
    product: Product.SPC,
    userStatues: [
      UserStatus.VERIFIED,
      UserStatus.AUTHENTICATION_REVIEW,
      UserStatus.AUTHENTICATION_SIGNATURE_REQUIRED,
      UserStatus.UCC3_SUBMITTED_TO_UCC,
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
  },
  {
    instruction: Instruction.TREASURY_PACKET_MAILING,
    label: "How to Prepare and Send The Treasury Packet",
    product: Product.SPC,
    userStatues: [
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    citizenStatus: [
      UserCitizenStatus.NA,
      UserCitizenStatus.US_CITIZEN,
      UserCitizenStatus.NATURALIZED_US_CITIZEN,
    ],
  },
  {
    instruction: Instruction.TREASURY_PACKET_MAILING_USPR,
    label: "How to Prepare and Send The Treasury Packet",
    product: Product.SPC,
    userStatues: [
      UserStatus.AUTHENTICATION_COMPLETED,
      UserStatus.TREASURY_PACKET_SENT,
      UserStatus.SECURED,
    ],
    citizenStatus: [UserCitizenStatus.US_PERMANENT_RESIDENT],
  },
  {
    instruction: Instruction.MASTER_BOND,
    label: "How to Prepare Master Bond",
    product: Product.TAX,
  },
  {
    instruction: Instruction.TAX_SETTLEMENT,
    label: "How to Prepare Tax Settlement",
    product: Product.TAX,
  },
  {
    instruction: Instruction.TRUST_QUICK_START_GUIDE,
    label: "Trust Quick Start Guide",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.TRUST_EXECUTION,
    label: "How to Execute the Declaration of Trust",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.REPLACE_PROTECTOR,
    label: "How to Replace the Protector",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.REPLACE_TRUSTEE,
    label: "How to Replace a Trustee",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.REPLACE_BENEFICIARY,
    label: "How to Replace a Beneficiary",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.REMOVE_BENEFICIARY,
    label: "How to Remove a Beneficiary",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.ADD_BENEFICIARY,
    label: "How to Add a Beneficiary",
    product: Product.TRUST,
  },
  {
    instruction: Instruction.GSA_BOND,
    label: "GSA Bond Instruction",
    product: Product.GSA,
  },
];

export const SHEPHERD_STEP_OPTIONS = {
  classes: "shepherd-theme-arrows tutorial-video-shepherd",
  scrollTo: true,
  cancelIcon: {
    enabled: true,
  },
};

export const SHEPHERD_BUILT_IN_BTN = {
  cancel: {
    classes: "cancel-button",
    secondary: true,
    text: "Exit",
    type: "cancel",
  },
  next: {
    classes: "next-button",
    text: "Next",
    type: "next",
  },
  back: {
    classes: "back-button",
    secondary: true,
    text: "Back",
    type: "back",
  },
};
