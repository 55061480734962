import { MatDialog, MatDialogConfig } from "@angular/material/dialog";

import { TrustHuman } from "app/models";
import { ReplaceTrustEntityReasonDialogComponent } from "app/pages/trust/_components/dialog/replace-trust-entity-reason-dialog/replace-trust-entity-reason-dialog.component";

export const getDialogConfig = ({
  width,
  autoFocus = false,
  disableClose = true,
  hasBackdrop = true,
  position = {},
  data = {},
  panelClass = "",
  backdropClass = "",
}): MatDialogConfig => {
  const dialogConfig = new MatDialogConfig();
  Object.assign(dialogConfig, {
    width,
    autoFocus,
    disableClose,
    hasBackdrop,
    position: {
      ...position,
      top: "64px",
    },
    data,
    panelClass,
    backdropClass,
    closeOnNavigation: true,
  });

  return dialogConfig;
};

export const getTrustAmendReason = async (dialog: MatDialog, humanType: TrustHuman) => {
  const dialogConfig = getDialogConfig({ width: "400px", data: { humanType } });
  const dialogRef = dialog.open(ReplaceTrustEntityReasonDialogComponent, dialogConfig);
  const { reason } = await dialogRef.afterClosed().toPromise();

  return reason;
};
