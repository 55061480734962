export const TAX_FIELDS = {
  payOffAmount: "amountDue",
  irsAccountNum: "irsAccount",
  irsTechDeptCertifiedMailNum: "irsTechCertifiedMailNumber",
  // treasuryDeptCertifiedMailNum: "treasuryDeptCertifiedMailNumber",
  treasurySecretaryCertifiedMailNum: "treasurySectaryCertifiedMailNumber",
  irsPubCertifiedMailNum: "irsPublicCertifiedMailNumber",
  irsPriRegisteredMailNum: "irsPrivateRegisteredMailNumber",
  // treasuryRegisteredMailNum: "treasuryRegisteredMailNumber",
  irsPubAddr: "irsPublicAddress",
  irsPubCity: "irsPublicCity",
  irsPubState: "irsPublicState",
  irsPubZipCode: "irsPublicZip",
  irsPubDepartName: "irsPuName",
  irsPriAddr: "irsPrivateAddress",
  irsPriCity: "irsPrivateCity",
  irsPriState: "irsPrivateState",
  irsPriZipCode: "irsPrivateZip",
  irsPriDepartName: "irsPName",

  ttbExciseTax: "ttbExciseTax",
  es1040CertMailNum: "es1040CertMailNum",
  v1040CertMailNum: "v1040CertMailNum",
  pibRegMailNum: "pibRegMailNum",
  boeRegMailNum: "boeRegMailNum",
  datePacketMailed1: "datePacketMailed1",
  datePacketMailed2: "datePacketMailed2",
  datePacketMailed3: "datePacketMailed3",
  datePacketMailed4: "datePacketMailed4",
  datePacketMailed5: "datePacketMailed5",
  datePacketMailed6: "datePacketMailed6",
  datePacketMailed7: "datePacketMailed7",
  datePacketMailed8: "datePacketMailed8",
  datePacketMailed9: "datePacketMailed9",
};

export const TAX_FIELDS_2 = {
  mailingDate2: "mailingDate2",
  date1096: "date1096",
  refNum1096: "refNum1096",
};

export function taxFormValueTransformer(formValue) {
  return new Proxy(formValue, {
    // eslint-disable-next-line object-shorthand
    get: function (target, propertyKey) {
      let targetValue: any;
      const targeKey = Object.entries(TAX_FIELDS).find(([key, _ignore]) => key === propertyKey);
      // eslint-disable-next-line no-param-reassign
      propertyKey = targeKey ? targeKey[1] : propertyKey;

      //  when target[propertyKey] === null, then String(target[propertyKey]) = 'null'
      if (propertyKey === "amountDue") {
        targetValue = String(target[propertyKey] || 0);
      } else {
        targetValue = target[propertyKey];
      }

      return targetValue;
    },
    // eslint-disable-next-line object-shorthand
    set: function (target, propertyKey, value): boolean {
      const targetKey = Object.entries(TAX_FIELDS).find(([key, _ignore]) => key === propertyKey);
      // eslint-disable-next-line no-param-reassign
      propertyKey = targetKey ? targetKey[1] : propertyKey;
      // eslint-disable-next-line no-param-reassign
      target[propertyKey] = value;
      return true;
    },
  });
}
