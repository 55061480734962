import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "navbarTitle",
})
export class NavbarTitlePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    if (value.toLowerCase().includes("dashboard")) {
      return "Dashboard";
    }

    if (value.toLowerCase().includes("profile")) {
      return "SPC Profile";
    }

    if (value.toLowerCase().includes("trust")) {
      if (value.toLowerCase().includes("create")) {
        return "Trust Formation";
      }

      if (value.toLowerCase().includes("edit")) {
        return "Trust Update";
      }

      return "Trust";
    }

    if (value.toLowerCase().includes("amend")) {
      return "SPC Addendum";
    }

    if (value.toLowerCase().includes("nda")) {
      return "Non-Disclosure Agreement";
    }

    if (value.toLowerCase().includes("irvic")) {
      return "Test";
    }

    if (value.toLowerCase().includes("services")) {
      let title = "Services";
      const paths = value.split("/");

      if (paths[2]?.toLowerCase().includes("master")) {
        title = `${title} / Master Bond`;
        return title;
      }

      if (paths[2]?.toLowerCase().includes("tax")) {
        title = `${title} / Tax Settlement`;
        return title;
      }

      if (paths[2]?.toLowerCase().includes("gsa")) {
        title = `${title} / GSA Bond`;
        return title;
      }

      if (paths[2]?.toLowerCase().includes("easy")) {
        title = `${title} / Easy Lien`;
        return title;
      }
    }
  }
}
