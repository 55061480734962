import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router } from "@angular/router";
import { LocalStorageKey } from "app/models";

import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { PersistenceService, StateService } from "../services";

@Injectable({
  providedIn: "root",
})
export class UserStatusGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _store: StateService,
    private readonly _persistenceService: PersistenceService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const permittedUserStatusArr = next.data.userStatusArr;

    return this._store.user.pipe(
      map((user: any) => {
        // const userStatus = user?.status?.status;
        const userStatus =
          user?.status?.status ||
          this._persistenceService.getItem(LocalStorageKey.USER_STATUS)?.status;

        if (userStatus && permittedUserStatusArr.includes(userStatus)) return true;

        this._router.navigateByUrl("/dashboard");
        return false;
      }),
    );
  }
}
