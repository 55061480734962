import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class StateService {
  private _config$: BehaviorSubject<any> = new BehaviorSubject({});
  config = this._config$.asObservable().pipe(distinctUntilChanged());

  private _windowWidth$: BehaviorSubject<number> = new BehaviorSubject(0);
  windowWidth = this._windowWidth$.asObservable().pipe(distinctUntilChanged());

  private _windowHeight$: BehaviorSubject<number> = new BehaviorSubject(0);
  windowHeight = this._windowHeight$.asObservable().pipe(distinctUntilChanged());

  private _countries$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  countries = this._countries$.asObservable().pipe(distinctUntilChanged());

  private _dialCodes$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  dialCodes = this._dialCodes$.asObservable().pipe(distinctUntilChanged());

  private _fullStates$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  fullStates = this._fullStates$.asObservable().pipe(distinctUntilChanged());

  private _states$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  states = this._states$.asObservable().pipe(distinctUntilChanged());

  private _currencies$: BehaviorSubject<any[]> = new BehaviorSubject([]);
  currencies = this._currencies$.asObservable().pipe(distinctUntilChanged());

  private _defaultGrantor$: BehaviorSubject<any> = new BehaviorSubject({});
  defaultGrantor = this._defaultGrantor$.asObservable().pipe(distinctUntilChanged());

  private _defaultProtector$: BehaviorSubject<any> = new BehaviorSubject({});
  defaultProtector = this._defaultProtector$.asObservable().pipe(distinctUntilChanged());

  private _user$: BehaviorSubject<any> = new BehaviorSubject({});
  user = this._user$.asObservable().pipe(distinctUntilChanged());

  private _citizenStatus$: BehaviorSubject<any> = new BehaviorSubject({});
  citizenStatus = this._citizenStatus$.asObservable().pipe(distinctUntilChanged());

  setConfig(config: any) {
    this._config$.next(config);
  }

  setWindowWidth(width: number) {
    this._windowWidth$.next(width);
  }

  setWindowHeight(height: number) {
    this._windowHeight$.next(height);
  }

  setCountries(data: any[]) {
    this._countries$.next(data);
  }

  setDialCodes(data: any[]) {
    this._dialCodes$.next(data);
  }

  setFullStates(data: any[]) {
    this._fullStates$.next(data);
  }

  setStates(data: any[]) {
    this._states$.next(data);
  }

  setCurrencies(data: any[]) {
    this._currencies$.next(data);
  }

  setDefaultGrantor(grantor) {
    this._defaultGrantor$.next(grantor);
  }

  setDefaultProtector(protector) {
    this._defaultProtector$.next(protector);
  }

  setUser(user) {
    this._user$.next(user);
  }

  setCitizenStatus(status) {
    this._citizenStatus$.next(status);
  }
}
