/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[click.prevent]",
})
export class PreventDefaultDirective implements OnInit, OnDestroy {
  unSubscribe: any;

  @Output("click.prevent") preventDefault = new EventEmitter();

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this.setVariables();
  }

  setVariables(): void {
    this.preventDefault = new EventEmitter();
  }

  ngOnInit(): void {
    this.unSubscribe = this._renderer.listen(this._elRef.nativeElement, "click", (ev) => {
      ev.preventDefault();
      this.preventDefault.emit(ev);
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }
}
