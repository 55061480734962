import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "ordinalSuffix",
})
export class OrdinalSuffixPipe implements PipeTransform {
  transform(value: number, ...args: any[]): any {
    const mod1 = value % 10;
    const mod2 = value % 100;

    if (mod1 === 1 && mod2 !== 11) {
      return `${value}st`;
    }

    if (mod1 === 2 && mod2 !== 12) {
      return `${value}nd`;
    }

    if (mod1 === 3 && mod2 !== 13) {
      return `${value}rd`;
    }

    return `${value}th`;
  }
}
