import { Injectable } from "@angular/core";

import { Subject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class EventsService {
  savePartialAddrEvent$ = new Subject();
  savePartialAddrEvent = this.savePartialAddrEvent$.asObservable();

  savePartialExtProfileEvent$ = new Subject();
  savePartialExtProfileEvent = this.savePartialExtProfileEvent$.asObservable();

  isShowLoadingSpinner$: Subject<boolean> = new Subject();
  isShowLoadingSpinner = this.isShowLoadingSpinner$.asObservable().pipe(distinctUntilChanged());

  savePartialAddr(): void {
    this.savePartialAddrEvent$.next(null);
  }

  savePartialExtProfile(): void {
    this.savePartialExtProfileEvent$.next(null);
  }

  showLoadingSpinner(): void {
    this.isShowLoadingSpinner$.next(true);
  }

  hideLoadingSpinner(): void {
    this.isShowLoadingSpinner$.next(false);
  }
}
