import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

@Directive({
  selector: "[ulawServiceStatus]",
})
export class ServiceStatusDirective implements OnChanges {
  @Input("ulawServiceStatus") menuItem: any;
  @Input() user: any;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges(): void {
    if (this.menuItem && this.user) {
      this.setVisibility(this.menuItem, this.user);
    }
  }

  setVisibility(menuItem: any, user: any): void {
    const {
      gsaSensitive,
      easyLienSensitive,
      nncSensitive,
      masterBondSensitive,
      taxSensitive,
    } = menuItem;

    const { status } = user;
    const isGsaEnabled = Boolean(status?.showGsa);
    const isEasyLienEnabled = Boolean(status?.showElien);
    const isNncEnabled = Boolean(status?.showNnc);
    const isTaxEnabled = Boolean(status?.showTax);
    const isMasterBondEnabled = Boolean(status?.showMasterBond);

    let style = "block";

    if (gsaSensitive) {
      style = isGsaEnabled ? "block" : "none";
    }

    if (easyLienSensitive) {
      style = isEasyLienEnabled ? "block" : "none";
    }

    if (nncSensitive) {
      style = isNncEnabled ? "block" : "none";
    }

    if (masterBondSensitive) {
      style = isMasterBondEnabled ? "block" : "none";
    }

    if (taxSensitive) {
      style = isTaxEnabled ? "block" : "none";
    }


    this._renderer.setStyle(this._elRef.nativeElement, "display", style);
  }
}
