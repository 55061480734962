import { Directive, ElementRef, Input, Renderer2 } from "@angular/core";

@Directive({
  selector: "[appNavBarHelpIcon]",
})
export class NavBarHelpIconDirective {
  url: any;

  @Input("appNavBarHelpIcon")
  set setURL(url: any) {
    this.url = url;
    this.url && this.setVisibility(this.url);
  }

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this._renderer.setStyle(this._elRef.nativeElement, "display", "none");
  }

  setVisibility(url: any): void {
    const urlSegments = url.split("/");
    const isVisible = urlSegments.pop().toLowerCase() === "trust";
    const property = isVisible ? "block" : "none";
    this._renderer.setStyle(this._elRef.nativeElement, "display", property);
  }
}
