/**
 * Enum of Easy Lien Product status in ULaw
 *
 * @enum {string} Easy Lien Product status
 */
export enum EasyLienProductStatus {
  NONE = "none",
  EXEMPTION_REVIEW = "Doc 1 review",
  EXEMPTION_REJECT = "Doc 1 reject",
  EXEMPTION_READY = "Doc 1 ready",
  EXEMPTION_DELIVERED = "Doc 1 delivered",
  DEFAULT_READY = "Doc 2 ready",
  DEFAULT_DELIVERED = "Doc 2 delivered",
  LIEN_UCC = "Doc 3 UCC",
  LIEN_ACCEPTED = "Accepted",
  LIEN_READY = "Doc 3 ready",
  COMPLETED = "Completed",
}

export enum EasyLienProductName {
  NOTICE_EXEMPTION = "noExemption",
  NOTICE_DEFAULT = "noDefault",
  NOTICE_LIEN = "noLien",
}

export enum EasyLienV2ProductStatus {
  NONE = "none",
  DOC1_REVIEW = "Data review",
  DATA_REJECT = "Data reject",
  DOC1_READY = "Doc 1 ready",
  DOC1_SIGN_REVIEW = "Doc 1 signature review",
  DOC1_TRACKING = "Doc 1 tracking",
  DOC1_DELIVERED = "Doc 1 delivered",
  DOC2_READY = "Doc 2 ready",
  DOC2_SIGN_REVIEW = "Doc 2 signature review",
  DOC2_TRACKING = "Doc 2 tracking",
  DOC2_DELIVERED = "Doc 2 delivered",
  ELIEN_TO_UCC1 = "Elien to UCC1",
  ELIEN_ACCEPTED = "Elien accepted",
  DOC3_READY = "Doc 3 ready",
  DOC3_SIGN_REVIEW = "Doc 3 signature review",
  FIRST_PACKET = "First packet",
  DOC3_TRACKING = "Doc 3 tracking",
  DOC3_DELIVERED = "Doc 3 delivered",
  COMPLETED = "Completed",
}

export enum EasyLienV2DocNames {
  DOC1_NAME = "noticeOfCopyrightNotice",
  MAIL_RECEIPT_1 = "copyrightNoticeCertifiedMailReceipt",
  MAIL_TRACKING_1 = "copyrightNoticeTrackingDelivered",
  DOC2_NAME = "noticeOfDefault",
  MAIL_RECEIPT_2 = "noticeOfDefaultCertifiedMailReceipt",
  MAIL_TRACKING_2 = "noticeOfDefaultTrackingDelivered",
  DOC3_NAME = "noticeOfLien",
  MAIL_RECEIPT_3 = "noticeOfLienCertifiedMailReceipt",
  MAIL_TRACKING_3 = "noticeOfLienTrackingDelivered",
  FINAL_PACKET = "final packet",
}
