/* eslint-disable @angular-eslint/directive-selector */
import {
  Directive,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
} from "@angular/core";

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: "[click.stop]",
})
export class StopPropagationDirective implements OnInit, OnDestroy {
  unSubscribe: any;

  @Output("click.stop") stopPropEvent = new EventEmitter();

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this.setVariables();
  }

  setVariables(): void {
    this.stopPropEvent = new EventEmitter();
  }

  ngOnInit(): void {
    this.unSubscribe = this._renderer.listen(this._elRef.nativeElement, "click", (ev) => {
      ev.stopPropagation();
      this.stopPropEvent.emit(ev);
    });
  }

  ngOnDestroy(): void {
    this.unSubscribe();
  }
}
