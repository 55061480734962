import * as _ from "lodash";
import { parseISO, compareDesc } from "date-fns";

export const isMobile = {
  Android: () => navigator.userAgent.match(/Android/i),
  BlackBerry: () => navigator.userAgent.match(/BlackBerry/i),
  iOS: () => navigator.userAgent.match(/iPhone|iPad|iPod/i),
  Opera: () => navigator.userAgent.match(/Opera Mini/i),
  Windows: () => navigator.userAgent.match(/IEMobile/i),
  any: () => {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

/**
 * @returns get width of browser window
 */
export const getWidth = () => {
  return Math.max(
    document.body.scrollWidth,
    document.documentElement.scrollWidth,
    document.body.offsetWidth,
    document.documentElement.offsetWidth,
    document.documentElement.clientWidth,
  );
};

/**
 * @returns get height of browser window
 */
export const getHeight = () => {
  return Math.max(
    document.body.scrollHeight,
    document.documentElement.scrollHeight,
    document.body.offsetHeight,
    document.documentElement.offsetHeight,
    document.documentElement.clientHeight,
  );
};

export const pickValue = (obj, keys) => {
  if (_.isArray(keys)) {
    return _.pick(obj, keys);
  }

  if (_.isObject(keys)) {
    const result = {};
    Object.entries(keys).forEach(([k, v]) => {
      _.set(result, v, _.get(obj, k));
    });
    return result;
  }
};

/**
 * @param obj *raw object*
 * @param removingKeys *keys that are being removed from object*
 * @returns object after removing some keys
 */
export const removeKeys = (obj, ...removingKeys): any => {
  const pickedKeys = Object.keys(obj).filter((key) => !removingKeys.includes(key));
  return _.pick(obj, pickedKeys);
};

/**
 * Return high order function that compares two objects by date
 * @param key *date property key in object*
 * @returns (date1, date2) => boolean
 */
export const sortByDate = (key: string) => (el1, el2) =>
  compareDesc(parseISO(_.get(el1, key)), parseISO(_.get(el2, key)));
