/**
 * Enum of the status of action
 *
 * @enum {string} Status
 */
export enum Status {
  SUCCESS = "success",
  FAILURE = "failure",
  UNKNOWN = "unknown",
}

/**
 * Enum of the status of session
 *
 * @enum {string} SessionStatus
 */
export enum SessionStatus {
  ACTIVE = "active",
  IDLE = "idle",
}

/**
 * Enum of Default humans in ULaw
 *
 * @enum {string} Human Email
 */
export enum Human {
  GRANTOR = "grantor@meta1.io",
  PROTECTOR = "protector@meta1.io",
  TRUSTEE = "trustee@meta1.io",
}

export enum Country {
  US = "United States of America",
}

export enum LocalStorageKey {
  AUTH_TOKEN = "ulaw_client:auth_token",
  EVER_LOGIN = "ulaw_client:auth_everLoggedIn",
  USER_EMAIL = "ulaw_client:auth_userEmail",
  USER_PASSWORD = "ulaw_client:auth_userPassword",
  REDIRECT_URL = "ulaw_client:user:redirectUrl",
  SPC_DOWNLOAD = "ulaw_client:profile_spcDownloaded",
  SPC_PARTIAL_ADDR = "ulaw_client:profile_partialAddress",
  SPC_PARTIAL_EXT_PROFILE = "ulaw_client:profile_partialExtendedProfile",
  SPC_CONSENT_DOWNLOAD = "ulaw_client:profile_spcConsentDownloaded",
  SPC_AMEND_DOWNLOAD = "ulaw_client:profile_spcAmendDownloaded",
  SPC_TREASURY_PACKET_DOWNLOAD = "ulaw_client:profile_spcTreasuryPacketDownloaded",
  SPC_AMEND_TRACKING_NUMBER = "ulaw_client:profile_spcAmendTrackingNumber",
  TRUST_CREATION_STATUS = "ulaw_client:trust_creationStatus",
  TRUST_DOWNLOAD = "ulaw_client:trust_downloaded",
  TRUST_TRACKING_NUMBER = "ulaw_client:trust_trackingNumber",
  TRUST_CHANGED_DOC_DOWNLOAD = "ulaw_client:trust_changedDocDownload",
  MASTER_BOND_TRACKING_NUMBER = "ulaw_client:services_masterBondTrackingNumber",
  PARTIAL_TAX = "ulaw_client:services_partialTax",
  USER_STATUS = "ulaw_client:user_profile_status",
}

export enum LocalStorageValue {
  TRUE = "true",
  FALSE = "false",
}

export enum DocumentDownloadStatusLocalStorageKey {
  SPC_PACKET = "ulaw-client:documents:spc_packet",
  SPC_CONSENT_PACKET = "ulaw-client:documents:spc_consent_packet",
  SPC_AMEND_PACKET = "ulaw-client:documents:spc_amend_packet",
}

export enum DocumentDownloadStatusLocalStorageValue {
  DOWNLOADED = "downloaded",
  NOT_DOWNLOADED = "not_downloaded",
}

/**
 * Enum of Pdf type in ULaw
 *
 * @enum {string} Pdf Type
 */
export enum PdfType {
  SPC = "spc",
  TRUST = "trust",
  AUTHENTICATION = "authentication",
  INSTRUCTION = "instruction",
  NNC = "nnc",
  NNC_PACKET = "nnc packet",
  INSTRUCTION_PDF = "InstructionsPDF",
  EASY_LIEN_V2 = "easy lien",
  GSA_V2 = "gsa bond",
  TAX_V2 = "tax2v",
  NDA = "nda",
  IRVIC = "irvic",
}

/**
 * Enum of Products in ULaw process
 */
export enum Product {
  SPC = "spc",
  TRUST = "trust",
  TAX = "tax",
  GSA = "gsa",
}

/**
 * Enum of Instructions in ULaw
 *
 * @enum {string} Instruction
 */
export enum Instruction {
  SPC_EXECUTION = "SPCExecution",
  TREASURY_PACKET_MAILING = "treasuryPacketMailing",
  TREASURY_PACKET_MAILING_USPR = "treasuryPacketMailingUSPR",
  AUTHENTICATION = "authentificationInstructions",
  TAX_SETTLEMENT = "taxSettlement",
  TRUST_QUICK_START_GUIDE = "trustQuickStartGuide",
  TRUST_EXECUTION = "trustExecution",
  REPLACE_PROTECTOR = "ReplacingProtector",
  REPLACE_TRUSTEE = "ReplacingTrustee",
  ADD_BENEFICIARY = "AddingBeneficiary",
  REPLACE_BENEFICIARY = "ReplacingBeneficiary",
  REMOVE_BENEFICIARY = "RemovingBeneficiary",
  MASTER_BOND = "masterBond",
  GSA_BOND = "gsaBondInstructions",
  EASY_LIEN = "easyLien",
  TAX_V2 = "tax2vInstructions",
}

export enum NavItemType {
  Sidebar = 1, // Only ever shown on sidebar
  NavbarLeft = 2, // Left-aligned icon-only link on navbar in desktop mode, shown above sidebar items on collapsed sidebar in mobile mode
  NavbarRight = 3, // Right-aligned link on navbar in desktop mode, shown above sidebar items on collapsed sidebar in mobile mode
}
