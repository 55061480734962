import { Pipe, PipeTransform } from "@angular/core";

import { TOOL_TIP } from "app/models/constants";

@Pipe({
  name: "tooltip",
})
export class TooltipPipe implements PipeTransform {
  transform(value: string): any {
    const keys = value.trim().split(".");
    const keysLength = keys.length;

    let i = 0;
    let tooltip = TOOL_TIP;
    while (i < keysLength) {
      tooltip = tooltip[keys[i]];
      // eslint-disable-next-line no-plusplus
      i++;
    }

    return tooltip || "";
  }
}
