import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dynClass",
})
/**
 *
 *
 * @export
 * @class DynClassPipe
 * @implements {PipeTransform}
 * @returns {string}
 * @description It returns class dynamically according to condition
 *
 *
 */
export class DynClassPipe implements PipeTransform {
  transform(value: any, ...args: any[]): string {
    const [targetValue, class1, class2] = args[0];
    return value && value === targetValue ? class1 : class2;
  }
}
