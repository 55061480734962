import { Directive, ElementRef, Renderer2, HostListener, Input } from "@angular/core";

@Directive({
  selector: "[appHoverHighlight]",
})
export class HoverHighlightDirective {
  color: string;

  @Input("appHoverHighlight")
  set setHighlightColor(color: string) {
    this.color = color;
  }

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this.color = "black";
    this.setCursor();
  }

  setCursor(): void {
    this._renderer.setStyle(this._elRef.nativeElement, "cursor", "pointer");
  }

  setColor(color: string): void {
    this._renderer.setStyle(this._elRef.nativeElement, "color", color);
  }

  @HostListener("mouseenter") onMouseEnter() {
    this.setColor(this.color);
  }

  @HostListener("mouseleave") onMouseLeave() {
    this.setColor("black");
  }
}
