import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "socialSecurityNumMask",
})
export class SocialSecurityNumMaskPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const curSsn = (value ?? "").trim().replace(/-/g, "");
    const ssnArr = curSsn.split("");
    if (ssnArr.length > 3) {
      ssnArr.splice(3, 0, "-");
    }

    if (ssnArr.length > 6) {
      ssnArr.splice(6, 0, "-");
    }

    return ssnArr.join("");
  }
}
