import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { IConfirmDialogData, Status } from "app/models";

/**
 * Confirm user to allow actions which is being taken place.
 * If accepted, then return success, else return failure
 *
 * @class ConfirmDialogComponent
 * @return {Status} status
 */
@Component({
  selector: "app-confirm-dialog",
  templateUrl: "./confirm-dialog.component.html",
  styleUrls: ["./confirm-dialog.component.scss"],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmDialogData,
  ) {}

  onAccept(): void {
    this.dialogRef.close(Status.SUCCESS);
  }

  onDecline(): void {
    this.dialogRef.close(Status.FAILURE);
  }
}
