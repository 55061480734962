import { Instruction, TaxProductStatus } from "../enums";

export const TAX_REVIEW_PRODUCT_STATUS_LIST = [
  // TaxProductStatus.NONE,
  // TaxProductStatus.REVIEW,
  TaxProductStatus.REJECTED,
];

export const TAX_READY_PRODUCT_STATUS_LIST = [
  TaxProductStatus.COMPLETED,
  TaxProductStatus.DELIVERED,
];

export const TAX_PROCESS_STEPS = [
  {
    statusList: [TaxProductStatus.NONE, TaxProductStatus.REVIEW, TaxProductStatus.REJECTED],
    step: {
      index: 1,
      percent: 33,
      text: "Prepare",
      tooltip:
        "After receiving your Statement from IRS enter the required information in the Tax Settlement Dashboard.",
    },
  },
  {
    statusList: [TaxProductStatus.UCC, TaxProductStatus.ACCEPTED, TaxProductStatus.DOWNLOADED],
    step: {
      index: 2,
      percent: 66,
      text: "Download",
      tooltip: "Download, prep and mail Tax Settlement Package.",
      instruction: Instruction.TAX_SETTLEMENT,
    },
  },
  {
    statusList: [TaxProductStatus.DELIVERED],
    step: {
      index: 3,
      percent: 100,
      text: "Delivered",
      tooltip: "All your mailed packages have reached their destinations and are being processed.",
    },
  },
];
