import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit, Self } from "@angular/core";
import { NgControl } from "@angular/forms";

import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeUntil, tap } from "rxjs/operators";

import { NO_CB, LONG_DELAY_IN_MILLISECONDS } from "@models/constants";

import { CountryMaskPipe } from "@shared/pipes";

@Directive({
  selector: "[ulawCountryValidate]",
  providers: [CountryMaskPipe],
})
export class CountryValidateDirective implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  @Input() countries: any[];

  constructor(
    @Self() private _ngCtrl: NgControl,
    private _cdRef: ChangeDetectorRef,
    private _countryMask: CountryMaskPipe,
  ) {
    this.setVariables();
  }

  ngOnInit(): void {
    this.initFormCtrlListener();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._stop$ = new Subject();
  }

  initFormCtrlListener(): void {
    this._ngCtrl.control.valueChanges
      .pipe(
        debounceTime(LONG_DELAY_IN_MILLISECONDS),
        distinctUntilChanged(),
        tap((country) => {
          const newValue = this._countryMask.transform(country, this.countries);
          this._ngCtrl.control.setValue(newValue);
        }),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }
}
