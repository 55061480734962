// eslint-disable-next-line max-classes-per-file
import { AbstractControl } from "@angular/forms";
import { ADDR_REG_EXP } from "app/models";

export class ConfirmPasswordValidator {
  // eslint-disable-next-line consistent-return
  static MatchPassword(control: AbstractControl) {
    const password = control.get("password").value;
    const confirmPassword = control.get("confirmPassword").value;
    if (password !== confirmPassword) {
      control.get("confirmPassword").setErrors({ ConfirmPassword: true });
    } else {
      return null;
    }
  }
}

export class EngCharacterValidator {
  static CheckCharacter(control: AbstractControl) {
    // eslint-disable-next-line consistent-return
    Object.keys(ADDR_REG_EXP).forEach((key) => {
      const { value } = control.get(key);
      if (!value) {
        return null;
      }

      if (!ADDR_REG_EXP[key].test(value.replace(/\s/g, ""))) {
        control.get(key).setErrors({ character: true });
      }
    });
  }
}
