<div class="video-viewer">
  <span class="close-btn" (click)="onClosePlayer()">
    <i class="material-icons">close</i>
  </span>
  <iframe
    [src]="safeSrc"
    [width]="iFrameWidth"
    [height]="iFrameHeight"
    frameborder="0"
    allow="autoplay; fullscreen"
    allowfullscreen
  ></iframe>
</div>
