import { ErrorHandler, Injectable, Injector } from "@angular/core";

import { util } from "app/util";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: "root",
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private _injector: Injector) {}

  handleError(err: any): void {
    // return if error is not due to client-error
    if (!err.error) {
      return;
    }

    if (environment.isProduction) {
      util.dangerMsg({ msgKey: "globalErr" });
    }

    // util.dangerMsg({ isOrigin: true, originMsg: err.error });
  }
}
