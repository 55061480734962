import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { SidebarComponent } from "./sidebar.component";

import { ServiceStatusDirective, UserStatusSidebarDirective } from "./_directives";

@NgModule({
  imports: [RouterModule, CommonModule],
  declarations: [SidebarComponent, UserStatusSidebarDirective, ServiceStatusDirective],
  exports: [SidebarComponent],
})
export class SidebarModule {}
