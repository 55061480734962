import { downloadFile } from "./decorators";

class FileUtil {
  @downloadFile()
  saveFile(blob: Blob, filename: string): void {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display:none;");
    const url = window.URL.createObjectURL(blob);
    const extension = blob.type.split("/")[1];
    a.href = url;
    a.download = `${filename}.${extension}`;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  @downloadFile()
  printPdf(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    window.open(url).print();
  }
}

export const fileUtil = new FileUtil();
