import { Component, Input } from "@angular/core";

declare let $: any;

@Component({
  selector: "app-scroll-top",
  templateUrl: "./scroll-top.component.html",
  styleUrls: ["./scroll-top.component.scss"],
})
export class ScrollTopComponent {
  @Input() isShowScrollTop: boolean;

  onScrollToTop(): void {
    $(".main-panel").animate(
      {
        scrollTop: 0,
      },
      800,
      () => {},
    );
  }
}
