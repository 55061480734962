import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "whiteSpaceMask",
})
export class WhiteSpaceMaskPipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const result = value.replace(/ /g, "");
    return result;
  }
}
