<h3 mat-dialog-title class="text-center text-capitalize">Select Reason</h3>
<div mat-dialog-content>
  <div class="row">
    <form class="col-12" [formGroup]="reasonForm">
      <div class="row">
        <mat-form-field class="col-12" appearance="outline">
          <mat-label> Choose Reason </mat-label>
          <mat-select formControlName="reason">
            <mat-option *ngFor="let reason of reasons" [value]="reason.value">
              {{ reason.label }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
<div mat-dialog-actions class="d-flex justify-content-end">
  <button
    type="button"
    class="btn btn-success text-capitalize"
    (click)="onSelectReason()"
  >
    Choose
  </button>
</div>
