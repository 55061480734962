import { Pipe, PipeTransform } from "@angular/core";

import { VIDEO } from "app/models/constants";

@Pipe({
  name: "video",
})
export class VideoPipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    const keys = value.trim().split(".");
    const keysLength = keys.length;

    let i = 0;
    let result = VIDEO;
    while (i < keysLength) {
      result = result[keys[i]];
      i++;
    }

    return result;
  }
}
