<div mat-dialog-content class="mt-3 mb-3 text-center">
  <span>{{ data.msg }}</span>
</div>
<div mat-dialog-actions class="d-flex justify-content-around">
  <button mat-raised-button class="btn btn-warning text-capitalize" (click)="onDecline()">
    {{ data.noBtnTxt }}
  </button>
  <button mat-raised-button class="btn btn-success text-capitalize" (click)="onAccept()">
    {{ data.yesBtnTxt }}
  </button>
</div>
