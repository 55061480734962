export const ADDR_VALIDATION_MSG = {
  userFirstName: [
    { type: "required", message: "First name is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userMiddleName: [{ type: "character", message: "Only English character is supported." }],
  userLastName: [
    { type: "required", message: "Last name is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userEMail: [
    { type: "required", message: "Email is required" },
    { type: "pattern", message: "Enter a valid email" },
  ],
  userAddressLine1: [
    { type: "required", message: "Address is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userCountry: [
    { type: "required", message: "Country is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userCity: [
    { type: "required", message: "City is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userState: [
    { type: "required", message: "State is required" },
    { type: "character", message: "Only English character is supported." },
  ],
  userPostalCode: [{ type: "required", message: "Postal code is required" }],
  userPhone: [
    { type: "required", message: "Phone number is required" },
    {
      type: "maxlength",
      message: "Phone number cannot be more than 15 characters long",
    },
    { type: "pattern", message: "Enter a valid phone number" },
  ],
  userCitizenStatus: [{ type: "required", message: "Citizen status is required" }],
};

export const EXT_PROFILE_VALIDATION_MSG = {
  bcFirstName: [{ type: "required", message: "Birth first name is required" }],
  bcLastName: [{ type: "required", message: "Birth last name is required" }],
  bcTitle: [{ type: "required", message: "Birth Certificate Title is required" }],
  bcCounty: [{ type: "required", message: "County of Birth is required" }],
  bcState: [{ type: "required", message: "Birth State is required" }],
  // bcFileNumber: [{ type: "required", message: "Birth Certificate Number is required" }],
  userCounty: [{ type: "required", message: "County of Residence is required" }],
  bcDOB: [{ type: "required", message: "Date of Birth is required" }],
  // bcGender: [{ type: "required", message: "Gender is required" }],
  sscSSN: [
    { type: "required", message: "Social Security Number is required" },
    { type: "pattern", message: "Enter a valid one" },
  ],
  spcNotaryState: [{ type: "required", message: "Notary Venue State is required" }],
  spcNotaryCounty: [{ type: "required", message: "Notary Venue County is required" }],
  // userEthnicity: [{ type: "required", message: "Race is required" }],
};

export const TRUST_BASIC_VALIDATION_MSG = {
  trustName: [{ type: "required", message: "Trust Name is required" }],
  trustNotaryCounty: [{ type: "required", message: "Trust County is required" }],
  trustNotaryState: [{ type: "required", message: "Trust State is required" }],
};

export const TRUST_SEARCH_VALIDATION_MSG = {
  email: [
    { type: "required", message: "Email is required" },
    { type: "email", message: "Enter a valid email" },
  ],
};

export const NCC_FORM_VALIDATION_MSG = {
  nccUserMailAddress: [{ type: "required", message: "Mailing Address is required" }],
  nccUserMailCity: [{ type: "required", message: "Mailing City is required" }],
  nccUserMailState: [{ type: "required", message: "Mailing State is required" }],
  nccUserMailZip: [{ type: "required", message: "Mailing Post Code is required" }],
  nccCaseType: [{ type: "required", message: "Civil Criminal or Family is required" }],
  nccCauseNum: [{ type: "required", message: "Cause Number is required" }],
  nccNotaryCounty: [{ type: "required", message: "Notary County is required" }],
  nccNotaryState: [{ type: "required", message: "Notary State is required" }],
  plaintiffPartName: [{ type: "required", message: "Plaintiff Name is required" }],
  plaintiffPartAddress: [{ type: "required", message: "Plaintiff Address is required" }],
  plaintiffPartCity: [{ type: "required", message: "Plaintiff City is required" }],
  plaintiffPartState: [{ type: "required", message: "Plaintiff State is required" }],
  plaintiffPartZip: [{ type: "required", message: "Plaintiff Postal Code is required" }],
  plaintiffPartCertMailNum: [
    { type: "required", message: "Plaintiff Certified Mail Number is required" },
  ],
  agentPartOrganization: [{ type: "required", message: "Agent Organization is required" }],
  agentPartName: [{ type: "required", message: "Agent Name is required" }],
  agentPartAddress: [{ type: "required", message: "Agent Address is required" }],
  agentPartCity: [{ type: "required", message: "Agent City is required" }],
  agentPartState: [{ type: "required", message: "Agent State is required" }],
  agentPartZip: [{ type: "required", message: "Agent Postal Code is required" }],
  agentPartCertMailNum: [{ type: "required", message: "Agent Certified Mail Number is required" }],
  judgePartName: [{ type: "required", message: "Judge Name is required" }],
  judgePartAddress: [{ type: "required", message: "Judge Address is required" }],
  judgePartCity: [{ type: "required", message: "Judge City is required" }],
  judgePartState: [{ type: "required", message: "Judge State is required" }],
  judgePartZip: [{ type: "required", message: "Judge Postal Code is required" }],
  judgePartCertMailNum: [{ type: "required", message: "Judge Certified Mail Number is required" }],
  judgePartCourtName: [{ type: "required", message: "Court Name is required" }],
  clerkPartName: [{ type: "required", message: "Clerk of Court Name is required" }],
  clerkPartAddress: [{ type: "required", message: "Clerk of Court Address is required" }],
  clerkPartCity: [{ type: "required", message: "Clerk of Court City is required" }],
  clerkPartState: [{ type: "required", message: "Clerk of Court State is required" }],
  clerkPartZip: [{ type: "required", message: "Clerk of Court Postal Code is required" }],
  clerkPartCertMailNum: [
    { type: "required", message: "Clerk of Court Certified Mail Number is required" },
  ],
  clerkPartCocOfficalCap: [
    { type: "required", message: "Clerk of Court Official Capacity is required" },
  ],
};
