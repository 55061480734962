import { Directive, ElementRef, Renderer2, Input, OnInit } from "@angular/core";

import { tap } from "rxjs/operators";

import {
  NO_CB,
  ADDRESS_UNLOCK_USER_STATUS_ARR,
  EXT_PROFILE_UNLOCK_USER_STATUS_ARR,
} from "@models/constants";
import { filterByNull } from "@util/operators";

import { StateService } from "@core/services";

@Directive({
  selector: "[appDisableBtn]",
})
export class DisableBtnDirective implements OnInit {
  user: any;
  btnCategory: string;

  @Input("appDisableBtn")
  set setBtnCategory(category: string) {
    if (!category) return;
    this.btnCategory = category;
    this.setDisable();
  }

  constructor(
    private _elRef: ElementRef,
    private _renderer: Renderer2,
    private _store: StateService,
  ) {}

  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this._store.user
      .pipe(
        filterByNull(),
        tap((user) => (this.user = user)),
        tap(() => this.setDisable()),
      )
      .subscribe(NO_CB);
  }

  setDisable(): void {
    const userStatus = this.user?.status?.status;

    if (!userStatus) return;

    let disable: boolean;
    switch (this.btnCategory) {
      case "address":
        disable = !ADDRESS_UNLOCK_USER_STATUS_ARR.includes(userStatus);
        break;
      case "extProfile":
        disable = !EXT_PROFILE_UNLOCK_USER_STATUS_ARR.includes(userStatus);
        break;
      default:
        disable = false;
        break;
    }

    this._renderer.setProperty(this._elRef.nativeElement, "disabled", disable);
  }
}
