import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { MaterialModule } from "../material/material.module";
import { DirectivesModule } from "../directives/directives.module";

import { NavbarComponent } from "./navbar.component";
import { NavbarTitlePipe, NavBarHelpIconDirective } from "./_core";

@NgModule({
  imports: [RouterModule, CommonModule, MaterialModule, DirectivesModule],
  declarations: [NavbarComponent, NavbarTitlePipe, NavBarHelpIconDirective],
  exports: [NavbarComponent],
})
export class NavbarModule {}
