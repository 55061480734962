import { Directive, ElementRef, Renderer2, Input, OnChanges } from "@angular/core";

import { UserCitizenStatus, UserStatus } from "@models/enums";

@Directive({
  selector: "[ulawUserStatusSidebar]",
})
export class UserStatusSidebarDirective implements OnChanges {
  @Input() user: any;
  @Input() userStatusArr: UserStatus[];
  @Input() userCitizenStatusArr: UserCitizenStatus[];
  @Input() showFieldName: string;

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {}

  ngOnChanges(): void {
    if (this.user && this.userStatusArr && this.userCitizenStatusArr)
      this.setVisibility(this.user, this.userStatusArr, this.userCitizenStatusArr);
  }

  setVisibility(
    user: any,
    userStatusArr: UserStatus[],
    userCitizenStatusArr: UserCitizenStatus[],
  ): void {
    const userStatus = user.status?.status ?? "";
    const userCitizenStatus = user?.userCitizenStatus ?? "";

    const isNewUser = [UserStatus.INVITED, UserStatus.NEW_USER].includes(userStatus);
    let hasUserStatus = false;
    let hasUserCitizenStatus = false;

    if (userStatusArr.length === 0) {
      hasUserStatus = true;
    } else {
      hasUserStatus = userStatusArr.includes(userStatus);
    }

    // when user status is "New User", user doesn't have citizenStatus
    if (isNewUser) {
      hasUserCitizenStatus = true;
    } else {
      hasUserCitizenStatus = userCitizenStatusArr.includes(userCitizenStatus);
    }

    let style;
    style = hasUserStatus && hasUserCitizenStatus ? "block" : "none";

    if (this.showFieldName) {
      switch (this.showFieldName) {
        case "showNda":
          style = hasUserStatus && hasUserCitizenStatus && user?.status?.showNda ? "block" : "none";
          break;
        case "showIrvic":
          style = hasUserStatus && hasUserCitizenStatus && user?.status?.showIrvic ? "block" : "none";
      }
    }
    this._renderer.setStyle(this._elRef.nativeElement, "display", style);
  }
}
