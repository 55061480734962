import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";

import {
  NEW_USER_STATUS_ARR,
  VERIFIED_USER_STATUS_ARR,
  SPC_AMEND_PDF_MANAGE_USER_STATUS_ARR,
} from "app/models/constants";
import { CITIZEN_STATUS_ARR, IUserCitizenStatus, UserCitizenStatus, UserStatus } from "app/models";

@Directive({
  selector: "[appUserStatus]",
})
export class UserStatusDirective implements OnChanges {
  entity: string;
  user: any;
  userStatus: UserStatus;
  userCitizenStatus: IUserCitizenStatus;
  setVisibilityFn: any;

  @Input("appUserStatus")
  set setEntity(entity: string) {
    this.entity = entity;
  }
  @Input("user")
  set setUser(user: any) {
    if (!user) {
      return;
    }

    const { status, userCitizenStatus: targetCitizenStatus } = user;
    this.user = user;
    this.userStatus = status?.status;
    this.userCitizenStatus = CITIZEN_STATUS_ARR.find(
      ({ userCitizenStatus }) => targetCitizenStatus === userCitizenStatus,
    );
  }

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    this.setVariables();
  }

  ngOnChanges(): void {
    if (this.entity && this.user) {
      this.setVisibilityFn[this.entity]({
        userStatus: this.userStatus,
        userCitizenStatus: this.userCitizenStatus,
      });
    }
  }

  setVariables(): void {
    this.setVisibilityFn = {
      spcPdfSection: this._setSpcPdfVisibility.bind(this),
      extProfileSection: this._setExpProfileVisibility.bind(this),
      spcAmendPdfSection: this._setSpcAmendPdfVisibility.bind(this),
      spcReportBtn: this._setSpcReportBtnVisibility.bind(this),
      refreshUserBtn: this._setRefreshBtnVisibility.bind(this),
    };
  }

  private _setRefreshBtnVisibility({ userStatus, userCitizenStatus }) {
    const isVisible = !NEW_USER_STATUS_ARR.includes(userStatus);
    const displayStyle = isVisible ? "block" : "none";

    this._renderer.setStyle(this._elRef.nativeElement, "display", displayStyle);
  }

  private _setExpProfileVisibility({ userStatus, userCitizenStatus = {} as IUserCitizenStatus }) {
    const { userCitizenStatus: citizenStatus } = userCitizenStatus;
    const isVisible =
      citizenStatus !== UserCitizenStatus.NA &&
      ![UserStatus.INVITED, UserStatus.NEW_USER].includes(userStatus);

    const displayStyle = isVisible ? "block" : "none";
    this._renderer.setStyle(this._elRef.nativeElement, "display", displayStyle);
  }

  private _setSpcPdfVisibility({ userStatus, userCitizenStatus = {} as IUserCitizenStatus }) {
    const { under18 = false } = this.user;
    const { SPCRequired = false } = userCitizenStatus;

    const isVisible = !NEW_USER_STATUS_ARR.includes(userStatus) && !under18 && SPCRequired;
    const displayStyle = isVisible ? "block" : "none";

    this._renderer.setStyle(this._elRef.nativeElement, "display", displayStyle);
  }

  private _setSpcReportBtnVisibility({ userStatus, userCitizenStatus }) {
    const isVisible = VERIFIED_USER_STATUS_ARR.includes(userStatus);
    const displayStyle = isVisible ? "block" : "none";

    this._renderer.setStyle(this._elRef.nativeElement, "display", displayStyle);
  }

  private _setSpcAmendPdfVisibility({ userStatus, userCitizenStatus }) {
    const isVisible = SPC_AMEND_PDF_MANAGE_USER_STATUS_ARR.includes(userStatus);
    const displayStyle = isVisible ? "block" : "none";

    this._renderer.setStyle(this._elRef.nativeElement, "display", displayStyle);
  }
}
