import {
  Component,
  OnInit,
  HostListener,
  OnDestroy,
  NgZone,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { MatSnackBar } from "@angular/material/snack-bar";

import { Subject, Observable, fromEvent, combineLatest, interval } from "rxjs";
import { filter, map, startWith, tap, takeUntil, finalize } from "rxjs/operators";

import isEmpty from "lodash/isEmpty";

import { IDLE_TIME, NO_CB } from "@models/constants";
import { UserStatus } from "@models/enums";

import { getSnackBarConfig, getTitle, util } from "app/util";

import {
  AuthService,
  StateService,
  EventsService,
  RouteStateService,
  SessionService,
  JwtService,
  UserService,
} from "@core/services";
import { SessionSnackBarComponent } from "@libs/session/session-snack-bar/session-snack-bar.component";

@Component({
  selector: "app-my-app",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;
  private _online$: Observable<any>;
  private _offline$: Observable<any>;
  private _onResize$: Observable<any>;

  userStatus: UserStatus;

  isSessionSnackBarOpened: boolean;

  constructor(
    private _ngZone: NgZone,
    private _cdRef: ChangeDetectorRef,
    private _router: Router,
    private _title: Title,
    private _snackBar: MatSnackBar,
    private _uLawJwt: JwtService,
    private _uLawAuth: AuthService,
    private _uLawState: StateService,
    private _uLawEvents: EventsService,
    private _uLawSession: SessionService,
    private _uLawRouteState: RouteStateService,
    private _uLawUserService: UserService,
  ) {
    this.setVariables();

    // set route change event globally
    // so that we can get the history of user navigation
    this._uLawRouteState.loadRouteState();
  }

  ngOnInit() {
    this.setPageTitle();
    this.getUserStatus();
    this._uLawAuth.populate();

    this._router.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        takeUntil(this._stop$),
      )
      .subscribe((ev: NavigationEnd) => {
        const body = document.getElementsByTagName("body")[0];
        const modalBackdrop = document.getElementsByClassName("modal-backdrop")[0];

        if (body.classList.contains("modal-open")) {
          body.classList.remove("modal-open");
          modalBackdrop.remove();
        }
      });

    this.initWindowsListener();
    this.initSessionListener();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  setVariables(): void {
    this._online$ = fromEvent(window, "online");
    this._offline$ = fromEvent(window, "offline");
    this._onResize$ = fromEvent(window, "resize");
    this._stop$ = new Subject();

    this.isSessionSnackBarOpened = false;
  }

  setPageTitle(): void {
    this._router.events
      .pipe(
        filter((ev) => ev instanceof NavigationEnd),
        map((ev: any) => getTitle(ev.url)),
        tap((title) => this._title.setTitle(title)),
      )
      .subscribe(NO_CB);
  }

  getUserStatus(): void {
    this._uLawState.user
      .pipe(
        filter((user) => !isEmpty(user)),
        tap((user: any) => (this.userStatus = user.status?.status)),
      )
      .subscribe(NO_CB);
  }

  initWindowsListener(): void {
    this._ngZone.runOutsideAngular(() => {
      const { width, height } = util.getWindowSize();

      combineLatest([
        this._online$.pipe(startWith(true), tap(this.onOnline.bind(this))),
        this._offline$.pipe(startWith(false), tap(this.onOffline.bind(this))),
        this._onResize$.pipe(
          startWith({ target: { innerWidth: width, innerHeight: height } }),
          map((ev) => ({
            width: ev.target.innerWidth,
            height: ev.target.innerHeight,
          })),
          tap(this.onWindowResize.bind(this)),
        ),
      ])
        .pipe(takeUntil(this._stop$))
        .subscribe(NO_CB);
    });
  }

  onOffline(ev: any): void {}

  onOnline(ev: any): void {}

  onWindowResize({ width, height }): void {
    this._uLawState.setWindowWidth(width);
    this._uLawState.setWindowHeight(height);
    this._cdRef.detectChanges();
  }

  initSessionListener(): void {
    this._ngZone.runOutsideAngular(() => {
      combineLatest([interval(1000), this._uLawSession.lastActiveTime])
        .pipe(tap(this.checkSessionStatus.bind(this)), takeUntil(this._stop$))
        .subscribe(NO_CB);
    });
  }

  continueSession(): void {
    this._uLawUserService
      .getUser()
      .pipe(finalize(() => (this.isSessionSnackBarOpened = false)))
      .subscribe(NO_CB);
  }

  exitSession(): void {
    this._uLawAuth.purgeAuth();
    this._router.navigate(["/login"]);
    this.isSessionSnackBarOpened = false;
  }

  async checkSessionStatus([_ignore, lastActiveTime]): Promise<void> {
    this._ngZone.run(async () => {
      const isUserLoggedIn = Boolean(this._uLawJwt.getToken());

      if (!isUserLoggedIn) {
        return;
      }

      const timeDiffInSeconds = Math.floor((Date.now() - lastActiveTime) / 1000);
      this._uLawSession.setSessionTime(timeDiffInSeconds);

      if (timeDiffInSeconds >= IDLE_TIME && !this.isSessionSnackBarOpened) {
        const sessionSnackBarConfig = getSnackBarConfig({});
        const sessionSnackBarRef = this._snackBar.openFromComponent(
          SessionSnackBarComponent,
          sessionSnackBarConfig,
        );
        this.isSessionSnackBarOpened = true;

        sessionSnackBarRef
          .afterDismissed()
          .pipe(
            tap(({ dismissedByAction }) => {
              if (dismissedByAction) {
                this.continueSession();
              } else {
                this.exitSession();
              }
            }),
            takeUntil(this._stop$),
          )
          .subscribe(NO_CB);
      }
    });
  }

  @HostListener("window:beforeunload", ["$event"])
  public onPageUnload($event: BeforeUnloadEvent) {
    // when user status is "New User", then save draft address
    if (this.userStatus === UserStatus.NEW_USER) {
      this._uLawEvents.savePartialAddr();
    }

    // when user status is "SPC Required", then only save extended profile
    // because user already saved address information in this status
    // so we can get user address from API
    if (this.userStatus === UserStatus.SPC_REQUIRED) {
      this._uLawEvents.savePartialExtProfile();
    }
  }
}
