import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";

import { Subject } from "rxjs";
import { tap, takeUntil } from "rxjs/operators";

import { NO_CB } from "@models/constants";
import { util } from "app/util";

import { StateService } from "@core/services";

@Component({
  selector: "app-video-player",
  templateUrl: "./video-player.component.html",
  styleUrls: ["./video-player.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VideoPlayerComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  safeSrc: SafeResourceUrl;
  iFrameWidth: number;
  iFrameHeight: number;

  @Input("url")
  set url(url: string) {
    if (url) {
      this.safeSrc = this._sanitizer.bypassSecurityTrustResourceUrl(url);
      this._cdRef.detectChanges();
    }
  }
  @Output() closePlayer: EventEmitter<any>;

  constructor(
    private _sanitizer: DomSanitizer,
    private _cdRef: ChangeDetectorRef,
    private _uLawState: StateService,
  ) {
    this._stop$ = new Subject();

    this.iFrameWidth = 800;
    this.iFrameHeight = 450;

    this.closePlayer = new EventEmitter();
  }

  ngOnInit(): void {
    this._uLawState.windowWidth
      .pipe(
        tap((width) => {
          if (util.isMobile.any()) {
            this.iFrameWidth = width - 20;
            this.iFrameHeight = (this.iFrameWidth * 9) / 16;
          }
        }),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  onClosePlayer(): void {
    this.closePlayer.emit();
  }
}
