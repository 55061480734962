import { IrvicProductStatus } from "../enums";

export const IRVIC_FIELDS = [
  "loopTerms",
  "ccBizLoop",
  "presentmentTitle",
  "receiveDate",
  "presentmentName",
  "acceptDate",
  "bizName",
  "bizAddress",
  "bizCity",
  "bizState",
  "bizZip",
  "bizRegMailNum1",
  "bizRegMailNum1Ddate",
  "bizRegMailNum2",
  "bizRegMailNum2Ddate",
  "bizRegMailNum3",
  "bizRegMailNum3Ddate",
  "bizRegMailNum4",
  "bizRegMailNum4Ddate",
  "bizRegMailNum5",
  "bizRegMailNum5Ddate",
];

export const IRVIC_REVIEW_STATUS_LIST = [IrvicProductStatus.DATA_REJECTED];
