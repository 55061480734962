import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs";

import { JwtService } from "../services";

@Injectable({
  providedIn: "root",
})
export class HttpTokenInterceptor implements HttpInterceptor {
  constructor(private _uLawJwt: JwtService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._uLawJwt.getToken();

    if (token) {
      const request = req.clone({ headers: req.headers.set("authorization", token) });
      return next.handle(request);
    }

    return next.handle(req);
  }
}
