/**
 * Enum of Tax Product Status
 *
 * @enum {string} Tax Product Status
 *
 */
export enum TaxProductStatus {
  NONE = "none",
  REVIEW = "First",
  REJECTED = "Rejected",
  UCC = "UCC",
  DOWNLOADED = "Downloaded",
  DOC_1_READY = "Doc 1 ready",
  DOC_1_DOWNLOADED = "Doc 1 downloaded",
  DOC_1_SIGNATURE_REVIEW = "Doc 1 signature review",
  INPUT_DATA_2 = "Input data 2",
  DATA_2_REVIEW = "Data 2 review",
  UCC_2 = "UCC 2",
  DOC_2_READY = "Doc 2 ready",
  DOC_2_DOWNLOADED = "Doc 2 downloaded",
  DOC_2_SIGNATURE_REVIEW = "Doc 2 signature review",
  DOC_3_Ready = "Doc 3 ready",
  DOC_3_DOWNLOADED = "Doc 3 downloaded",
  ACCEPTED = "Accepted",
  ACCEPTED_2 = "Accepted 2",
  DELIVERED = "Delivered",
  COMPLETED = "Completed",
}

export enum TaxDocName {
  DOC_1 = "doc1",
  DOC_2 = "doc2",
  DOC_3 = "doc3",
  EXECUTED_TAX_PACKET = "executedTaxPacket",
  IRS_TECHNICAL_SUPPORT_DIVISION = "irsTechnicalSupportDivision",
  TTB_EXCISE_TAX = "ttbExciseTax",
  SECRETARY_OF_TRE = "secretaryOfTheTreasury",
  IRS_P_NAME = "irsPName",
  IRS_PU_NAME = "irsPuNAme",
  IRS_TECHNICAL_SUPPORT_DIVISION_TRC = "irsTechnicalSupportDivisionTracking",
  TTB_EXCISE_TAX_TRC = "ttbExciseTaxTracking",
  SECRETARY_OF_TRE_TRC = "secretaryOfTheTreasuryTracking",
  IRS_PU_NAME_TRC = "irsPuNAmeTracking",
  IRS_P_NAME_TRC = "irsPNameTracking",
  IRS_TECHNICAL_SUPPORT_DIVISION_REC = "irsTechnicalSupportDivisionMailReceipt",
  TTB_EXCISE_TAX_REC = "ttbExciseTaxMailReceipt",
  SECRETARY_OF_TRE_REC = "secretaryOfTheTreasuryMailReceipt",
  IRS_P_NAME_REC = "irsPNameMailReceipt",
  IRS_PU_NAME_REC = "irsPuNAmeMailReceipt",
  CERT_OF_MAILING_IRS_TECH = "certOfMailings_irsTechnicalSupportDivision",
  CERT_OF_MAILING_TTB = "certOfMailings_ttbExciseTax",
  CERT_OF_MAILING_SEC = "certOfMailings_secretaryOfTheTreasury",
  CERT_OF_MAILING_IRS_PU = "certOfMailings_irsPuNAme",
  CERT_OF_MAILING_IRS_P = "certOfMailings_irsPName",
  BOE_TREASURY_COVER_LETTER = "boeTreasuryCoverLetterSigned",
  BIP_TREASURY_COVER_LETTER = "bipTreasuryCoverLetterSigned",
  BOE_SIGNED = "boeSigned",
  PIB_SIGNED = "pibSigned",
  NEW_TAX2 = "newTax2",
  TAX_TRACKING_RECEIPTS = "taxTrackingReceipts",
  TAX_PROD_RECEIPTS = "taxPodTracking",
}

/**
 * Enum of Tax Product Element
 *
 * @enum {string} Tax Product Element
 *
 */
export enum TaxProductElement {
  NONE = "none",
  GET_TAX_BUTTON = "get_tax_btn",
  DOWNLOAD_TAX_BUTTON = "download_tax_btn",
}
