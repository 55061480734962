import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  AfterViewInit,
} from "@angular/core";

/**
 * HorizontalStepperComponent
 * @input steps *list of possible steps*
 * @input currentStep *current step item*
 * @output clickStep *click event handler of step DOM element*
 */
@Component({
  selector: "ulaw-horizontal-stepper",
  templateUrl: "./horizontal-stepper.component.html",
  styleUrls: ["./horizontal-stepper.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalStepperComponent implements AfterViewInit {
  @Input() steps: any[];
  @Input() currentStep: any;
  @Output() clickStep: EventEmitter<any>;
  @ViewChildren("step") stepDOMList: QueryList<any>;

  constructor(private _cdRef: ChangeDetectorRef) {
    this.setVariables();
  }

  ngAfterViewInit(): void {
    this.drawStepLinkBar();
  }

  setVariables(): void {
    this.steps = [];
    this.currentStep = {};
    this.clickStep = new EventEmitter();
  }

  getStepDOMOffset(stepDOM: any): any {
    const rect = stepDOM.getBoundingClientRect();
    return {
      left: rect.left + window.pageXOffset,
      top: rect.top + window.pageYOffset,
      width: rect.width || stepDOM.offsetWidth,
      height: rect.height || stepDOM.offsetHeight,
    };
  }

  drawLineBetweenStepDOM(dom1, dom2, index, color = "#616161", thickness = 2): void {
    const off1 = this.getStepDOMOffset(dom1);
    const off2 = this.getStepDOMOffset(dom2);

    const canvas = document.createElement("canvas");
    canvas.id = `step-link-bar-canvas${index}`;
    canvas.width = off2.left - (off1.left + off1.width);
    canvas.height = Math.max(off1.height, off2.height);
    canvas.style.position = "absolute";
    canvas.style.left = `${off1.width}px`;
    canvas.style.top = `0px`;

    dom1.appendChild(canvas);

    // bottom right
    const x1 = 16; // remove 16 from x for the padding
    const y1 = off1.height / 2 - 12; // remove 12 from y for margin
    // top right
    const x2 = off2.left - (off1.left + off1.width) - 16;
    const y2 = off1.height / 2 - 12;
    const ctx = canvas.getContext("2d");
    ctx.beginPath();
    if (this.steps[index].index >= this.currentStep.index) ctx.setLineDash([8, 4]); // show dashed line for future process
    ctx.moveTo(x1, y1);
    ctx.lineTo(x2, y2);
    ctx.strokeStyle = color;
    ctx.lineWidth = thickness;
    ctx.stroke();
  }

  drawStepLinkBar(): void {
    this.stepDOMList.forEach((stepDOM, index, list) => {
      if (index === list.length - 1) return;
      this.drawLineBetweenStepDOM(stepDOM.nativeElement, list[index + 1].nativeElement, index);
    });
  }

  onClickStep(step: any): void {
    // disable below line, because all steps are clickable
    // if (this.currentStep.index !== step.index) return;
    this.clickStep.emit(step);
  }
}
