<div class="row mx-0">
  <div class="col-12 d-flex">
    <button
      mat-raised-button
      class="btn btn-danger btn-round btn-fab btn-sm ml-auto"
      (click)="onClose()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>
</div>
