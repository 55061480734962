import { TrustHuman, TrustStatus } from "../enums";
import { SHEPHERD_BUILT_IN_BTN } from "./common.constant";
import { IBeneficiaryCategory, ITrustPersonalProperty } from "../models";

export const TRUST_AUTHORIZATION_ROLE = {
  [TrustHuman.GRANTOR]: "Grantor",
  [TrustHuman.PROTECTOR]: "Protector",
  [TrustHuman.SECONDARY_TRUSTEE]: "Admin",
  [TrustHuman.BENEFICIARY]: "Beneficiary",
};

export const BENEFICIARY_CATEGORIES: IBeneficiaryCategory[] = [
  { value: "human", label: "Human" },
  { value: "trust", label: "Trust" },
];

export const MAIN_TRUST_PROPERTY = [
  { name: "META 1 Holdings", description: "META 1 Coins and Wallet" },
];

export const CHANGE_TRUST_STATUS_ARRAY: TrustStatus[] = [
  TrustStatus.TRUST_COMPLETED,
  TrustStatus.PROTECTOR_CHANGE,
  TrustStatus.TRUSTEE_CHANGE,
  TrustStatus.BENEFICIARY_ADD,
  TrustStatus.BENEFICIARY_REPLACE,
  TrustStatus.BENEFICIARY_REMOVED,
  TrustStatus.PROTECTOR_VERIFIED,
  TrustStatus.TRUSTEE_VERIFIED,
  TrustStatus.BENEFICIARY_ADD_VERIFIED,
  TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
  TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
  TrustStatus.PA_SENT_TO_UCC,
  TrustStatus.TRE_SENT_TO_UCC,
  TrustStatus.BFA_SENT_TO_UCC,
  TrustStatus.BF_ADD_SENT_TO_UCC,
  TrustStatus.BF_R_SENT_TO_UCC,
];

export const DOWNLOAD_TRUST_AMEND_DOC_STATUS = {
  [TrustHuman.PROTECTOR]: [TrustStatus.PROTECTOR_VERIFIED, TrustStatus.PA_SENT_TO_UCC],
  [TrustHuman.SECONDARY_TRUSTEE]: [TrustStatus.TRUSTEE_VERIFIED, TrustStatus.TRE_SENT_TO_UCC],
  [TrustHuman.BENEFICIARY]: [
    TrustStatus.BENEFICIARY_ADD_VERIFIED,
    TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
    TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
    TrustStatus.BFA_SENT_TO_UCC,
    TrustStatus.BF_ADD_SENT_TO_UCC,
    TrustStatus.BF_R_SENT_TO_UCC,
    TrustStatus.BENEFICIARY_TRUST_ADD_VERIFIED,
    TrustStatus.BENEFICIARY_TRUST_REMOVED_VERIFIED,
    TrustStatus.BENEFICIARY_TRUST_REPLACE_VERIFIED,
  ],
};

export const COMPLETE_TRUST_AMEND_STATUS = {
  [TrustHuman.PROTECTOR]: [TrustStatus.PROTECTOR_VERIFIED],
  [TrustHuman.SECONDARY_TRUSTEE]: [TrustStatus.TRUSTEE_VERIFIED],
  [TrustHuman.BENEFICIARY]: [
    TrustStatus.BENEFICIARY_ADD_VERIFIED,
    TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
    TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
    TrustStatus.BENEFICIARY_TRUST_ADD_VERIFIED,
    TrustStatus.BENEFICIARY_TRUST_REMOVED_VERIFIED,
    TrustStatus.BENEFICIARY_TRUST_REPLACE_VERIFIED,
  ],
};

export const TRUST_ENTITY_REPLACE_REASON = {
  [TrustHuman.PROTECTOR]: [
    { label: "Removal by Board of Trustees", value: "removal" },
    { label: "Death", value: "Death" },
    { label: "Resignation", value: "Resignation" },
  ],
  [TrustHuman.SECONDARY_TRUSTEE]: [
    { label: "Removed by the Board of Trustees", value: "removal" },
    { label: "Death", value: "Death" },
    { label: "Resignation", value: "Resignation" },
  ],
  [TrustHuman.BENEFICIARY]: [
    { label: "Removed by the Board of Trustees", value: "removal" },
    { label: "Death", value: "Death" },
    { label: "Resignation", value: "Resignation" },
  ],
};

export const TRUST_PROCESS_STEPS = [
  {
    statusList: [TrustStatus.IN_PROCESS],
    step: {
      index: 1,
      percent: 20,
      text: "Confirming Roles",
    },
  },
  {
    statusList: [TrustStatus.TRUST_IN_REVIEW, TrustStatus.TRUST_SUBMITTED_TO_UCC],
    step: {
      index: 2,
      percent: 40,
      text: "Review",
    },
  },
  {
    statusList: [TrustStatus.SIGNATURES_REQUIRED],
    step: {
      index: 3,
      percent: 60,
      text: "Signatures",
    },
  },
  {
    statusList: [TrustStatus.SIGNATURE_REVIEW],
    step: {
      index: 4,
      percent: 80,
      text: "Review of Signatures",
    },
  },
  {
    statusList: [
      TrustStatus.TRUST_COMPLETED,
      TrustStatus.PROTECTOR_CHANGE,
      TrustStatus.TRUSTEE_CHANGE,
      TrustStatus.BENEFICIARY_ADD,
      TrustStatus.BENEFICIARY_REPLACE,
      TrustStatus.BENEFICIARY_REMOVED,
      TrustStatus.PROTECTOR_VERIFIED,
      TrustStatus.TRUSTEE_VERIFIED,
      TrustStatus.BENEFICIARY_ADD_VERIFIED,
      TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
      TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
      TrustStatus.BENEFICIARY_TRUST_ADD_VERIFIED,
      TrustStatus.BENEFICIARY_TRUST_REMOVED_VERIFIED,
      TrustStatus.BENEFICIARY_TRUST_REPLACE_VERIFIED,
      TrustStatus.PA_SENT_TO_UCC,
      TrustStatus.TRE_SENT_TO_UCC,
      TrustStatus.BFA_SENT_TO_UCC,
      TrustStatus.BF_ADD_SENT_TO_UCC,
      TrustStatus.BF_R_SENT_TO_UCC,
    ],
    step: {
      index: 5,
      percent: 100,
      text: "Complete",
    },
  },
];

export const DEFAULT_TRUST_PERSONAL_PROPERTY = {
  id: 0,
  ppItemName: "USD",
  ppDescription: "United States Dollar",
  ppValue: "1",
  ppQty: 1,
  ppCurrency: "USD",
};

export const BENEFICIARY_TRUST_ROLE_AUTH_STATUS_ARR: TrustStatus[] = [
  TrustStatus.SIGNATURES_REQUIRED,
  TrustStatus.SIGNATURE_REVIEW,
  TrustStatus.TRUST_COMPLETED,
  TrustStatus.PROTECTOR_CHANGE,
  TrustStatus.TRUSTEE_CHANGE,
  TrustStatus.BENEFICIARY_ADD,
  TrustStatus.BENEFICIARY_REPLACE,
  TrustStatus.BENEFICIARY_REMOVED,
  TrustStatus.PROTECTOR_VERIFIED,
  TrustStatus.TRUSTEE_VERIFIED,
  TrustStatus.BENEFICIARY_ADD_VERIFIED,
  TrustStatus.BENEFICIARY_REPLACE_VERIFIED,
  TrustStatus.BENEFICIARY_REMOVED_VERIFIED,
  TrustStatus.PA_SENT_TO_UCC,
  TrustStatus.TRE_SENT_TO_UCC,
  TrustStatus.BFA_SENT_TO_UCC,
  TrustStatus.BF_ADD_SENT_TO_UCC,
  TrustStatus.BF_R_SENT_TO_UCC,
];

export const TRUST_SHEPHERD_STEPS = [
  {
    attachTo: {
      element: ".trust-naming-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel, SHEPHERD_BUILT_IN_BTN.next],
    classes: "trust-naming-shepherd",
    id: "trust-naming-shepherd",
    title: "How to Name Trust",
    text: "This tutorial video shows how name Trust.",
  },
  {
    attachTo: {
      element: ".trust-humans-tutorial-video-ico",
      on: "right",
    },
    buttons: [SHEPHERD_BUILT_IN_BTN.cancel, SHEPHERD_BUILT_IN_BTN.back],
    classes: "trust-humans-shepherd",
    id: "trust-humans-shepherd",
    title: "How to Add Humans to Trust",
    text: "This tutorial video shows how to add Humans to Trust.",
  },
];

export const TRUST_HUMAN_FIELDS = {
  userFirstName: "firstName",
  userLastName: "lastName",
  userEMail: "email",
  "status.status": "status",
};

export const TRUST_BASIC_FIELDS = {
  trustName: "name",
  trustStatus: "status",
};

export const TRUST_PERSONAL_PROPERTY_FIELDS = {
  name: "ppItemName",
  description: "ppDescription",
  // eslint-disable-next-line no-param-reassign
  value: (obj, newObj) => (newObj.ppValue = `${obj.value}`),
  quantity: "ppQty",
  currency: "currency",
};

export const META1_COIN_HOLDER_MAIN_TRUST_FILING_NOTIFICATION =
  "I authorized Universal Law to file a UCC 1 financing statement for this trust on my behalf. Additionally, by authorizing this action my META 1 wallet address will be automatically transferred to this trust and assigned as it's property. The Address assigned is the first wallet address you created on the META EXCHANGE. If you desire to grant additional wallet addresses to the trust property you can do so at a later time.";

export const META1_COIN_HOLDER_OTHER_TRUST_FILING_NOTIFICATION =
  "I authorize Universal Law to file a UCC 1 financial statement for this trust on my behalf.";
