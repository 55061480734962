import { Injectable } from "@angular/core";
import { LocalStorageKey, NO_CB } from "app/models";
import { NgxSpinnerService } from "ngx-spinner";

import { Subject, of, Observable, forkJoin } from "rxjs";
import { distinctUntilChanged, map, switchMap, takeUntil, tap } from "rxjs/operators";

import { ApiService } from "./api.service";
import { EventsService } from "./events.service";
import { JwtService } from "./jwt.service";
import { PersistenceService } from "./persistence.service";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private _user$: Subject<any> = new Subject();
  user = this._user$.asObservable().pipe(distinctUntilChanged());

  constructor(
    private _uLawApi: ApiService,
    private _uLawJwt: JwtService,
    private _uLawState: StateService,
    private _uLawEvent: EventsService,
    private _spinner: NgxSpinnerService,
    private readonly _persistenceService: PersistenceService,
  ) {}

  formatUser(res) {
    const {
      id,
      userFirstName,
      userLastName,
      userEMail,
      userPhone,
      status: { status = "" },
    } = res.body;
    const token = res.headers.get("authorization");

    return {
      userId: id,
      userFirstName,
      userLastName,
      userEMail,
      userPhone,
      userStatus: status,
      token,
    };
  }

  setAuth(user: any): void {
    this._uLawJwt.saveToken(user.token);
    this._user$.next(user);
  }

  // TODO: don't navigate to any route here, because this method is invoked in app.component.ts
  purgeAuth(): void {
    this._uLawJwt.destroyToken();
    this._user$.next(Object.create(null));
  }

  populate(): void {
    const token = this._uLawJwt.getToken();
    if (!token) {
      this.purgeAuth();
      return;
    }

    this._uLawEvent.isShowLoadingSpinner
      .pipe(
        tap((isShow: boolean) => {
          isShow && this._spinner.show();
          !isShow && this._spinner.hide();
        }),
        takeUntil(this._user$),
      )
      .subscribe(NO_CB);

    this._uLawApi
      .get("/user")
      .pipe(
        tap((res) => {
          const user = this.formatUser(res);
          this.setAuth({ ...user, token });
        }),
        tap(({ body }) => this._uLawState.setUser(body)),
        tap(({ body }) =>
          this._persistenceService.setItem(LocalStorageKey.USER_STATUS, body?.status),
        ),
      )
      .subscribe(
        () => {},
        () => this.purgeAuth(),
      );
  }

  login(credentials: any): Observable<any> {
    return this._uLawApi.post("/user/login", credentials).pipe(
      map((res) => {
        const user = this.formatUser(res);
        this.setAuth(user);
        return user;
      }),
    );
  }

  loginWithToken(token: any): Observable<any> {
    this._uLawJwt.saveToken(token);
    return this._uLawApi.get("/user").pipe(
      tap((res) => {
        const user = this.formatUser(res);
        this.setAuth({ ...user });
      }),
      map((res) => res.body),
    );
  }

  loginFromAc(token: any): Observable<any> {
    this._uLawJwt.saveToken(token);
    return this._uLawApi.get("/user/ac/").pipe(
      map((res) => res.headers.get("authorization")),
      tap((acToken: any) => this._uLawJwt.saveToken(acToken)),
      switchMap((acToken) =>
        forkJoin({
          acToken: of(acToken),
          user: this._uLawApi.get("/user"),
        }),
      ),
      map(({ acToken, user }) => {
        const acUser = this.formatUser(user);
        this.setAuth({ ...acUser, token: acToken });
        return { ...acUser, token: acToken };
      }),
    );
  }

  register(credentials: any): Observable<any> {
    return this._uLawApi.post("/user/register", credentials).pipe(map((res) => res.result || ""));
  }

  verify(token) {
    return this._uLawApi.get(`/user/verify/${token}`).pipe(map((res) => res.body));
  }

  authorizeRole(token) {
    return this._uLawApi.get(`/trust/confirmrole/${token}`).pipe(map((res) => res.body));
  }

  resetPassword({ password, token }) {
    return this._uLawApi
      .post("/user/reset_password", { userPassword: password, token })
      .pipe(map((res) => res.body));
  }

  resetPasswordEmail(email) {
    return this._uLawApi
      .get(`/user/reset_password?userEMail=${email}`)
      .pipe(map((res) => res.body));
  }
}
