import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  DisableBtnDirective,
  UserStatusDirective,
  BindQueryParamDirective,
  SpcProgressBarDirective,
  HoverHighlightDirective,
  CurrencyFormatterDirective,
  PhoneMaskDirective,
} from "./common";
import { RouteTransformerDirective } from "./route-transformer";
import { CountryValidateDirective, StateValidateDirective } from "./form-control";
import { PreventDefaultDirective, StopPropagationDirective } from "./event-modifiers";

@NgModule({
  exports: [
    RouteTransformerDirective,
    DisableBtnDirective,
    UserStatusDirective,
    BindQueryParamDirective,
    CurrencyFormatterDirective,
    SpcProgressBarDirective,
    HoverHighlightDirective,
    CountryValidateDirective,
    StateValidateDirective,
    PreventDefaultDirective,
    StopPropagationDirective,
    PhoneMaskDirective,
  ],
  imports: [CommonModule],
  declarations: [
    RouteTransformerDirective,
    DisableBtnDirective,
    UserStatusDirective,
    BindQueryParamDirective,
    CurrencyFormatterDirective,
    SpcProgressBarDirective,
    HoverHighlightDirective,
    CountryValidateDirective,
    StateValidateDirective,
    PreventDefaultDirective,
    StopPropagationDirective,
    PhoneMaskDirective,
  ],
})
export class DirectivesModule {}
