import { filterKey } from "./decorators";

class FilterUtil {
  @filterKey()
  filterState(list: any[], key: string): any[] {
    return list.filter((el) => el.toLowerCase().includes(key));
  }

  @filterKey()
  filterCountry(list: any[], key: string): any[] {
    return list.filter((el) => el.name.toLowerCase().includes(key));
  }
}

export const filterUtil = new FilterUtil();
