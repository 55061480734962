export enum NncCaseCategory {
  CRIMINAL = "Criminal",
  CIVIL = "Civil",
  FAMILY = "Family",
}

export enum NNCStatus {
  REVIEW = "First",
  DOC_READY = "Doc ready",
  REJECTED = "Rejected",
  UPLOADED = "Uploaded",
  COMPLETED = "Completed",
}

export enum NNCDocNames {
  JUDGE = "Judge",
  CLERK = "Clerk",
}
