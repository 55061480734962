import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import {
  StateMaskPipe,
  CountryMaskPipe,
  SpcProgressStepPipe,
  SocialSecurityNumMaskPipe,
} from "./profile";
import {
  VideoPipe,
  TooltipPipe,
  TruncatePipe,
  DialogTitlePipe,
  FormCtrlErrPipe,
  OrdinalSuffixPipe,
  WhiteSpaceMaskPipe,
  DynClassPipe,
} from "./common";
import { SafePipe } from "./safe";

@NgModule({
  declarations: [
    SafePipe,
    VideoPipe,
    TooltipPipe,
    TruncatePipe,
    StateMaskPipe,
    DialogTitlePipe,
    FormCtrlErrPipe,
    CountryMaskPipe,
    OrdinalSuffixPipe,
    WhiteSpaceMaskPipe,
    SpcProgressStepPipe,
    SocialSecurityNumMaskPipe,
    DynClassPipe,
  ],
  imports: [CommonModule],
  exports: [
    SafePipe,
    VideoPipe,
    TooltipPipe,
    TruncatePipe,
    StateMaskPipe,
    DialogTitlePipe,
    CountryMaskPipe,
    FormCtrlErrPipe,
    OrdinalSuffixPipe,
    WhiteSpaceMaskPipe,
    SpcProgressStepPipe,
    SocialSecurityNumMaskPipe,
    DynClassPipe,
  ],
})
export class PipesModule {}
