import { Injectable } from "@angular/core";

import { BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { SessionStatus } from "@models/enums";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private _sessionStatus$ = new BehaviorSubject<SessionStatus>(null);
  sessionStatus = this._sessionStatus$.asObservable().pipe(distinctUntilChanged());

  private _sessionTime$ = new BehaviorSubject<number>(0);
  sessionTime = this._sessionTime$.asObservable().pipe(distinctUntilChanged());

  private _lastActiveTime$ = new BehaviorSubject<any>(null);
  lastActiveTime = this._lastActiveTime$.asObservable().pipe(distinctUntilChanged());

  setStatus(status: SessionStatus): void {
    this._sessionStatus$.next(status);
  }

  setSessionTime(sessionTime: number): void {
    this._sessionTime$.next(sessionTime);
  }

  setLastActiveTime(): void {
    this._lastActiveTime$.next(Date.now());
  }
}
