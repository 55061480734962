import { Directive, ElementRef, HostListener } from "@angular/core";
import { Location } from "@angular/common";
import { Router } from "@angular/router";

@Directive({
  selector: "[appRouteTransformer]",
})
export class RouteTransformerDirective {
  constructor(
    private readonly _elRef: ElementRef,
    private readonly _router: Router,
    private readonly _location: Location,
  ) {}

  @HostListener("click", ["$event"])
  public onClick(event) {
    if (event.target.tagName === "A") {
      this._router.navigateByUrl(this._location.path(false) + event.target.hash);
      event.preventDefault();
    }
  }
}
