<nav #navbar class="navbar navbar-expand-lg navbar-transparent navbar-absolute">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <div class="navbar-minimize">
        <button
          mat-raised-button
          (click)="minimizeSidebar()"
          class="btn btn-just-icon btn-white btn-fab btn-round"
        >
          <i class="material-icons text_align-center visible-on-sidebar-regular"> more_vert </i>
          <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini"> view_list </i>
        </button>
      </div>
      <a class="navbar-brand font-weight-bold" href="{{ path }}">{{ path | navbarTitle }}</a>
      <mat-icon
        class="ml-2 puls-icon"
        [appNavBarHelpIcon]="path"
        appHoverHighlight="#F5AF1C"
        matTooltip="Tutorial Video"
        matTooltipPosition="above"
        (click)="onPlayHelpVideo('trust.setup')"
      >
        help_outline
      </mat-icon>
    </div>
    <button mat-button class="navbar-toggler btn-no-ripple" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">
        <li class="nav-item dropdown">
          <a
            class="nav-link"
            href="#pablo"
            id="navbarDropdownMenuLink"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <i class="material-icons">person</i>
            <p>
              <span class="d-lg-none d-md-block">Some Actions</span>
            </p>
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
            <a class="dropdown-item" href="javascript:void(0)">
              <span class="mr-2 text-uppercase font-weight-bold text-muted"> SPC Status: </span>
              <span class="font-weight-bold"> {{ user?.status?.status }} </span>
            </a>
            <a class="dropdown-item" href="#" (click.prevent)="onLogout($event)">
              <i class="material-icons mr-3">exit_to_app</i>
              <span class="font-weight-bold"> Log Out </span>
            </a>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
