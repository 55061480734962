import { Injectable } from "@angular/core";

import { Subject, Observable, BehaviorSubject } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";

import { IPdf } from "app/models";

@Injectable({
  providedIn: "root",
})
export class OverlayService {
  private _videoKey$: Subject<string> = new Subject();
  videoKey: Observable<string> = this._videoKey$.asObservable();

  private _pdf$: Subject<IPdf> = new Subject();
  pdf: Observable<IPdf> = this._pdf$.asObservable();

  private _isShowUploadBar$: Subject<boolean> = new Subject();
  isShowUploadBar: Observable<boolean> = this._isShowUploadBar$.asObservable();

  private _uploadPercent$: Subject<number> = new Subject();
  uploadPercent: Observable<number> = this._uploadPercent$.asObservable();

  private _uploadingFilename$: BehaviorSubject<string> = new BehaviorSubject(null);
  uploadingFilename = this._uploadingFilename$.asObservable().pipe(distinctUntilChanged());

  setVideoKey(videoKey: string): void {
    this._videoKey$.next(videoKey);
  }

  setPdf(pdf: IPdf): void {
    this._pdf$.next(pdf);
  }

  showUploadBar(): void {
    this._isShowUploadBar$.next(true);
  }

  hideUploadBar(): void {
    this._isShowUploadBar$.next(false);
  }

  setUploadPercent(percent: number): void {
    this._uploadPercent$.next(percent);
  }

  setUploadingFilename(filename: string): void {
    this._uploadingFilename$.next(filename);
  }
}
