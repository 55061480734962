import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";

import { Subject } from "rxjs";
import { filter, takeUntil, tap } from "rxjs/operators";

import { NO_CB } from "@models/constants";
import { uLawNavigator } from "@util/navigator-util";
import { AuthService, OverlayService, StateService } from "@core/services";

const misc: any = {
  navbar_menu_visible: 0,
  active_collapse: true,
  disabled_collapse_init: 0,
};

declare const $: any;

@Component({
  selector: "app-navbar-cmp",
  templateUrl: "navbar.component.html",
  styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  // DOM variables
  location: Location;
  // tslint:disable-next-line: variable-name
  mobile_menu_visible: any = 0;
  nativeElement: Node;
  toggleButton: any;
  sidebarVisible: boolean;

  user: any;

  @ViewChild("app-navbar-cmp", { static: false }) button: any;

  constructor(
    location: Location,
    private _elRef: ElementRef,
    private _router: Router,
    private _store: StateService,
    private _authService: AuthService,
    private _overlayService: OverlayService,
  ) {
    this.location = location;
    this.nativeElement = _elRef.nativeElement;
    this.sidebarVisible = false;
    this._stop$ = new Subject();
  }

  minimizeSidebar() {
    const body = document.getElementsByTagName("body")[0];

    if (misc.sidebar_mini_active === true) {
      body.classList.remove("sidebar-mini");
      misc.sidebar_mini_active = false;
    } else {
      setTimeout(function () {
        body.classList.add("sidebar-mini");

        misc.sidebar_mini_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }
  hideSidebar() {
    const body = document.getElementsByTagName("body")[0];
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (misc.hide_sidebar_active === true) {
      setTimeout(function () {
        body.classList.remove("hide-sidebar");
        misc.hide_sidebar_active = false;
      }, 300);
      setTimeout(function () {
        sidebar.classList.remove("animation");
      }, 600);
      sidebar.classList.add("animation");
    } else {
      setTimeout(function () {
        body.classList.add("hide-sidebar");
        // $('.sidebar').addClass('animation');
        misc.hide_sidebar_active = true;
      }, 300);
    }

    // we simulate the window Resize so the charts will get updated in realtime.
    const simulateWindowResize = setInterval(function () {
      window.dispatchEvent(new Event("resize"));
    }, 180);

    // we stop the simulation of Window Resize after the animations are completed
    setTimeout(function () {
      clearInterval(simulateWindowResize);
    }, 1000);
  }

  ngOnInit() {
    const navbar: HTMLElement = this._elRef.nativeElement;
    const body = document.getElementsByTagName("body")[0];
    // eslint-disable-next-line prefer-destructuring
    this.toggleButton = navbar.getElementsByClassName("navbar-toggler")[0];
    if (body.classList.contains("sidebar-mini")) {
      misc.sidebar_mini_active = true;
    }
    if (body.classList.contains("hide-sidebar")) {
      misc.hide_sidebar_active = true;
    }
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.sidebarClose();

        const $layer = document.getElementsByClassName("close-layer")[0];
        if ($layer) {
          $layer.remove();
        }
      });

    this._store.user
      .pipe(
        tap((user) => (this.user = user)),
        takeUntil(this._stop$),
      )
      .subscribe(NO_CB);
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  onResize(event) {
    return !($(window).width() > 991);
  }

  sidebarOpen() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const toggleButton = JSON.parse(JSON.stringify(this.toggleButton));
    const body = document.getElementsByTagName("body")[0];
    setTimeout(function () {
      toggleButton.classList?.add("toggled");
    }, 500);
    body.classList.add("nav-open");
    setTimeout(function () {
      $toggle.classList.add("toggled");
    }, 430);

    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    if (body.querySelectorAll(".main-panel")) {
      document.getElementsByClassName("main-panel")[0].appendChild($layer);
    } else if (body.classList.contains("off-canvas-sidebar")) {
      document.getElementsByClassName("wrapper-full-page")[0].appendChild($layer);
    }

    setTimeout(function () {
      $layer.classList.add("visible");
    }, 100);

    $layer.onclick = function () {
      // assign a function
      body.classList.remove("nav-open");
      this.mobile_menu_visible = 0;
      this.sidebarVisible = false;

      $layer.classList.remove("visible");
      setTimeout(function () {
        $layer.remove();
        $toggle.classList.remove("toggled");
      }, 400);
    }.bind(this);

    body.classList.add("nav-open");
    this.mobile_menu_visible = 1;
    this.sidebarVisible = true;
  }

  sidebarClose() {
    const $toggle = document.getElementsByClassName("navbar-toggler")[0];
    const body = document.getElementsByTagName("body")[0];
    this.toggleButton.classList.remove("toggled");
    const $layer = document.createElement("div");
    $layer.setAttribute("class", "close-layer");

    this.sidebarVisible = false;
    body.classList.remove("nav-open");
    // $('html').removeClass('nav-open');
    body.classList.remove("nav-open");
    if ($layer) {
      $layer.remove();
    }

    // setTimeout(function () {
    //   $toggle.classList.remove('toggled');
    // }, 400);

    this.mobile_menu_visible = 0;
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  onLogout(ev: any) {
    this._authService.purgeAuth();
    this._router.navigate(["/login"]);
    uLawNavigator.login();
  }

  onPlayHelpVideo(videoKey: string): void {
    videoKey && this._overlayService.setVideoKey(videoKey);
  }

  capitalizeFirstCharacter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  get path(): string {
    return this.location.prepareExternalUrl(this.location.path());
  }
}
