<div class="row bg-white" [style.width.px]="width">
  <div
    class="col-12 d-flex align-items-center"
    style="background-color: #323639 !important; height: 56px !important"
  >
    <span class="f-16-500 text-white"> {{ fileDisplayName }} </span>
    <button
      type="button"
      mat-raised-button
      class="btn btn-warning btn-round btn-fab btn-sm ml-auto mr-1"
      [disabled]="!isPdfLoaded"
      (click)="onDownloadPdf()"
    >
      <i class="material-icons">get_app</i>
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn btn-success btn-round btn-fab btn-sm mx-1"
      [disabled]="!isPdfLoaded"
      (click)="onPrintPdf()"
    >
      <i class="material-icons">print</i>
    </button>
    <button
      type="button"
      mat-raised-button
      class="btn btn-danger btn-round btn-fab btn-sm ml-1"
      (click)="onClose()"
    >
      <i class="material-icons">close</i>
    </button>
  </div>

  <div class="col-12 mt-2 mb-4 overflow-auto" [style.height.px]="height">
    <pdf-viewer
      #pdfPreview
      [src]="docSrc"
      [original-size]="false"
      [fit-to-page]="true"
      [render-text]="true"
      (after-load-complete)="onInitPdfView($event)"
      (error)="onErrorPdf($event)"
      style="display: block"
    ></pdf-viewer>
  </div>
</div>
