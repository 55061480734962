import { MatSnackBarConfig } from "@angular/material/snack-bar";

export const getSnackBarConfig = ({
  data = {},
  duration = null,
  panelClass = "snack-bar",
}): MatSnackBarConfig => {
  return {
    data,
    duration,
    panelClass,
    horizontalPosition: "center",
    verticalPosition: "bottom",
  };
};
