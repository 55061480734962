import { Injectable } from "@angular/core";

import { LocalStorageKey } from "@models/enums";

import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: "root",
})
export class JwtService {
  getToken(): string {
    return JSON.parse(localStorage.getItem(LocalStorageKey.AUTH_TOKEN));
  }

  saveToken(token: string): void {
    localStorage.setItem(LocalStorageKey.AUTH_TOKEN, JSON.stringify(token));
  }

  destroyToken(): void {
    localStorage.removeItem(LocalStorageKey.AUTH_TOKEN);
  }

  decodeToken(): any {
    const token = localStorage.getItem(LocalStorageKey.AUTH_TOKEN);
    return jwt_decode(token);
  }

  hasExpired(): boolean {
    const { exp } = this.decodeToken();
    const isExpired = new Date().getTime() > exp * 1000;
    return isExpired;
  }
}
