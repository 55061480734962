import { NncCaseType } from "app/models";
import { NncCaseCategory, NNCStatus } from "../enums";

export const NNC_CASE_TYPE: NncCaseType[] = [
  {
    label: "Civil",
    value: NncCaseCategory.CIVIL,
  },
  {
    label: "Criminal",
    value: NncCaseCategory.CRIMINAL,
  },
  {
    label: "Family",
    value: NncCaseCategory.FAMILY,
  },
];
