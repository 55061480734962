/**
 * Enum of GSA Bond Product Status
 *
 * @enum {string} GSA Bond Product Status
 *
 */
export enum GsaProductStatus {
  NONE = "none",
  REVIEW = "Data review",
  REJECTED = "Data reject",
  ACCEPTED = "Accepted",
  DOWNLOADED = "Downloaded",
}

/**
 * Enum of GSA Bond Product Element
 *
 * @enum {string} GSA Bond Product Element
 *
 */
export enum GsaProductElement {
  NONE = "none",
  GET_GSA_BUTTON = "get_gsa_btn",
  DOWNLOAD_GSA_BUTTON = "download_gsa_btn",
}

export enum Gsa2VProductStatus {
  DATA_REVIEW = "Data review",
  DOC_1_READY = "Doc 1 ready",
  DOC_1_DOWNLOADED = "Doc 1 Downloaded",
  DOC_1_SIGNATURE_REVIEW = "Doc 1 signature review",
  DOC_2_READY = "Doc 2 ready",
  DOC_2_TRACKING = "Doc 2 tracking",
  DOC_2_DELIVERED = "Doc 2 Delivered",
  DOC_3_READY = "Doc 3 ready",
  DOC_3_DOWNLOADED = "Doc 3 Downloaded",
  DOC_3_SIGNATURE_REVIEW = "Doc 3 signature review",
  DOC_4_READY = "Doc 4 ready",
  DOC_4_TRACKING = "Doc 4 tracking",
  DOC_4_DELIVERED = "Doc 4 Delivered",
  COMPLETE = "Complete",
  GSA_TO_UCC = "GSA to UCC",
  GSA_ACCEPTED = "GSA Accepted",
}

export enum GSA2vDocNames {
  DOC1_JUDGE = "Doc1Judje",
  DOC1_IRS = "Doc1Irs",
  DOC1_DTC = "Doc1Dtc",
  DOC1_TREASURY = "Doc1Treasury",
  DOC2_JUDGE = "Doc2Judje",
  DOC2_IRS = "Doc2Irs",
  DOC2_DTC = "Doc2Dtc",
  DOC2_TREASURY = "Doc2Treasury",
  BC_AND_SPC = "BcAndSpcPack",
  A_PACK = "1099-aPack",
  COURT_JUDGE = "CourtJudjePack",
  COURT_IRS = "CourtIrsPack",
  COURT_DTC = "CourtDtcPack",
  COURT_TREASURY = "CourtTreasuryPack",
  IRS_JUDGE = "IrsFormJudjePack",
  IRS_IRS = "IrsFormIrsPack",
  IRS_DTC = "IrsFormDtcPack",
  IRS_TREASURY = "IrsFormTreasuryPack",
  JUDGE_MAIL_RECEIPT = "judgeMailReceipt",
  JUDGE_TRACKING = "judgeTracking",
  IRS_MAIL_RECEIPT = "irsMailReceipt",
  IRS_TRACKING = "irsTracking",
  DTC_MAIL_RECEIPT = "dtcMailReceipt",
  DTC_TRACKING = "dtcTracking",
  TREASURY_MAIL_RECEIPT = "treasuryMailReceipt",
  TREASURY_TRACKING = "treasuryTracking",
  SETT_OF_LITIGATION = "settlementOfLitigation",
  PROOF_OF_SERVICE = "settlementOfLitigationProofOfServicePack",
  JUDGE_MAIL_RECEIPT2 = "judgeMailReceipt2",
  JUDGE_TRACKING2 = "judgeTracking2",
  DOC_1 = "doc1",
  DOC_2 = "doc2",
}

export enum GSABondCaseCategory {
  CRIMINAL = "Criminal",
  CIVIL = "Civil",
}
