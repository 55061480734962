import { Injectable } from "@angular/core";
import { CanActivate, UrlTree, Router } from "@angular/router";

import { Observable } from "rxjs";

import { JwtService } from "../services";

@Injectable({
  providedIn: "root",
})
export class NoAuthGuard implements CanActivate {
  constructor(private readonly router: Router, private readonly _jwt: JwtService) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const token = this._jwt.getToken();

    if (token) {
      this.router.navigate(["/dashboard"]);
      return false;
    }

    return true;
  }
}
