import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "ulaw-side-drawer",
  templateUrl: "./side-drawer.component.html",
  styleUrls: ["./side-drawer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideDrawerComponent {
  @Input() content: any;
  @Output() closeDrawer: EventEmitter<any>;

  constructor() {
    this.setVariables();
  }

  setVariables(): void {
    this.closeDrawer = new EventEmitter();
  }

  onClose(): void {
    this.closeDrawer.emit();
  }
}
