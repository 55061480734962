import { environment } from "environments/environment";

class ULawNavigator {
  loginURL: string;
  registerInviteeURL: string;
  productURL: string;

  constructor(loginURL: string, registerInviteeURL: string, productURL: string) {
    this.loginURL = loginURL;
    this.registerInviteeURL = registerInviteeURL;
    this.productURL = productURL;
  }

  login(): void {
    window.open(this.loginURL, "_self");
  }

  purchase(): void {
    window.open(this.productURL, "_self");
  }

  registerInvitee(email: string): void {
    window.open(`${this.registerInviteeURL}?email=${email}`, "_self");
  }
}

export const uLawNavigator = new ULawNavigator(
  environment.universalLawLogin,
  environment.universalLawRegisterInvitee,
  environment.universalLawProduct,
);
