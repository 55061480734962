import { Injectable } from "@angular/core";

import { Observable } from "rxjs";
import { map, mergeMap, tap } from "rxjs/operators";

import { ApiService } from "./api.service";
import { StateService } from "./state.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(private _uLawApi: ApiService, private _uLawState: StateService) {}

  getUser(): Observable<any> {
    return this._uLawApi.get("/user").pipe(
      map((res) => res.body),
      tap((user) => this._uLawState.setUser(user)),
    );
  }

  findUserByEmail(email: string): Observable<any> {
    return this._uLawApi.get(`/trust/finduser?email=${email}`).pipe(map((user) => user.body));
  }

  inviteUserWithTrustRole({ email: userEMail, role: userRole }): Observable<any> {
    return this._uLawApi
      .post("/user/invite", { userEMail, userRole })
      .pipe(mergeMap(() => this.findUserByEmail(userEMail)));
  }

  uploadProductPdf(formData: FormData, isExhibit = false): Observable<any> {
    if (isExhibit) {
      return this._uLawApi.put("/product/exhibit", formData);
    }
    return this._uLawApi.put("/product", formData);
  }

  getProductById(productId: any): Observable<any> {
    return this._uLawApi.get(`/product/one?productId=${productId}`).pipe(map((res) => res.body));
  }
}
