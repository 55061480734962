import { NOTIFICATION_MSG } from "app/models/constants";

export function notificationMsg(): MethodDecorator {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (args) {
      const { isOrigin, originMsg, msgKey } = args;
      const msg = isOrigin ? originMsg : this.getMsg(NOTIFICATION_MSG, msgKey);
      this.notify(propertyKey, msg);
    };
  };
}

declare const $: any;

const NotificationTemplate =
  '<div data-notify="container" class="notification-user col-xs-11 col-sm-3 alert alert-{0} alert-with-icon" role="alert">' +
  '<button mat-raised-button type="button" aria-hidden="true" class="close" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
  '<i class="material-icons" data-notify="icon">notifications</i> ' +
  '<span data-notify="title">{1}</span> ' +
  '<span data-notify="message">{2}</span>' +
  '<div class="progress" data-notify="progressbar">' +
  '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
  "</div>" +
  '<a href="{3}" target="{4}" data-notify="url"></a>' +
  "</div>";

class NotificationUtil {
  private _template: any;
  private listOfNotificationDom: any;

  constructor(template) {
    this._template = template;
  }

  notify(type: string, msg: string) {
    this.listOfNotificationDom = $(".notification-user");
    const removeArray = this.listOfNotificationDom.slice(0, this.listOfNotificationDom.length - 1);

    removeArray.get().map((val) => {
      $("div.notification-user button:first").click();
    });

    setTimeout(() => {
      $.notify(
        {
          icon: "notifications",
          message: msg,
        },
        {
          type,
          timer: 45 * 1000,
          placement: {
            from: "top",
            align: "right",
          },
          template: this._template,
        },
      );
    }, 500);
  }

  getMsg(msg: any, key: string) {
    const keys = key.trim().split(".");
    const [curKey, ...leftKeys] = keys;
    const curMsg = msg[curKey];
    const curKeys = leftKeys.join(".");
    return keys.length === 1 ? curMsg : this.getMsg(curMsg, curKeys);
  }

  @notificationMsg()
  info({ isOrigin, msgKey, originMsg }) {}

  @notificationMsg()
  success({ isOrigin, msgKey, originMsg }) {}

  @notificationMsg()
  warning({ isOrigin, msgKey, originMsg }) {}

  @notificationMsg()
  danger({ isOrigin, msgKey, originMsg }) {}
}

export const notificationUtil = new NotificationUtil(NotificationTemplate);
