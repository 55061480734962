export const NOTIFICATION_MSG = {
  globalErr: "Some errors occurred. Please try again later.",
  tokenexpired: "Token expired. Please try to login.",
  auth: {
    login: {
      success: "Login succeed.",
      failure: "Login Failed.",
      acFailure: "Login with token failed.",
      tokenFailure: "Invalid token provided.",
      verifyEmail: "Email is not verified yet. Please verify your email first.",
    },
    resetPassword: {
      success: "Reset password email successfully sent.",
      failure: "Reset password failed. This user not found.",
    },
    register: {
      success:
        "Registration Successful. Please check your email to verify your account and log in.",
      failure: "Some registration problems. This email already exists.",
    },
    password: {
      success: "Reset Password Succeed.",
      failure: "Reset password failed.",
    },
    verify: {
      success: "Email has been verified.",
      failure: "Verification token expired, please contact admin.",
    },
    authorizeRole: {
      confirmed:
        "Your participation in the trust has been confirmed, please reach out to the human that invited you for next steps.",
      invalidToken: "Invalid token provided.",
      expired: "Invitation has expired, please contact admin.",
    },
  },
  profile: {
    address: {
      verified: "Your account has been verified.",
      saved:
        "Save successful - your basic profile has been saved. Please scroll down and complete your extended profile.",
      updateinsecured: "Your address has been updated.",
      spcPurchaseUSFailure:
        "You have not purchased any Secured Party services, you will be automatically directed to services options in 5 seconds.",
      spcPurchaseNAFailure:
        "You have not purchased any trusts, you will be automatically directed to services options in 5 seconds.",
      addressIsoFailure: "Your address is not correct.",
    },
    extended: {
      success: "Save successful - your extended profile has been saved.",
      failure: "Your extended profile does not saved.",
    },
    spc: {
      spcUploadSuccess: "SPC Packet successfully uploaded and received.",
      spcUploadFailure: "Upload SPC Packet Failed.",
      spcConsentUploadSuccess: "SPC Consent successfully uploaded and received.",
      spcConsentUploadFailure: "Upload SPC Consent Packet Failed.",
    },
    downloadspcpacket: "Please download SPC Packet first",
    downloadspcconsentpacket: "Please download SPC Consent Packet first",
    downloadspcadmendpacket: "Please download SPC Addendum Packet first",
  },
  trust: {
    basic: {
      noName: "Enter trust name.",
    },
    human: {
      noGrantor: "Add grantor.",
      noProtector: "Add protector.",
      noSecondaryTrustee: "Add secondary trustee.",
      authorizationRequestSuccess: "Role authorization request succeed.",
      authorizationRequestFailure: "Role authorization request failed.",
      replaceProtectorSuccess:
        "Protector successfully replaced, you will receive an email notification as soon as the replacement Protector has been verified in our system.",
      replaceSecondaryTrusteeSuccess:
        "Trustee successfully replaced, you will receive an email notification as soon as the replacement Trustee has been verified in our system.",
      replaceFailure: "Replace trust human failed",
      uploadChangedDocSuccess:
        "File upload successful. Once the UCC 3 filing is complete, we will email you and the role change will be finalized.",
      uploadChangedDocFailure: "Upload changed trust doc failure.",
      completeProtectorReplacementSucceed:
        "Replacement of Protector successful. No more action is required.",
      completeSecondaryTrusteeReplacementSucceed:
        "Replacement of Second Trustee successful. No more action is required.",
      completeReplacementFailure: "Complete trust human replacement failure.",
    },
    beneficiary: {
      noBeneficiary: "Add at least one beneficiary.",
      requestAuthorizationSuccess: "Authorization request succeed.",
      requestAuthorizationFailure: "Authorization request failed.",
      addSuccess:
        "New Beneficiary successfully added, you will receive an email notification as soon as the beneficiary has been verified in our system.",
      addFailure: "Add new beneficiary failed.",
      replaceSuccess:
        "New Beneficiary successfully added, you will receive an email notification as soon as the beneficiary has been verified in our system.",
      replaceFailure: "Replace beneficiary failed.",
      deleteSuccess:
        "To complete removal of selected beneficiary, please download and execute the amendment paperwork. ",
      deleteFailure:
        "Beneficiary cannot be removed due to there being only one. Please use the replacement function to remove this beneficiary and replace with a new one.",
      completeRemovalSuccess:
        "Selected Beneficiary has been successfully removed from the trust. No more action is required.",
      completeSuccess: "Complete trust beneficiary change succeed. No more action is required.",
      completeBeneficiaryAddSuccess:
        "The new beneficiary has been successfully added to the trust. No more action is required.",
      completeBeneficiaryChangeSuccess:
        "Complete trust beneficiary change succeed. No more action is required.",
      completeFailure: "Complete trust beneficiary change failed.",
      uploadSuccess:
        "File upload successful. Once the UCC 3 filing is complete, we will email you and the role change will be finalized.",
      uploadFailure: "Upload beneficiary changed doc failed.",
      addBTrustSuccess: "New Beneficiary Trust successfully added.",
      addBTrustFailure: "Add new Beneficiary Trust failed",
      replaceBTrustSuccess: "",
      replaceBTrustFailure: "",
      removeBTrustSuccess: "Remove beneficiary trust succeed.",
      removeBTrustFailure: "Remove beneficiary trust failed.",
      completeBTrustAddSuccess: "Complete adding beneficiary trust change succeed.",
      completeBTrustReplaceSuccess: "Complete replacing beneficiary trust change succeed.",
      completeBTrustRemovalSuccess: "Complete removing beneficiary trust change succeed.",
      completeBTrustAmendFailure: "Complete beneficiary trust change failed.",
    },
    property: {
      noProperty: "Add Trust property.",
    },
    propertyamend: {
      upload:
        "Trust Property Amendment uploaded successfully and submitted to the UCC for filing. Please check your email for a notification that the filing is complete.",
      complete: "Trust Property Amendment completed.",
    },
    verifyProfile: "Please verify your profile first.",
    purchasedTrust:
      "You have not purchased a trust, you will be re-directed to our service offerings in 5 seconds.",
    beneficiaryTrustPurchase:
      "You have not enough purchased trust. Please purchase trust first and try again.",
    humanNotMe: "Trust human needs to be a man or woman that you are not related to.",
    humanNotGrantor:
      "Grantor cannot hold another role within the trust, please choose another human for this role.",
    humanNotProtector:
      "Protector cannot hold another role within the trust, please choose another human for this role.",
    humanNotFound:
      "User was not found with that email address, please click Invite to start registration for the human you are adding into your trust.",
    humanInviteSuccess: "User invitation succeed.",
    humanInviteFailure: "User invitation failure.",
    createSuccess:
      "Trust created successfully, as soon as all humans involved in the trust have registered and verified their accounts. You will receive an email with next steps on how to execute the trust. ",
    createFailure: "Create trust failed.",
    updateSuccess: "Update trust succeed.",
    updateFailure: "Update trust failed.",
    trustNotFound:
      "Beneficiary Trust does not exist with this email. Please create another Beneficiary Trust or choose another Beneficiary Trust.",
    downloadTrustFailure: "Trust is not ready.",
    uploadTrustSuccess:
      "Trust Packet Uploaded. Your Trust Packet is now being reviewed by our team. Once the review is complete you will receive an email with next steps.",
    uploadCompletedTrustSuccess:
      "Trust successfully uploaded, Congratulations! your trust is complete and ready for use.",
    uploadTrustFailure: "Trust Packet uploading failed.",
    inputTrackingNumSuccess: "Input Tracking Number succeed.",
    inputTrackingNumFailure: "Input Tracking Number failed.",
  },
  spcAmend: {
    verifyProfile: "Please verify your profile first.",
    completeUccState: "Please complete ucc state form.",
    completeChildForm: "Please complete child form.",
    saveSuccess:
      "Authentication submitted successfully, after our staff reviews your request we will email you with next steps.",
    saveFailure: "Authentication submission failure.",
    pdf: {
      uploadSuccess:
        "Upload successful - Your Addendum Document is being reviewed and then will be filed. We will email you with next steps when the filing process is complete.",
      uploadFailure: "SPC addendum pdf uploading failed.",
      trackingNumSuccess:
        "Tracking Number updated, once your Treasury Packet is received by the UST your SPC process will be complete.",
      trackingNumFailure: "Input Tracking Number Failed.",
    },
  },
  pdf: {
    spc: {
      spc: "SPC is not available.",
      spcConsent: "SPC Consent is not available.",
      spcReport: "SPC Report is not available.",
      spcAmendment: "SPC Addendum is not available.",
      spcAmendmentReport: "SPC Addendum Report is not available.",
      treasuryPacket: "Treasury Packet is not available.",
      // masterBond: "Master Bond is not available.",
      masterBond: `Please contact support at <a href='mailto:support@8universallaw.com' target='_blank'>support@8universallaw.com</a>`,
    },
    trust: {
      default: "Trust is not available.",
      changed: "Changed Trust is not available.",
    },
  },
  services: {
    masterBond: {
      userNotFound: "User not found with this email. Please try to search another user.",
      userNotSecured: "User status must be 'Secured' status. Please try to search another user.",
      userAlreadySelected:
        "User already selected as Surety Security Party. Please try to search another user.",
      suretyPartyNotSelected: "Please select Master Bond Surety Security Party.",
      updateSuretyPartyNotSelected: "Please update Master Bond Surety Security Party.",
      setMasterBondSuccess:
        "Your Master Bond has been submitted and is currently being filed, once the filing process is complete we will email you.",
      setMasterBondFailed: "Set master bond failed.",
      upload: {
        success: "Master Bond Document uploaded",
        failure: "Master Bond upload failed",
      },
    },
    nda: {
      upload: {
        success: "Non-Disclosure Agreement Document uploaded",
        failure: "Non-Disclosure Agreement upload failed",
      },
    },
    taxSettlement: {
      bondRequired: "Please complete the Master Bond process before starting your Tax Settlement.",
      getPacketSucceed:
        "Your information is being reviewed, we will send you an email when your Tax Settlement package is ready for preparation and mailing.",
      getPacketFailed: "Saving your tax information failed.",
      downloadFailed:
        "Your Tax settlement packet is not available yet, please check back in a few minutes",
      notPurchased:
        "You did not purchase tax product. Please try to purchase tax product in Universal Law.",
      notEnoughPurchased:
        "You don't have enough purchased tax product. Please try to purchase more tax product in Universal Law.",
      upload: {
        success: "Tax Settlement Document uploaded",
        failure: "Tax Settlement upload failed",
      },
      regenerate: {
        success: "Document regenerated successfully",
        failure: "Document regeneration failed",
      },
    },
    gsa: {
      getGsaPacketSuccess:
        "Our team is reviewing your GSA Packet, as soon as it is approved and ready, we will email you",
      getGsaPacketFailure: "Get Gsa Packet failed",
      upload: {
        success: "GSA Bond Document uploaded",
        failure: "GSA Bond upload failed",
      },
    },
    easylien: {
      noticeexemption: {
        success:
          "The data entered is being reviewed.  Any questions or concerns may be directed to Support@8universallaw.com",
      },
      noticedefault: {
        success:
          "We are generating your notice of default, check back here in a moment to download it.",
      },
      noticelien: {
        info: "We are generating your notice of lien, check back here in a moment to download it",
      },
      incidentstatement: {
        success: "Incident Statement successfully updated.",
      },
    },
    easylienv2: {
      upload: {
        success: "Easylien Document uploaded",
        failure: "Easylien upload failed",
      },
      continueProcess: {
        yes: "please contact support",
        success: "Your response saved successfully",
        failure: "Something went wrong, Please try again",
      },
    },
    nnc: {
      create: {
        success: "NNC Created Successfully",
      },
      update: {
        success: "NNC Updated Successfully",
      },
      upload: {
        success: "NNC Document uploaded",
        failure: "NNC upload failed",
      },
    },
    irvic: {
      getIrvicSuccess:
        "Our team is reviewing your Test Packet, as soon as it is approved and ready, we will email you",
      upload: {
        success: "Test Doc uploaded",
        failure: "Test doc upload failed",
      },
    },
  },
  uploadsmallerfile:
    "This file is too large and cannot be uploaded, please compress file and try again.",
};
