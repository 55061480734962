import { Pipe, PipeTransform } from "@angular/core";

import { FORM_CONTROL_ERROR } from "app/models/constants/form-control-errors.constant";

@Pipe({
  name: "formCtrlErr",
})
export class FormCtrlErrPipe implements PipeTransform {
  transform(formCtrl: string, ...args: any[]): any {
    const [validation] = args;
    return FORM_CONTROL_ERROR[formCtrl][validation] || "";
  }
}
