<div class="snackbar-container">
  <div class="snackbar-icon">
    <span class="material-icons">notifications</span>
  </div>
  <div class="snackbar-msg">
    <span>You will be signed out after {{ sessionTimeInSecond }} seconds.</span>
  </div>
  <div class="snackbar-actions">
    <span class="snackbar-btn" (click)="onContinueSession()"> Continue Session </span>
  </div>
</div>
