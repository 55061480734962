import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";

import { NgCircleProgressModule } from "ng-circle-progress";
import { PdfViewerModule } from "ng2-pdf-viewer";

import { PipesModule } from "@shared/pipes/pipes.module";
import { MaterialModule } from "@shared/material/material.module";
import { HorizontalStepperModule } from "@libs/horizontal-stepper/horizontal-stepper.module";

import { PdfViewerComponent } from "./pdf-viewer/pdf-viewer.component";
import { VideoPlayerComponent } from "./video-player/video-player.component";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { UploadIndicatorDialogComponent } from "./upload-indicator-dialog/upload-indicator-dialog.component";

@NgModule({
  declarations: [
    PdfViewerComponent,
    VideoPlayerComponent,
    ConfirmDialogComponent,
    UploadIndicatorDialogComponent,
  ],
  imports: [
    CommonModule,

    PdfViewerModule,
    NgCircleProgressModule.forRoot({
      radius: 56,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    }),

    PipesModule,
    MaterialModule,
    HorizontalStepperModule,
  ],
  exports: [
    VideoPlayerComponent,
    PdfViewerComponent,
    ConfirmDialogComponent,
    UploadIndicatorDialogComponent,
  ],
  entryComponents: [ConfirmDialogComponent, UploadIndicatorDialogComponent],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
})
export class ComponentsModule {}
