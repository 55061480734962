import { UserCitizenStatus } from "app/models";

class ProfileUtil {
  getFullState(stateAbb: string, states: any[]): string {
    const state1 = states.find((state) => state.name === stateAbb);
    if (state1) {
      return stateAbb;
    }

    const state2 = states.find((state) => state.abb === stateAbb);
    if (state2) {
      return state2.name;
    }

    return "";
  }

  isInternationalUser(citizenStatus: UserCitizenStatus): boolean {
    return citizenStatus === UserCitizenStatus.NA;
  }
}

export const profileUtil = new ProfileUtil();
