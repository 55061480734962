<div class="logo">
  <img src="assets/img/logo.png" />
</div>

<div class="sidebar-wrapper">
  <ul class="nav">
    <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
      <!--If is a single link-->
      <ng-container *ngIf="menuitem.type === 'link'">
        <a
          class="nav-link"
          [routerLink]="[menuitem.path]"
          ulawUserStatusSidebar
          [user]="user"
          [userStatusArr]="menuitem.userStatusArr"
          [userCitizenStatusArr]="menuitem.userCitizenStatusArr"
          [showFieldName]="menuitem.showFieldName"
        >
          <i class="material-icons">{{ menuitem.icontype }}</i>
          <p>{{ menuitem.title }}</p>
        </a>
      </ng-container>

      <!--If it have a submenu-->
      <ng-container *ngIf="menuitem.type === 'sub'">
        <a
          href="#{{ menuitem.collapse }}"
          class="nav-link"
          data-toggle="collapse"
          ulawUserStatusSidebar
          [user]="user"
          [userStatusArr]="menuitem.userStatusArr"
          [userCitizenStatusArr]="menuitem.userCitizenStatusArr"
          [showFieldName]="menuitem.showFieldName"
          (click)="updatePS()"
        >
          <i class="material-icons">{{ menuitem.icontype }}</i>
          <p>{{ menuitem.title }}<b class="caret"></b></p>
        </a>
      </ng-container>

      <!--Display the submenu items-->
      <div id="{{ menuitem.collapse }}" class="collapse" *ngIf="menuitem.type === 'sub'">
        <ul class="nav">
          <ng-container *ngFor="let childitem of menuitem.children">
            <li
              class="nav-item"
              routerLinkActive="active"
              [user]="user"
              [ulawServiceStatus]="childitem"
            >
              <a [routerLink]="[menuitem.path, childitem.path]" class="nav-link">
                <span class="sidebar-mini">{{ childitem.ab }}</span>
                <span class="sidebar-normal">{{ childitem.title }}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </li>
  </ul>
</div>
