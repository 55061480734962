import { Injectable } from "@angular/core";

import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DrawerService {
  open: Subject<any>;
  close: Subject<any>;
  content: BehaviorSubject<any>;

  constructor() {
    this.setVariables();
  }

  setVariables(): void {
    this.open = new Subject();
    this.close = new Subject();
    this.content = new BehaviorSubject({});
  }

  openDrawer(content: any): void {
    this.open.next();
    this.content.next(content);
  }

  closeDrawer(): void {
    this.close.next();
  }
}
