/**
 * Enum of Trust status in ULaw
 *
 * @enum {string} Trust Status
 */
export enum TrustStatus {
  FREE = "Free",
  IN_PROCESS = "In process",
  TRUST_IN_REVIEW = "Trust In Review",
  TRUST_SUBMITTED_TO_UCC = "Trust Submitted to UCC",
  SIGNATURES_REQUIRED = "Signatures Required",
  SIGNATURE_REVIEW = "Signature Review",
  TRUST_COMPLETED = "Trust Completed",
  PROTECTOR_CHANGE = "Protector Change",
  TRUSTEE_CHANGE = "Trustee Change",
  BENEFICIARY_ADD = "Beneficiary Add",
  BENEFICIARY_REPLACE = "Beneficiary Replace",
  BENEFICIARY_REMOVED = "Beneficiary Removed",
  PROTECTOR_VERIFIED = "Protector Verified",
  TRUSTEE_VERIFIED = "Trustee Verified",
  BENEFICIARY_ADD_VERIFIED = "Beneficiary Add Verified",
  BENEFICIARY_REPLACE_VERIFIED = "Beneficiary Replace Verified",
  BENEFICIARY_REMOVED_VERIFIED = "Beneficiary Removed Verified",
  BENEFICIARY_TRUST_ADD_VERIFIED = "Beneficiary Trust Add Verified",
  BENEFICIARY_TRUST_REMOVED_VERIFIED = "Beneficiary Trust Removed Verified",
  BENEFICIARY_TRUST_REPLACE_VERIFIED = "Beneficiary Trust Replace Verified",
  PA_SENT_TO_UCC = "PA Sent to UCC",
  TRE_SENT_TO_UCC = "TRE Sent to UCC",
  BFA_SENT_TO_UCC = "BFA Sent to UCC",
  BF_ADD_SENT_TO_UCC = "BFAdd Sent to UCC",
  BF_R_SENT_TO_UCC = "BFR Sent to UCC",
}

/**
 * Enum of Trust Type in ULaw
 *
 * @enum {string} Trust Type
 *
 * Only Coin Holder can have just 1 Main Trust.
 * Main Trust can select Beneficiary Trust as Beneficiary.
 * Main Trust can not have Personal Properties.
 *
 * Other Trust can select Beneficiary Trust as Beneficiary.
 * Other Trust can have Personal Properties.
 *
 * Beneficiary Trust can not select Trust as Beneficiary.
 * Beneficiary Trust can have Personal Properties.
 */
export enum TrustType {
  MAIN = "Main",
  OTHER = "Other",
  BENEFICIARY = "Beneficiary",
}

/**
 * Enum of Humans in Trust
 *
 * @enum {string}
 *
 * In ULaw Trust, there are Grantor, Protector, Primary Trustee, Secondary Trustee and Beneficiary Humans.
 */
export enum TrustHuman {
  GRANTOR = "grantor",
  PROTECTOR = "protector",
  PRIMARY_TRUSTEE = "primaryTrustee",
  SECONDARY_TRUSTEE = "secondaryTrustee",
  BENEFICIARY = "beneficiary",
}

/**
 * Enum of Beneficiary in Trust
 *
 * @enum {string}
 *
 * In ULaw Trust, there are 2 kinds of Beneficiary: Human & Trust.
 */
export enum TrustBeneficiary {
  HUMAN = "human",
  TRUST = "trust",
}

/**
 * Enum of Invite Human Role
 *
 * @enum {string}
 *
 * When user create Trust, user select other users as Grantor, Protector, Admin and Beneficiary.
 * If user does not exist, then user can invite those humans to ULaw.
 */
export enum InviteRole {
  GRANTOR = "Grantor",
  PROTECTOR = "Protector",
  ADMIN = "Admin",
  BENEFICIARY = "Beneficiary",
}

export enum TrustHumanActionBtn {
  REPLACE = "replace",
  DOWNLOAD = "download",
  COMPLETE = "complete",
}

export enum TrustBeneficiaryAmendAction {
  ADD = "add",
  REMOVE = "remove",
  REPLACE = "replace",
  DOWNLOAD = "download",
  COMPLETE = "complete",
  REMOVAL_DOWNLOAD = "removalDownload",
  REMOVAL_COMPLETE = "removalComplete",
}

export enum TrustPropertyProductStatus {
  SUBMITTED = "First",
  UPLOADED = "Uploaded",
  SENT_TO_UCC3 = "Sent to UCC3",
  COMPLETED = "Accepted",
}
