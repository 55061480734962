import { notificationUtil } from "./notification-util";

export interface SimpleChange<T> {
  firstChange: boolean;
  previousValue: T;
  currentValue: T;
  isFirstChange: () => boolean;
}

export function OnChange<T = any>(callback: (value: T, simpleChange?: SimpleChange<T>) => void) {
  // eslint-disable-next-line symbol-description
  const cachedValueKey = Symbol();
  // eslint-disable-next-line symbol-description
  const isFirstChangeKey = Symbol();
  return (target: any, key: PropertyKey) => {
    Object.defineProperty(target, key, {
      // eslint-disable-next-line object-shorthand
      set: function (value) {
        // change status of "isFirstChange"
        if (this[isFirstChangeKey] === undefined) {
          this[isFirstChangeKey] = true;
        } else {
          this[isFirstChangeKey] = false;
        }

        // No operation if new value is same as old value
        if (!this[isFirstChangeKey] && this[cachedValueKey] === value) {
          return;
        }

        const oldValue = this[cachedValueKey];
        this[cachedValueKey] = value;
        const simpleChange: SimpleChange<T> = {
          firstChange: this[isFirstChangeKey],
          previousValue: oldValue,
          currentValue: this[cachedValueKey],
          isFirstChange: () => this[isFirstChangeKey],
        };

        callback.call(this, this[cachedValueKey], simpleChange);
      },
      // eslint-disable-next-line object-shorthand
      get: function () {
        return this[cachedValueKey];
      },
    });
  };
}

export function uploadFileMaxSizeDecorator(size): MethodDecorator {
  // tslint:disable-next-line: only-arrow-functions
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (ev: any) {
      const fileSize = ev.target.files[0].size;

      if (fileSize >= size) {
        const msg =
          "This file is too large and cannot be uploaded, please compress file and try again.";
        notificationUtil.info({ isOrigin: true, originMsg: msg, msgKey: "" });
        return;
      }

      originFn.call(this, ev);
    };
  };
}

export function filterKey(): MethodDecorator {
  // tslint:disable-next-line: only-arrow-functions
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];

    // here args is array-like object, so we have to get it by ...args
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (...args) {
      const key = (args[1] ?? "").toLowerCase();
      return originFn.apply(this, [args[0], key]);
    };
  };
}

export function downloadFile(): MethodDecorator {
  // tslint:disable-next-line: only-arrow-functions
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];
    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (...args) {
      originFn.apply(this, args);
    };
  };
}

export function disabledForm(isDisabled: boolean): MethodDecorator {
  // tslint:disable-next-line: only-arrow-functions
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originFn = target[propertyKey];

    // eslint-disable-next-line no-param-reassign
    descriptor.value = function (...args) {
      if (isDisabled) return;
      originFn.apply(this, args);
    };
  };
}
