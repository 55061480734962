<div class="step-horizontal">
  <ng-container *ngFor="let step of steps; last as isLast">
    <div
      #step
      class="step"
      [class.past]="step.index < currentStep.index"
      [class.active]="step.index === currentStep.index"
      [class.future]="step.index > currentStep.index"
      [matTooltip]="step.tooltip || ''"
      matTooltipPosition="above"
    >
      <ng-container *ngIf="step.index < currentStep.index">
        <span class="step-icon" (click)="onClickStep(step)">
          <i class="material-icons">done</i>
        </span>
      </ng-container>
      <ng-container *ngIf="step.index === currentStep.index && !isLast">
        <span class="step-icon" (click)="onClickStep(step)">
          <i class="material-icons">flight_takeoff</i>
        </span>
      </ng-container>
      <ng-container *ngIf="step.index === currentStep.index && isLast">
        <span class="step-icon" (click)="onClickStep(step)">
          <i class="material-icons">done</i>
        </span>
      </ng-container>
      <ng-container *ngIf="step.index > currentStep.index">
        <span class="step-number" (click)="onClickStep(step)">{{ step.index }}</span>
      </ng-container>
      <span class="step-title">{{ step.text }}</span>
    </div>
  </ng-container>
</div>
