import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dialogTitle",
})
export class DialogTitlePipe implements PipeTransform {
  transform(value: string, ...args: any[]): string {
    const isSecondaryTrustee = value.toLowerCase().includes("secondary");
    return !isSecondaryTrustee
      ? value.split(".").filter(Boolean).join(" ")
      : "Update Secondary Trustee";
  }
}
