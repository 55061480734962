import { Directive, ElementRef, Input, OnInit, Renderer2 } from "@angular/core";
import { UserCitizenStatus, UserStatus } from "@models/enums";

import * as _ from "lodash";

@Directive({
  selector: "[appSpcProgressBar]",
})
export class SpcProgressBarDirective {
  user: any;

  @Input("appSpcProgressBar")
  set setUser(user: any) {
    this.user = user;
    this.user && this.setStyle(user);
  }

  constructor(private _elRef: ElementRef, private _renderer: Renderer2) {
    _renderer.setStyle(_elRef.nativeElement, "display", "none");
  }

  setStyle(user: any): void {
    const status = _.get(user, "status.status");
    const citizenStatus = _.get(user, "userCitizenStatus");

    const hasStatus = ![UserStatus.NEW_USER, UserStatus.INVITED].includes(status);
    const hasCitizenStatus = citizenStatus !== UserCitizenStatus.NA;

    const isVisible = hasStatus && hasCitizenStatus;
    const displayProperty = isVisible ? "block" : "none";

    this._renderer.setStyle(this._elRef.nativeElement, "display", displayProperty);
  }
}
