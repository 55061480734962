<div mat-dialog-content class="upload-progress-bar">
  <div class="row">
    <div class="col-2 d-flex align-items-center justify-content-center">
      <i class="material-icons">cloud_upload</i>
    </div>
    <div class="col-10">
      <div class="row">
        <div class="col-12 text-center">
          <span class="font-weight-bold">{{ filename || ''}}</span>
        </div>
        <div class="col-12 text-center pt-2 pb-2">
          <mat-progress-bar mode="buffer" color="primary" [value]="percent" [bufferValue]="4"></mat-progress-bar>
        </div>
      </div>
    </div>
  </div>
</div>