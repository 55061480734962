import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";

import { PDFDocumentProxy } from "ng2-pdf-viewer";

import { Subject, combineLatest } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

import startCase from "lodash/startCase";

import { util } from "app/util";
import { IPdf } from "@models/models";
import { SPC_LOCAL_STORAGE_KEYS } from "@models/constants";
import {
  Sku,
  PdfType,
  LocalStorageKey,
  LocalStorageValue,
  Instruction,
  DocumentDownloadStatusLocalStorageKey,
  DocumentDownloadStatusLocalStorageValue,
  EasyLienV2DocNames,
} from "@models/enums";

import { environment } from "@environments/environment";

import { JwtService, StateService, PersistenceService, UserService } from "@core/services";

@Component({
  selector: "app-pdf-viewer",
  templateUrl: "./pdf-viewer.component.html",
  styleUrls: ["./pdf-viewer.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PdfViewerComponent implements OnInit, OnDestroy {
  private _stop$: Subject<any>;

  pdf: IPdf;
  tempPdf: any;
  docSrc: any;
  width: number;
  height: number;

  isPdfLoaded: boolean;
  getUser: boolean;

  @Input("pdf")
  set pdfData(pdfData: IPdf) {
    this.pdf = pdfData;
    this.setPdf(this.pdf);
    this._cdRef.detectChanges();
  }
  @Output() loadError: EventEmitter<any>;
  @Output() closeViewer: EventEmitter<any>;

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _uLawJwt: JwtService,
    private _uLawState: StateService,
    private _uLawPersistence: PersistenceService,
    private _uLawUserService: UserService,
  ) {
    this._setVariables();
  }

  ngOnInit(): void {
    this._getWindowSize();
  }

  ngOnDestroy(): void {
    this._stop$.next();
    this._stop$.complete();
  }

  private _setVariables() {
    this._stop$ = new Subject();

    this.pdf = {} as IPdf;
    this.width = 0;
    this.height = 0;

    this.isPdfLoaded = false;
    this.getUser = false;

    this.loadError = new EventEmitter();
    this.closeViewer = new EventEmitter();
  }

  private _getWindowSize(): void {
    combineLatest([this._uLawState.windowWidth, this._uLawState.windowHeight])
      .pipe(
        tap(([_ignore, height]) => {
          this.height = height - 100;
          this.width = Math.floor((this.height * 3) / 4) + 40;
        }),
        takeUntil(this._stop$),
      )
      .subscribe(() => this._cdRef.detectChanges());
  }

  setPdf({
    type,
    sku = Sku.SPC,
    caseNumber,
    productId,
    productName,
    trustId = 0,
    propertyId,
    changedDoc = false,
    instruction = Instruction.SPC_EXECUTION,
    typeOfParticipant,
    docName,
  }: IPdf) {
    const jwt = this._uLawJwt.getToken();

    if (type === PdfType.SPC) {
      switch (sku) {
        case Sku.SPC:
        case Sku.SPC_CONSENT:
        case Sku.SPC_AMEND:
        case Sku.MASTER_BOND:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/product/get?sku=${sku}&method=download`,
          };
          break;
        case Sku.SPC_REPORT:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/user/uccdoc?sku=${Sku.SPC}`,
          };
          break;
        case Sku.SPC_AMEND_REPORT:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/user/uccdoc?sku=${Sku.SPC_AMEND}`,
          };
          break;
        case Sku.TREASURY_PACKET:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/user/spcamendmentdocs`,
          };
          break;
        case Sku.GSA:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/product/get?sku=${sku}&casenumber=${caseNumber}&method=download`,
          };
          break;
        case Sku.EASY_LIEN:
          this.docSrc = {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/product/get?sku=${sku}&productId=${productId}&productName=${productName}`,
          };
          break;
        default:
          break;
      }
    }

    if (type === PdfType.TRUST && !changedDoc) {
      const isReport: boolean = this.pdf.filename.toLowerCase().includes("report");
      this.docSrc = isReport
        ? {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/user/uccdoc?trustId=${trustId}`,
          }
        : {
            httpHeaders: { Authorization: jwt },
            url: `${environment.apiUrl}/doc/get?trustId=${trustId}&method=download`,
          };
    }

    if (type === PdfType.TRUST && changedDoc) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/doc/get?trustId=${trustId}&changedoc=true&offset=0`,
      };
    }

    if (type === PdfType.TRUST && propertyId) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/doc/get?trustId=${trustId}&propertyId=${propertyId}`,
      };
    }

    if (type === PdfType.INSTRUCTION) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/user/template?productName=${instruction}`,
      };
    }

    if (type === PdfType.INSTRUCTION_PDF) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/user/template?sku=${sku}&productName=${instruction}`,
      };
    }

    if (type === PdfType.GSA_V2) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/product/get?sku=${sku}&productId=${productId}&docName=${docName}`,
      };
      this.getUser = true;
    }

    if (type === PdfType.TAX_V2) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/product/get?sku=${sku}&productId=${productId}&docName=${docName}`,
      };
      this.getUser = true;
    }

    if (type === PdfType.NDA) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/product/get?sku=${sku}`,
      };
      this.getUser = true;
    }

    if (type === PdfType.IRVIC) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/product/get?sku=${sku}&productId=${productId}&docName=${docName}`,
      };
    }

    if (type === PdfType.NNC) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/product/get?sku=nnc&productId=${productId}&typeOfParticipant=${typeOfParticipant}`,
      };
    }

    if (type === PdfType.NNC_PACKET) {
      this.docSrc = {
        httpHeaders: { Authorization: jwt },
        url: `${environment.apiUrl}/user/nncdocs?productId=${productId}&typeOfParticipant=${typeOfParticipant}`,
      };
    }

    if (type === PdfType.EASY_LIEN_V2) {
      if (docName === EasyLienV2DocNames.FINAL_PACKET) {
        this.docSrc = {
          httpHeaders: { Authorization: jwt },
          url: `${environment.apiUrl}/user/elien2vpack?productId=${productId}`,
        };
      } else {
        this.docSrc = {
          httpHeaders: { Authorization: jwt },
          url: `${environment.apiUrl}/product/get?sku=elien2v&productId=${productId}&docName=${docName}`,
        };
      }
    }
  }

  updateLocalStorage(pdfType: PdfType): () => any {
    const self = this;
    const localStorageFn = {
      [PdfType.SPC]: () => self.updateSpcLocalStorage(),
      [PdfType.TRUST]: () => self.updateTrustLocalStorage(),
      [PdfType.GSA_V2]: () => {},
      [PdfType.NNC]: () => {},
      [PdfType.EASY_LIEN_V2]: () => {},
      [PdfType.NNC_PACKET]: () => {},
      [PdfType.TAX_V2]: () => {},
      [PdfType.NDA]: () => {},
      [PdfType.INSTRUCTION]: () => {},
      [PdfType.INSTRUCTION_PDF]: () => {},
      [PdfType.IRVIC]: () => {},
    };
    return localStorageFn[pdfType];
  }

  updateSpcLocalStorage(): void {
    const { sku } = this.pdf;

    switch (sku) {
      case Sku.SPC:
        this._uLawPersistence.setItem(
          DocumentDownloadStatusLocalStorageKey.SPC_PACKET,
          DocumentDownloadStatusLocalStorageValue.DOWNLOADED,
        );
        break;
      case Sku.SPC_CONSENT:
        this._uLawPersistence.setItem(
          DocumentDownloadStatusLocalStorageKey.SPC_CONSENT_PACKET,
          DocumentDownloadStatusLocalStorageValue.DOWNLOADED,
        );
        break;
      case Sku.SPC_AMEND:
        this._uLawPersistence.setItem(
          DocumentDownloadStatusLocalStorageKey.SPC_AMEND_PACKET,
          DocumentDownloadStatusLocalStorageValue.DOWNLOADED,
        );
        break;
      default:
        break;
    }
  }

  updateTrustLocalStorage(): void {
    const { trustId, trust, changedDoc } = this.pdf;

    if (changedDoc) {
      const prevValue = this._uLawPersistence.getItem(LocalStorageKey.TRUST_CHANGED_DOC_DOWNLOAD);
      const newValue = [...(prevValue || []), trust?.changesUsers[0].id];
      this._uLawPersistence.setItem(LocalStorageKey.TRUST_CHANGED_DOC_DOWNLOAD, newValue);
    }

    if (!changedDoc) {
      const prevValue = this._uLawPersistence.getItem(LocalStorageKey.TRUST_DOWNLOAD);
      const newValue = [...(prevValue || []), trustId];
      this._uLawPersistence.setItem(LocalStorageKey.TRUST_DOWNLOAD, newValue);
    }
  }

  onInitPdfView(pdf: PDFDocumentProxy): void {
    this.isPdfLoaded = true;
    this.tempPdf = pdf;
    if (this.getUser) {
      this._uLawUserService.getUser().subscribe(() => {
        this.getUser = false;
      });
    }
  }

  onDownloadPdf(): void {
    this.tempPdf.getData().then((u8) => {
      this.updateLocalStorage(this.pdf.type)();
      const blob = new Blob([u8.buffer], {
        type: "application/pdf",
      });
      util.savePdf(blob, this.pdf.filename);
    });
  }

  onPrintPdf(): void {
    this.tempPdf.getData().then((u8) => {
      this.updateLocalStorage(this.pdf.type)();
      const blob = new Blob([u8.buffer], {
        type: "application/pdf",
      });
      util.printPdf(blob, this.pdf.filename);
    });
  }

  onClose(): void {
    this.closeViewer.emit();
  }

  onErrorPdf(_ignore): void {
    this.loadError.emit();
  }

  get fileDisplayName(): string {
    if (this.pdf?.sku === "gsa2v") {
      return this.pdf.filename ? `${this.pdf.filename}.pdf` : "";
    }
    return this.pdf.filename ? `${startCase(this.pdf.filename)}.pdf` : "";
  }
}
