<mat-drawer-container hasBackdrop>
  <mat-drawer #sideDrawer mode="over" position="end">
    <ulaw-side-drawer
      [content]="drawerContent"
      (closeDrawer)="onCloseSideDrawer()"
    ></ulaw-side-drawer>
  </mat-drawer>
  <mat-drawer-content>
    <div class="wrapper">
      <div class="sidebar" data-color="white" data-background-color="black">
        <app-sidebar-cmp></app-sidebar-cmp>
        <div
          class="sidebar-background"
          style="background-image: url(assets/img/sidebar-bg.jpg)"
        ></div>
      </div>

      <div class="main-panel">
        <app-navbar-cmp></app-navbar-cmp>
        <router-outlet></router-outlet>
        <app-footer></app-footer>
        <app-scroll-top [isShowScrollTop]="isShowScrollTop"></app-scroll-top>
      </div>

      <ngx-spinner
        bdColor="rgba(0, 0, 0, 0.8)"
        size="medium"
        color="#ea7900"
        type="line-scale"
        [fullScreen]="false"
      >
      </ngx-spinner>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<ng-template cdk-portal #videoPortal="cdkPortal">
  <app-video-player [url]="videoKey | video" (closePlayer)="onDetachOverlay()"></app-video-player>
</ng-template>

<ng-template cdk-portal #pdfViewerPortal="cdkPortal">
  <app-pdf-viewer [pdf]="pdf" (closeViewer)="onDetachOverlay()" (loadError)="onLoadPdfError()">
  </app-pdf-viewer>
</ng-template>
